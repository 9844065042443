import React from 'react';
import { Link } from 'react-router-dom';
import library from "../../assets/image/library.png";

const AnimalSacrifice = () => {
    return (
        <div className='px-0 md:px-10 lg:px-20'>
            <p className='text-center font-bold text-2xl text-secondary mb-2'>সবার জন্য কুরবানী</p>
            <p>
                সবার জন্য কুরবানী পূর্ব বড়ুয়া তরুণ সংঘ মানবসেবামূলক নিয়মিত কার্যক্রমের অংশ। প্রতিষ্ঠার পর থেকেই থেকেই দীনদার ধনীদের পক্ষ থেকে গরিব জনগোষ্ঠীর মধ্যে কুরবানী কার্যক্রম পরিচালনা করে আসছে।
                <br />
                <br />
                সাধারণত বেশি সংখ্যক কুরবানী হয়ে থাকে শহর-কেন্দ্রিক। অথচ অধিক সংখ্যক গরিব বাস করে গ্রামে। শহরের একটি বহুতল ভবনে যত সংখ্যক গরু কুরবানী হয়ে থাকে, এই পরিমাণ গরু কুরবানী কয়েকটি গ্রামেও হয় কি না— যথেষ্ট সন্দেহ রয়েছে। সবার জন্য কুরবানী প্রকল্পের মাধ্যমে পূর্ব বড়ুয়া তরুণ সংঘের শহুরে ধনীদের কুরবানীর একটা অংশ প্রত্যন্ত অঞ্চলের সুবিধা-বঞ্চিত দরিদ্র জনগোষ্ঠীর নিকট পৌঁছে দেয়।
            </p>
            <div className='flex justify-center my-10'>
                <Link to="/donation" className='btn btn-primary text-white w-44'>দান করুন</Link>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center place-items-center gap-10'>
                <img className='h-44 w-52 m-2' src={library} alt="" />
                <img className='h-44 w-52 m-2' src={library} alt="" />
                <img className='h-44 w-52 m-2' src={library} alt="" />
            </div>
        </div>
    );
};

export default AnimalSacrifice;