import React, { useState, useEffect } from "react";
import { FaFilePdf, FaImage } from "react-icons/fa";
import api from "../../utils/axios.config";
import Loading from "../Shared/Loading";

const LatestNotice = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [entries, setEntries] = useState(20);
  const [notices, setNotices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchNotices = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data } = await api.get("/notice");
      setNotices(data.data);
    } catch (error) {
      setError(error.response?.data?.error ?? error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  const filteredData = notices.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderAttachmentIcon = (attachment) => {
    if (attachment.type === "pdf") {
      return <FaFilePdf size={24} className="text-red-600" />;
    } else if (attachment.type === "image") {
      return <FaImage size={24} className="text-blue-600" />;
    }
    return null;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-7xl mx-auto bg-white p-6 rounded-lg shadow-lg">
          <p className="text-red-500">Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <div>
            <label htmlFor="entries" className="mr-2">
              Show
            </label>
            <select
              id="entries"
              value={entries}
              onChange={(e) => setEntries(Number(e.target.value))}
              className="border rounded p-2"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
            <span className="ml-2">entries</span>
          </div>
          <div>
            <label htmlFor="search" className="mr-2">
              Search:
            </label>
            <input
              id="search"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border rounded p-2"
            />
          </div>
        </div>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 border-b">ক্রমিক</th>
              <th className="py-2 border-b">শিরোনাম</th>
              <th className="py-2 border-b">প্রকাশের তারিখ</th>
              <th className="py-2 border-b">ডাউনলোড</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.slice(0, entries).map((item, index) => (
              <tr key={item._id}>
                <td className="py-2 border-b text-center">{index + 1}</td>
                <td className="py-2 border-b">{item.title}</td>
                <td className="py-2 border-b text-center">
                  {item?.createdAt?.split("T")[0]}
                </td>
                <td className="py-2 border-b text-center flex justify-center">
                  {item.attachments.map((attachment, index) => (
                    <a
                      key={index}
                      href={attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mx-1"
                    >
                      {renderAttachmentIcon(attachment)}
                    </a>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LatestNotice;
