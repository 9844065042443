import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/image/logo.ico";

const Navbar = () => {
  const { pathname } = useLocation();

  const aboutPageElement = [
    "/about",
    "/adviser",
    "/member",
    "/rules",
    "/vision-mission",
    "/questions-answers",
  ];
  const listPageElement = [
    "talent-students",
    "public-university-students",
    "volunteer-list",
    "freelancer",
    "training-center",
    "donor-partner",
  ];
  const mediaPageElement = [
    "/media/photo-gallery",
    "/media/video-gallery",
    "/media/latest-notice",
    "newspaper-news",
  ];
  const bloodDonationPageElement = ["/blood-donors", "/join"];
  const projectPageElement = [
    "/talent-student-award",
    "/education-project",
    "cloth-project",
    "food-project",
    "library-project",
    "tree-plantation-project",
    "animal-sacrifice-project",
  ];
  const donationPageElement = [
    "/donation",
    "/sponsor-info",
    "/about-donation",
    "sponsor-chile",
    "sponsor",
    "bank-info",
  ];

  const applicationElement = [
    "/scholarship-application",
    "/meritorious-student",
  ];

  const navbar = (
    <>
      <li>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "font-bold text-primary " : "font-thin"
          }
        >
          হোম
        </NavLink>
      </li>
      <li tabindex="2">
        <a
          className={
            donationPageElement.some((el) => pathname == el)
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          দান
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/donation"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              দান করুন
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about-donation"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              দানের ব্যাখ্যা
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/sponsor-info"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              স্পন্সর করুন
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/sponsor-child"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              Sponsor A Child
            </NavLink>
          </li>
        </ul>
      </li>
      <li tabindex="0">
        <a
          className={
            aboutPageElement.some((el) => pathname == el)
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          আমাদের-সম্পর্কে
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              আমাদের-সম্পর্কে
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/adviser"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              উপদেষ্টা মন্ডলী সমূহ
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/member"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              কার্যকরী কমিটি
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/rules"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              বিধি মালা
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/vision-mission"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              লক্ষ ও উদ্দেশ্য
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/questions-answers"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              প্রয়োজনীয় প্রশ্ন ও উত্তর
            </NavLink>
          </li>
        </ul>
      </li>
      <li tabindex="1">
        <a
          className={
            listPageElement.some((el) => pathname.includes(el))
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          তালিকা
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/talent-students"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              মেধাবী শিক্ষার্থীর তালিকা
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/public-university-students"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              বিভিন্ন পাবলিক বিশ্ববিদ্যালয়ের শিক্ষার্থীর তালিকা
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/freelancer"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              ফ্রিল্যান্সার
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/training-center"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              ট্রেনিং সেন্টার
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/donor-partner"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              ডোনার এন্ড পার্টনার
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/volunteer-list"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              স্বেচ্ছাসেবক তালিকা
            </NavLink>
          </li>
        </ul>
      </li>
      <li tabindex="1">
        <a
          className={
            mediaPageElement.some((el) => pathname.includes(el))
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          মিডিয়া
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/media/photo-gallery"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              ফটো গ্যালারী
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/media/video-gallery"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              ভিডিও গ্যালারী
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/media/latest-notice"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              সর্বশেষ বিজ্ঞপ্তী
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/media/newspaper-news"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              বিভিন্ন পত্রিকায় প্রকাশিত সংবাদ
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/media/get-prize"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              প্রাপ্ত পুরষ্কার সমূহ
            </NavLink>
          </li>
        </ul>
      </li>
      <li tabindex="2">
        <a
          className={
            bloodDonationPageElement.some((el) => pathname.includes(el))
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          ব্লাড ডোনেশন
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/blood-donors"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              ব্লাড ডোনারের তালিকা
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/join"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              ব্লাড ডোনার হিসাবে নিবন্ধন করুন
            </NavLink>
          </li>
        </ul>
      </li>
      <li tabindex="2">
        <a
          className={
            projectPageElement.some((el) => pathname.includes(el))
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          প্রকল্প
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/library-project"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              পূর্ব বড়ুয়া তরুণ সংঘ পাঠাগার
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tree-plantation-project"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              বৃক্ষরোপণ কর্মসূচি
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/animal-sacrifice-project"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              সবার জন্য কুরবানী
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/education-project"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              শিক্ষা
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/food-project"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              খাদ্য
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/cloth-project"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              শীতার্তদের শীতবস্ত্র
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/talent-student-award"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              কৃতি শিক্ষার্থীদের সংবর্ধনা
            </NavLink>
          </li>
        </ul>
      </li>
      <li tabindex="2">
        <a
          className={
            applicationElement.some((el) => pathname.includes(el))
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          আবেদন
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/scholarship-application"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              শিক্ষা বৃত্তির আবেদন
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/meritorious-student"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              শিক্ষার্থী সংবর্ধনা
            </NavLink>
          </li>
        </ul>
      </li>
      <li tabindex="2">
        <a
          className={
            applicationElement.some((el) => pathname.includes(el))
              ? "font-bold text-primary "
              : "font-thin"
          }
        >
          ব্লাড ডোনার
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </a>
        <ul class="p-2 bg-secondary md:bg-base-100">
          <li>
            <NavLink
              to="/blood-donor"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              অনুসন্ধান
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blood-donor/register"
              className={({ isActive }) =>
                isActive ? "font-bold text-primary " : "font-thin"
              }
            >
              নিবন্ধন
            </NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink
          to="/committee"
          className={({ isActive }) =>
            isActive ? "font-bold text-primary " : "font-thin"
          }
        >
          কমিটি
        </NavLink>
      </li>
    </>
  );
  return (
    <div className="navbar bg-white sticky top-0 z-50 shadow-xl">
      <div className="navbar-start">
        <div className="dropdown  text-white">
          <div className="lg:hidden bg-secondary md:bg-base-100 w-12  p-2 flex justify-center m-2 rounded">
            <label tabIndex="0" className="text-white lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
          </div>
          <ul
            tabIndex="0"
            className="menu menu-compact dropdown-content mt-3 p-2 shadow  w-52 bg-secondary md:bg-base-100"
          >
            {navbar}
          </ul>
        </div>
        <Link to="/" className="h-20 w-20">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">{navbar}</ul>
      </div>
      <div className="navbar-end">
        {/* <NavLink to="/donation" className="btn btn-primary text-white">
          Donation
        </NavLink> */}
        <NavLink
          to="/blood-donor"
          className={({ isActive }) =>
            isActive
              ? "font-bold text-red-500 "
              : "font-bold bg-red-500 text-white p-2 rounded"
          }
        >
          Blood Donor
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
