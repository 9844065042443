import React, { useEffect, useState } from "react";
import member1 from "../../assets/members/saikat.jpg";
import { BsFacebook } from "react-icons/bs";
import member01 from "../../assets/members/adviser/01.jpeg";
import member02 from "../../assets/members/adviser/02.jpg";
import member03 from "../../assets/members/adviser/03.JPG";
import member04 from "../../assets/members/adviser/04.jpg";
import member06 from "../../assets/members/adviser/06.jpg";
import member07 from "../../assets/members/adviser/07.jpg";
import member08 from "../../assets/members/adviser/08.jpg";
import member10 from "../../assets/members/adviser/10.jpg";
import member11 from "../../assets/members/adviser/11.jpg";
import member12 from "../../assets/members/adviser/12.jpg";
import member13 from "../../assets/members/adviser/13.jpg";
import member14 from "../../assets/members/adviser/14.jpg";
import member15 from "../../assets/members/adviser/15.jpg";
import member17 from "../../assets/members/adviser/17.jpg";
import member18 from "../../assets/members/adviser/18.jpg";
import member19 from "../../assets/members/adviser/19.jpg";
import member23 from "../../assets/members/adviser/23.jpg";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const AdviserMember = () => {
  const members = [
    {
      name: "মোঃ আরমান রহমান",
      designation: "প্রধান উপদেষ্টা",
      professionInstitute: "ইতিহাস বিভাগ, লালমনিরহাট সরকারী কলেজ",
      professionDesignation: "বিভাগীয় প্রধান",
      mobile: "০১৭১৭৭৫৭২৯৩",
      email: "adviser@pbtsbd.org",
      address: "রাজারহাট কুড়িগ্রাম",
      image: member01,
      facebookLink: "",
    },
    {
      name: "মোঃ ইদ্রিস আলী",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট ইউনিয়ন পরিষদ, সদর, লালমনিরহাট",
      professionDesignation: "চেয়ারম্যান",
      mobile: "০১৭৪০১৩৪৯৩২",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: member02,
      facebookLink: "",
    },
    {
      name: "মোঃ শাহজাহান আলী",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "প্রধান শিক্ষক",
      mobile: "০১৭১৩৭৭৩১৩৭",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member03,
      facebookLink: "",
    },
    {
      name: "মোঃ আব্দুল মজিদ",
      designation: "উপদেষ্টা",
      professionInstitute: "ভাটিবাড়ী উচ্চ বিদ্যালয় ",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭২৯৪২০৭৩৮",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member04,
      facebookLink: "",
    },
    {
      name: "মোঃ আব্দুল গফুর মন্ডল",
      designation: "উপদেষ্টা",
      professionInstitute: "পূর্ব বড়ুয়া সরকারি প্রাথমিক বিদ্যালয়",
      professionDesignation: "প্রধান শিক্ষক",
      mobile: "০১৭১৮৮৩৭৬৮২",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ এমদাদুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৮৪৩৯৯৫৫৬৯",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member06,
      facebookLink: "",
    },
    {
      name: "মোঃ এনামুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট ইউনিয়ন পরিষদ",
      professionDesignation: "ইউপি সদস্য , ০৬ নং ওয়ার্ড",
      mobile: "০১৭৬৭৫৬২৮০৫",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member07,
      facebookLink: "",
    },
    {
      name: "মোঃ তোজাম্মেল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭১৮৪৩২৯৬৬",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member08,
      facebookLink: "",
    },
    {
      name: "মোঃ আব্দুল হামিদ",
      designation: "উপদেষ্টা",
      professionInstitute: "মজিদা খাতুন সরকারি কলেজ",
      professionDesignation: "অফিস সহকারী",
      mobile: "০১৭২১৫৪২০৮২",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ আশরাফুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭১২০১৩৪২০",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member10,
      facebookLink: "",
    },
    {
      name: "মোঃ হারুন অর রশিদ",
      designation: "উপদেষ্টা",
      professionInstitute: "নিলয় ইলেকট্রোনিক্স, কুলাঘাট বাজার",
      professionDesignation: "প্রোপাইটর",
      mobile: "০১৭১৬৯৬৩৮৫০",
      email: "adviser@pbtsbd.org",
      address: "বাশঁপচাই ভিতরকুটি, লালমনিরহাট",
      image: member11,
      facebookLink: "",
    },
    {
      name: "মোঃ সাইফুল আমিন",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭৩৬৫৮৬৬৪১",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: member12,
      facebookLink: "",
    },
    {
      name: "মোঃ এরশাদুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "সিটি ব্যাংক, ঠাকুরগাঁও শাখা",
      professionDesignation: "ম্যানেজার",
      mobile: "০১৭১৭২৩৯৩৩৭",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member13,
      facebookLink: "",
    },
    {
      name: "মোঃ মিজানুর রহমান মন্টু",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "সভাপতি",
      mobile: "০১৭৩৪৫৭৭৪২৩",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member14,
      facebookLink: "",
    },
    {
      name: "মোঃ গোলাম মোস্তফা রাঙ্গা",
      designation: "উপদেষ্টা",
      professionInstitute: "বাংলাদেশ আনছার ও ভিডিপি, কুড়িগ্রাম",
      professionDesignation: "হিসাব রক্ষক",
      mobile: "01716443461",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member15,
      facebookLink: "",
    },
    {
      name: "মোঃ মুকুল ইসলাম",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "সমাজ সেবক",
      mobile: "০১৯২১২৬৭৮৭৭",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ নাসিরুল আলম মন্ডল",
      designation: "উপদেষ্টা",
      professionInstitute: "দলগ্রাম উচ্চ বিদ্যালয়",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭১৭২৭৬৬৩৭",
      email: "adviser@pbtsbd.org",
      address: "দলগ্রাম, কালীগঞ্জ",
      image: member17,
      facebookLink: "",
    },
    {
      name: "আবু সাঈদ মোঃ রাশিদুজ্জামান",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৯৭৬২০৪৭৪৯",
      email: "adviser@pbtsbd.org",
      address: "দূর্গাপুর, আদিতমারী",
      image: member18,
      facebookLink: "",
    },
    {
      name: "জগদীশ বিশ্বাস",
      designation: "উপদেষ্টা",
      professionInstitute:
        "প্রকল্প বাস্তবায়ন কর্মর্কতার কার্যালয়, সদর, লালমনিরহাট",
      professionDesignation: "অফিস সহকারী",
      mobile: "০১৭২৮৭৫৩৯৪৩",
      email: "adviser@pbtsbd.org",
      address: "বনগ্রাম, কুলাঘাট, লালমনিরহাট",
      image: member19,
      facebookLink: "",
    },
    {
      name: "মোঃ কবিরুল ইসলাম",
      designation: "উপদেষ্টা",
      professionInstitute: "ধাইরখাতা সরকারি প্রাথমিক বিদ্যালয়",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭০০০০০০০০",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, কুলাঘাট, লালমনিরহাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ খবিরুল ইসলাম",
      designation: "উপদেষ্টা",
      professionInstitute: "একটি বাড়ী একটি খামার সদর, লালমনিরহাট",
      professionDesignation: "মাঠ কর্মী",
      mobile: "০১৭৪৯৯৭৩৮৪৯",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, কুলাঘাট, লালমনিরহাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ হাফেজুল ইসলাম",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৯২৪৭২৪৫৫৪",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মিলন বিশ্বাস",
      designation: "উপদেষ্টা",
      professionInstitute: "ভেলাবাড়ী স্কুল এন্ড কলেজ",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭৩৮৬২৬৭০৯",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member23,
      facebookLink: "",
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);

  const [allMember, setAllMember] = useState([]);

  useEffect(() => {
    const totalPage = Math.ceil(members.length / 10);
    if (totalPage > currentPage) {
      setAllMember(members.slice(currentPage * 10, 10 * (currentPage + 1)));
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, members]);

  const handleIncreasePage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleDecreasePage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="m-2 md:m-5">
      <h3 className="text-center font-bold text-2xl">উপদেষ্টা মন্ডলীর সদস্য</h3>
      <div className="my-2 flex justify-end">
        <input
          type="text"
          placeholder="মোবাইল নাম্বার লিখুন"
          class="input input-bordered input-md w-full max-w-xs"
        />
        <button className="btn bg-secondary text-white border-0 mx-2">
          অনুসন্ধান
        </button>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-compact w-full">
          <thead>
            <tr>
              <th className="bg-secondary text-white">নাম এবং পদবী</th>
              <th className="bg-secondary text-white">
                কর্মরত প্রতিষ্ঠানের নাম এবং পদবী
              </th>
              <th className="bg-secondary text-white">যোগাযোগ</th>
              <th className="bg-secondary text-white">ঠিকানা</th>
              <th className="bg-secondary text-white">ফেসবুক</th>
            </tr>
          </thead>
          <tbody>
            {allMember?.map((member, index) => (
              <tr key={index}>
                <div class="flex items-center space-x-3">
                  <div class="avatar">
                    <div class="mask mask-squircle w-20 h-20 p-2">
                      {member?.image && (
                        <div class="avatar">
                          <div class="mask mask-squircle w-20 h-20">
                            <img src={member?.image} alt="not found" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div class="font-bold">{member?.name}</div>
                    <div class="text-sm opacity-80">{member?.designation}</div>
                  </div>
                </div>

                <td>
                  <div class="font-bold">{member?.professionInstitute}</div>
                  <div class="text-sm opacity-80">
                    {member?.professionDesignation}
                  </div>
                </td>
                <td>
                  <div class="font-bold">{member?.mobile}</div>
                  <div class="text-sm opacity-80">{member?.email}</div>
                </td>
                <td>{member?.address}</td>
                <td>
                  <div className="cursor-pointer">
                    <BsFacebook color="blue" size={30} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="px-5">
          <div className="flex justify-end items-center">
            <div className="px-6 py-4">
              {currentPage * 10 + 1}-{10 * (currentPage + 1)} of{" "}
              {members?.length}
            </div>
            <div>
              <div className="flex items-center">
                <button className="pr-10" onClick={handleDecreasePage}>
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <button onClick={handleIncreasePage}>
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdviserMember;
