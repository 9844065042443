import "./App.css";
import Index from "./routes";
import MessengerCustomerChat from "react-messenger-customer-chat";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import Modal from "react-modal";

Modal.setAppElement("#root");

function App() {
  useEffect(() => {
    AOS.init({
      offset: 120, // offset (in px) from the original trigger point
      delay: 1000, // values from 0 to 3000, with step 50ms
      duration: 1500, // values from 0 to 3000, with step 50ms
    });
  }, []);

  return (
    <div>
      <Index />
      <MessengerCustomerChat pageId="110416461366449" appId="734463204949856" />
      <Toaster />
    </div>
  );
}

export default App;
