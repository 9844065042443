import React, { useEffect, useState } from 'react';
import api from '../../utils/axios.config';
import { baseUrl } from '../../utils/url';
import { BsTrashFill } from 'react-icons/bs';
import { GrEdit } from 'react-icons/gr';

function AdviserMessage() {
  const [message, setMessage] = useState([]);

  const handleGetMessage = async () => {
    const { data } = await api.get('adviser-message');

    setMessage(data.adviserMessage);
  };

  useEffect(() => {
    handleGetMessage();
  }, []);
  return (
    <div>
      <div className="flex justify-around border-b-2 font-bold">
        <p>Message</p>
        <p>Action</p>
      </div>
      {message?.map((msg) => (
        <div
          key={msg._id}
          className="flex justify-around items-center border-b-2"
        >
          <div className="flex-1 justify-center items-center p-5">
            <p>{msg.message}</p>
          </div>
          <div className="flex w-32 flex-1 justify-center items-center p-5">
            <BsTrashFill className="mx-2" size={30} color="red" />
            <GrEdit className="mx-2" size={30} color="blue" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default AdviserMessage;
