import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../utils/axios.config';
import { toast } from 'react-hot-toast';
import ReactQuill from 'react-quill';
import axios from 'axios';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { baseUrl } from '../../utils/url';
import Loading from '../Shared/Loading';

/** Debounce function */
function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function AddRecentActivity(props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const onSubmit = async ({
    file,
    title,
    shortDescription,
    longDescription,
  }) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('image', file[0]);
    formData.append('title', title);
    formData.append('shortDescription', shortDescription);
    formData.append('longDescription', longDescription);

    try {
      const { data } = await api.post('recent-activities/create', formData);

      if (data.success) {
        toast.success(data.message);
        reset();
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error(error.response.data.error ?? error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const [value, setValue] = useState('');
  // const quillRef = useRef();

  // const handleImageUpload = async () => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.click();

  //   input.onchange = async () => {
  //     const file = input.files[0];
  //     const formData = new FormData();
  //     formData.append('image', file);

  //     try {
  //       const { data } = await api.post('/upload-image', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });

  //       const imageUrl = baseUrl + data.url;
  //       const range = quillRef.current.getEditor().getSelection();
  //       quillRef.current
  //         .getEditor()
  //         .insertEmbed(range.index, 'image', imageUrl);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  // };

  // const modules = useMemo(
  //   () => ({
  //     toolbar: {
  //       container: [
  //         [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //         [{ size: ['small', false, 'large', 'huge'] }],
  //         [{ script: 'sub' }, { script: 'super' }],
  //         [{ color: [] }, { background: [] }],
  //         ['bold', 'italic', 'underline'],
  //         [{ font: [] }],
  //         [{ align: [] }],
  //         ['image', 'code-block', 'blockquote'],
  //         [{ list: 'ordered' }, { list: 'bullet' }],
  //         [{ direction: 'rtl' }],
  //       ],
  //       handlers: {
  //         image: handleImageUpload,
  //       },
  //     },
  //   }),
  //   []
  // );
  // // Using debounce here solved the issue
  // const rteChange = debounce(function (content, delta, source, editor) {
  //   setValue(content);
  // }, 200);

  // const handleSubmit = async () => {
  //   try {
  //     const { data } = await api.post('recent-activities/create', { value });
  //     // console.log('HTML data sent successfully!');
  //     console.log(data);
  //   } catch (error) {
  //     console.error('Error sending HTML data:', error);
  //   }
  // };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <label class="label">
              <span class="label-text">ছবি নির্বাচন করুন *</span>
            </label>
            <input
              type="file"
              {...register('file', { required: 'ছবি আবশ্যক' })}
              class="file-input file-input-bordered file-input-primary w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.file && errors.file.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">টাইটেল *</span>
            </label>
            <input
              {...register('title', {
                required: 'টাইটেল আবশ্যক',
                maxLength: {
                  value: 30,
                  message:
                    'দুুুুুুুুুুুুুুুুুুুুুুুুুুুুঃখিত অক্ষর লিমিট অতিক্রম করেছে',
                },
              })}
              type="text"
              placeholder="টাইটেল লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.title && errors.title.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">সংক্ষিপ্ত বিবরণ *</span>
            </label>
            <textarea
              class="textarea textarea-bordered h-24"
              {...register('shortDescription', {
                required: 'সংক্ষিপ্ত বিবরণ আবশ্যক',
                maxLength: {
                  value: 100,
                  message:
                    'দুুুুুুুুুুুুুুুুুুুুুুুুুুুুঃখিত অক্ষর লিমিট অতিক্রম করেছে',
                },
              })}
              type="text"
              placeholder="সংক্ষিপ্ত বিবরণ লিখুন"
            ></textarea>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.shortDescription && errors.shortDescription.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">বিস্তারিত বিবরণ *</span>
            </label>
            <textarea
              class="textarea textarea-bordered h-32"
              {...register('longDescription', {
                required: 'বিস্তারিত বিবরণ আবশ্যক',
              })}
              type="text"
              placeholder="বিস্তারিত বিবরণ লিখুন"
            ></textarea>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.longDescription && errors.longDescription.message}
              </span>
            </label>
          </div>
        </div>
        <div className="mt-10">
          <input
            className="btn btn-primary text-white w-44"
            value={'যুক্ত করুন'}
            type="submit"
          />
        </div>
      </form>
      {/* <div>
      <ReactQuill
        ref={quillRef}
        placeholder="Start typing from here..."
        modules={modules}
        defaultValue={value}
        onChange={rteChange}
        preserveWhitespace
      />
      <br />
      <br />

      <div>
        <button
          onClick={() => handleSubmit()}
          className="btn btn-primary font-bold text-white w-44"
        >
          Add
        </button>
      </div>
      </div> */}
    </>
  );
}

export default AddRecentActivity;
