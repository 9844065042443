import Slider from "react-slick";
import winterClothe2022 from "../../assets/image/winter-clothe.jpeg";
import winterClothe2023 from "../../assets/image/winter-clothe-2023.jpeg";
import teams from "../../assets/image/team-for-header.jpeg";
import organizationBanner from "../../assets/image/organization-banner.jpeg";
import educationBanner from "../../assets/image/education.jpeg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";

function Carousel() {
  const [banners, setBanners] = useState([]);

  const handleGetHeaderBanners = async () => {
    const { data } = await api.get("header-banner");
    setBanners(data.data);
    console.log(data.data[0].imageUrl);
  };

  useEffect(() => {
    handleGetHeaderBanners();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="md:mx-5 mx-2">
      <Slider autoplay={true} {...settings}>
        {banners
          .slice()
          .reverse()
          .map((banner, index) => (
            <div key={index}>
              <div className="hero">
                <div className="hero-content flex-col lg:flex-row-reverse items-center">
                  <img
                    src={`${baseUrl}${banner?.imageUrl}`}
                    className="w-[70vw] md:w-[800px] rounded-lg  h-52 md:h-96"
                    alt=""
                  />
                  <div className="md:pr-12">
                    <h1 className="text-5xl font-bold">{banner?.title}</h1>
                    <p className="py-6">{banner?.description}</p>
                    <Link to="/donation" className="btn btn-primary text-white">
                      Donation Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}

export default Carousel;
