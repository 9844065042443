import React from "react";

const NewspaperNews = () => {
  const newspaperLinks = [
    {
      title: "লালমনিরহাটে পূর্ব বড়ুয়া তরুণ সংঘ ক্রিকেট টুর্নামেট-২০২০ অনুষ্ঠিত",
      url: "https://www.alormoni.com/archives/2564",
      image:
        "https://www.alormoni.com/wp-content/uploads/2020/10/News-2-768x431.jpg",
      excerpt:
        "লালমনিরহাট সদর উপজেলার পূর্ব বড়ুয়া তরুণ সংঘের আয়োজনে পূর্ব বড়ুয়া তরুণ সংঘ ক্রিকেট টুর্নামেট-২০২০ এর ফাইনাল খেলা অনুষ্ঠিত হয়েছে।শনিবার (০৩ অক্টোবর) উপজেলার দক্ষিন ধাইরখাতা মনিরের তেপতি স্পোটিং ক্লাব ও পূর্ব বড়ুয়া বন্ধু একাদশ এর অংশগ্রহণে এ খেলা অনুষ্ঠিত হয়। খেলায় চ্যাম্পিয়ন হয়েছে দক্ষিন ধাইরখাতা মনিরের তেপতি স্পোটিং ক্লাব।",
    },
    {
      title: "লালমনিরহাটে পূর্ব বড়ুয়া তরুণ সংঘ এর উদ্যোগে ত্রাণ বিতরণ",
      url: "https://dailymukti.com/%E0%A6%B0%E0%A6%95%E0%A6%AE%E0%A6%BE%E0%A6%B0%E0%A6%BF/%E0%A6%B8%E0%A6%82%E0%A6%97%E0%A6%A0%E0%A6%A8-%E0%A6%B8%E0%A6%82%E0%A6%AC%E0%A6%BE%E0%A6%A6/%E0%A6%B2%E0%A6%BE%E0%A6%B2%E0%A6%AE%E0%A6%A8%E0%A6%BF%E0%A6%B0%E0%A6%B9%E0%A6%BE%E0%A6%9F%E0%A7%87-%E0%A6%AA%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%AC-%E0%A6%AC%E0%A7%9C%E0%A7%81%E0%A7%9F%E0%A6%BE/",
      image:
        "https://api.pbtsbd.org/api/v1/images/1695348070620-449397842-winter-clothe.jpeg",
      excerpt:
        "বিশ্বব্যাপী করোনা ভাইরাসের প্রকোপে বৈশ্বিক অর্থনীতি হুমকীর মুখে। বাংলাদেশের মতো উন্নয়নশীল দেশে করোনাভাইরাস প্রতিরোধ ও মোকাবেলায় দেশব্যাপী লকডাউন চলছে। এতে সবচেয়ে বেশি ক্ষতিগ্রস্থ হচ্ছে অসহায় ও দারিদ্র মানুষগুলো। ঠিক সে সময় লালমনিরহাট সদর উপজেলায় পূর্ব বড়ুয়া তরুণ সংঘ এর উদ্যোগে কুলাঘাট ইউনিয়নে দরিদ্র ও অসহায় মানুষদের মাঝে চাল, ডাল ও সাবান বিতরণ করা হয়।",
    },
    {
      title: "পূর্ব বড়ুয়া তরুণ সংঘের আয়োজনে কৃতি শিক্ষার্থীদের সংবর্ধনা",
      url: "https://www.dainikjamalpur.com/country/11332",
      image:
        "https://www.dainikjamalpur.com/media/imgAll/2020April2nd/Untitled-1-2012121912.jpg",
      excerpt:
        "১২ ডিসেম্বর শনিবার বিকাল ৩টায় লালমনিরহাট সদর উপজেলার কুলাঘাট ইউনিয়নাধীন পূর্ব বড়ুয়া সরকারী প্রাথমিক বিদ্যালয় মাঠে পূর্ব বড়ুয়া তরুণ সংঘের আয়োজনে কৃতি শিক্ষার্থীদের সংবর্ধনা ও দুস্থদের মাঝে শীত বস্ত্র বিতরণ এবং কুইজ প্রতিযোগীতায় বিজয়ীদের পুরস্কার বিতরণী ও আলোচনা সভা অনুষ্ঠিত হয়। ",
    },
    // Add more newspaper links as needed
  ];

  return (
    <div className="m-2 md:m-5">
      <h3 className="text-center text-primary font-bold text-2xl">
        সংবাদপত্রে প্রকাশিত সংবাদ সমূহ
      </h3>

      <div className="mx-2 md:mx-5 my-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {newspaperLinks.map((link, index) => (
            <div
              key={index}
              className="border border-gray-300 rounded-lg overflow-hidden shadow-md"
            >
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <img
                  src={link.image}
                  alt={link.title}
                  className="w-full h-72"
                />
                <div className="px-4 pt-4">
                  <p className="text-primary text-xl font-bold">{link.title}</p>
                </div>
                <div className="p-4">
                  <p className="text-gray-800">
                    {link.excerpt.slice(0, 100)}{" "}
                    <button className="btn btn-link text-blue-500">
                      More Read ...
                    </button>
                  </p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewspaperNews;
