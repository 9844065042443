import React, { useEffect, useState } from "react";
import { BsFacebook } from "react-icons/bs";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";

// Import adviser images
import member01 from "../../assets/members/adviser/01.jpeg";
import member02 from "../../assets/members/adviser/02.jpg";
import member03 from "../../assets/members/adviser/03.JPG";
import member04 from "../../assets/members/adviser/04.jpg";
import member06 from "../../assets/members/adviser/06.jpg";
import member07 from "../../assets/members/adviser/07.jpg";
import member08 from "../../assets/members/adviser/08.jpg";
import member10 from "../../assets/members/adviser/10.jpg";
import member11 from "../../assets/members/adviser/11.jpg";
import member12 from "../../assets/members/adviser/12.jpg";
import member13 from "../../assets/members/adviser/13.jpg";
import member14 from "../../assets/members/adviser/14.jpg";
import member15 from "../../assets/members/adviser/15.jpg";
import member17 from "../../assets/members/adviser/17.jpg";
import member18 from "../../assets/members/adviser/18.jpg";
import member19 from "../../assets/members/adviser/19.jpg";
import member23 from "../../assets/members/adviser/23.jpg";
import logo from "../../assets/image/logo.png";

const Committee = () => {
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState("advisers");
  const pageSize = 12;

  const advisers = [
    {
      name: "মোঃ আরমান রহমান",
      designation: "প্রধান উপদেষ্টা",
      professionInstitute: "ইতিহাস বিভাগ, লালমনিরহাট সরকারী কলেজ",
      professionDesignation: "বিভাগীয় প্রধান",
      mobile: "০১৭১৭৭৫৭২৯৩",
      email: "adviser@pbtsbd.org",
      address: "রাজারহাট কুড়িগ্রাম",
      image: member01,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ ইদ্রিস আলী",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট ইউনিয়ন পরিষদ, সদর, লালমনিরহাট",
      professionDesignation: "চেয়ারম্যান",
      mobile: "০১৭৪০১৩৪৯৩২",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: member02,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ শাহজাহান আলী",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "প্রধান শিক্ষক",
      mobile: "০১৭১৩৭৭৩১৩৭",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member03,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আব্দুল মজিদ",
      designation: "উপদেষ্টা",
      professionInstitute: "ভাটিবাড়ী উচ্চ বিদ্যালয় ",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭২৯৪২০৭৩৮",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member04,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আব্দুল গফুর মন্ডল",
      designation: "উপদেষ্টা",
      professionInstitute: "পূর্ব বড়ুয়া সরকারি প্রাথমিক বিদ্যালয়",
      professionDesignation: "প্রধান শিক্ষক",
      mobile: "০১৭১৮৮৩৭৬৮২",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: logo,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ এমদাদুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৮৪৩৯৯৫৫৬৯",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member06,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ এনামুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট ইউনিয়ন পরিষদ",
      professionDesignation: "ইউপি সদস্য , ০৬ নং ওয়ার্ড",
      mobile: "০১৭৬৭৫৬২৮০৫",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member07,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ তোজাম্মেল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭১৮৪৩২৯৬৬",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member08,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আব্দুল হামিদ",
      designation: "উপদেষ্টা",
      professionInstitute: "মজিদা খাতুন সরকারি কলেজ",
      professionDesignation: "অফিস সহকারী",
      mobile: "০১৭২১৫৪২০৮২",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: logo,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আশরাফুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭১২০১৩৪২০",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member10,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ হারুন অর রশিদ",
      designation: "উপদেষ্টা",
      professionInstitute: "নিলয় ইলেকট্রোনিক্স, কুলাঘাট বাজার",
      professionDesignation: "প্রোপাইটর",
      mobile: "০১৭১৬৯৬৩৮৫০",
      email: "adviser@pbtsbd.org",
      address: "বাশঁপচাই ভিতরকুটি, লালমনিরহাট",
      image: member11,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ সাইফুল আমিন",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭৩৬৫৮৬৬৪১",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: member12,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ এরশাদুল হক",
      designation: "উপদেষ্টা",
      professionInstitute: "সিটি ব্যাংক, ঠাকুরগাঁও শাখা",
      professionDesignation: "ম্যানেজার",
      mobile: "০১৭১৭২৩৯৩৩৭",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member13,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ মিজানুর রহমান মন্টু",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "সভাপতি",
      mobile: "০১৭৩৪৫৭৭৪২৩",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member14,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ গোলাম মোস্তফা রাঙ্গা",
      designation: "উপদেষ্টা",
      professionInstitute: "বাংলাদেশ আনছার ও ভিডিপি, কুড়িগ্রাম",
      professionDesignation: "হিসাব রক্ষক",
      mobile: "01716443461",
      email: "adviser@pbtsbd.org",
      address: "বড়ুয়া, লালমনিরহাট",
      image: member15,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ রবিউল আলম",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট কলেজ",
      professionDesignation: "ভাষা সহকারী",
      mobile: "০১৭২৬৪৪৮৫৩৯",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: logo,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ হযরত আলী",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭১৭১৬২৭৩৫",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: member17,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আবু সাঈদ",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট উচ্চ বিদ্যালয়",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "০১৭১৯৪৯৭৩২৩",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: member18,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আরিফুল ইসলাম",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭১৮৫০০৮৭৫",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: member19,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ শহিদুল ইসলাম",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭২০৮৮৩৯৩৭",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: logo,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আব্দুল হাকিম",
      designation: "উপদেষ্টা",
      professionInstitute: "কুলাঘাট বাজার",
      professionDesignation: "বিশিষ্ট ব্যবসায়ী ও সমাজ সেবক",
      mobile: "০১৭৩৯৫০৮৫৪৮",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: logo,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ মজিবর রহমান",
      designation: "উপদেষ্টা",
      professionInstitute: "উত্তরা ব্যাংক লিমিটেড",
      professionDesignation: "ব্যাংক কর্মকর্তা",
      mobile: "০১৭২১০৩৮০০২",
      email: "adviser@pbtsbd.org",
      address: "কুলাঘাট, লালমনিরহাট",
      image: logo,
      facebookLink: "",
      type: "adviser",
    },
    {
      name: "মোঃ আশরাফুল ইসলাম",
      designation: "উপদেষ্টা",
      professionInstitute: "বিআরডিবি অফিস",
      professionDesignation: "কর্মকর্তা",
      mobile: "০১৭১১৩২৮১১১",
      email: "adviser@pbtsbd.org",
      address: "ধাইরখাতা, লালমনিরহাট",
      image: member23,
      facebookLink: "",
      type: "adviser",
    },
  ];

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await api.get(`/member`);
        setMembers(response.data.data);
      } catch (error) {
        // console.error("Failed to fetch members:", error);
      }
    };

    fetchMembers();
  }, []);

  const pageCount = Math.ceil(
    (activeTab === "advisers" ? advisers : members).length / pageSize
  );

  const goToPage = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < pageCount) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = currentPage * pageSize;
  const selectedMembers = (activeTab === "advisers" ? advisers : members).slice(
    startIndex,
    startIndex + pageSize
  );

  return (
    <div className="py-16 px-4 lg:px-16 min-h-screen">
      <h1 className="text-center font-bold text-3xl">কমিটি</h1>
      <div className="flex justify-center my-4">
        <button
          onClick={() => {
            setActiveTab("advisers");
            setCurrentPage(0);
          }}
          className={`px-4 py-2 mx-2 border rounded-md ${
            activeTab === "advisers" ? "bg-blue-500 text-white" : ""
          }`}
        >
          উপদেষ্টা
        </button>
        <button
          onClick={() => {
            setActiveTab("members");
            setCurrentPage(0);
          }}
          className={`px-4 py-2 mx-2 border rounded-md ${
            activeTab === "members" ? "bg-blue-500 text-white" : ""
          }`}
        >
          কার্যকরী সদস্য
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {selectedMembers.map((member, index) => (
          <div key={index} className="p-4 shadow-md border rounded-lg">
            <img
              src={member.image ? member.image : `${baseUrl}${member.imageUrl}`}
              alt={member.name}
              className="w-full h-96 object-fill p-5 rounded-t-lg"
            />
            <div className="p-4">
              <h3 className="font-bold text-lg">{member.name}</h3>
              <p>
                {member?.type
                  ? member?.designation
                  : member?.organizationDesignation}
              </p>
              <p>
                {member.type ? member.professionInstitute : member.institute}
              </p>
              <p>
                {member?.type
                  ? member.professionDesignation
                  : member.designation}
              </p>
              <p>{member.mobile}</p>
              <p>{member.email}</p>
              <p>{member.address}</p>
              {member.facebookLink && (
                <a
                  href={member.facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsFacebook className="text-blue-600 text-xl" />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-8">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 0}
          className="px-3 py-2 mx-1 border rounded-md"
        >
          <MdOutlineKeyboardArrowLeft />
        </button>
        {Array.from({ length: pageCount }, (_, index) => (
          <button
            key={index}
            onClick={() => goToPage(index)}
            className={`px-3 py-2 mx-1 border rounded-md ${
              index === currentPage ? "bg-blue-500 text-white" : ""
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === pageCount - 1}
          className="px-3 py-2 mx-1 border rounded-md"
        >
          <MdOutlineKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Committee;
