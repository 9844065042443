import React from 'react';

const GeneralMember = () => {
    return (
        <div className='p-2 md:p-5 lg:p-20'>
            <h3 className='font-bold text-center text-2xl text-secondary'>"পূর্ব বড়ুয়া তরুণ সংঘ" এর পক্ষ থেকে স্বাগতম!</h3>
            <p>
                <br />
                <br />
                "পূর্ব বড়ুয়া তরুণ সংঘের" এর সদস্য পদ প্রাপ্তি আবেদনের প্রযোজ্য শর্ত গুলো হলঃ
                <br />
                <br />
                <br />
                ১। প্রাথমিক সদস্যঃ
                <br />
                ২০০ টাকা জমা দিয়ে আবেদন করবে, শর্ত পূরণ ও যাচাই-বাছাই সাপেক্ষে পরবর্তীতে প্রতি মাসে ৫০ টাকা ফি মাসিক চাঁদা হিসেবে দিবেন। সংগঠনের ডোনেশন মেন্যু থেকে নিদির্ষ্ট পেমেন্ট এ
                <br />
                <br />
                <br />
                ২। ডোনার/ দাতা সদস্যঃ
                <br />
                ২০০ টাকা জমা দিয়ে আবেদন করবে, প্রতিমাসে ১০০ চাঁদা প্রদানের সম্মতি সাপেক্ষে একজন আবেদনকারী ডোনার/দাতা সদস্য হিসেবে অন্তর্ভুক্ত হতে পারবেন। সংগঠনের ডোনেশন মেন্যু থেকে নিদির্ষ্ট পেমেন্ট এ
                <br />
                <br />
                <br />
                নোটঃ
                <br />
                ১। যে কোন পরিস্থিতিতে স্বেচ্ছাসেবী হিসেবে কাজ করার মানসিকতা থাকতে হবে। (প্রযোজ্য ক্ষেত্রে)
                <br />
                ২। মাসিক চাঁদা নিয়মিত প্রদান করার অনুরোধ রইল।
                <br />
                ৩। আমরাই পূর্ব বড়ুয়া তরুণ এর সুনাম ক্ষুণ্ণ হয় এমন সকল কার্যক্রম থেকে বিরত থাকতে হবে।
                <br />
                ৪। একই সঙ্গে দুই বা ততোধিক, একই শ্রেনীর সংগঠনের সাথে সংযুক্ত না থেকে নিদিষ্ট একটিতে সংযুক্ত থেকে কাজ করা-ই শ্রেয়।
                <br />
                ৫। স্বেচ্ছাশ্রম এর সাথে কোন বিনিময় সংযুক্ত থাকে না, তাই বিনা স্বার্থে কাজ করার মানসিকতা থাকতে হবে।
                <br />
                <br />
                যথাযথ আবেদন ফর্ম পূরণ ও ডকুমেন্ট সাবমিট সাপেক্ষে, যাচাই-বাচাই পরবর্তীতে সদস্য পদ প্রাপ্তি/অবস্থা সম্পর্কে ই-মেইল এর মাধ্যমে নিশ্চিত করা হবে। দয়া করে ইমেইল (স্পাম) নোটিফিকেশন লক্ষ্য রাখুন। একই সঙ্গে আবেদন কালীন প্রদত্ত ইমেইল ও পাসওয়ার্ড সদসপদপ্রাপ্তি পরবর্তী LOGIN করার জন্য সংরক্ষণ করুন।
                <br />
                <br />
                *** পূর্ব বড়ুয়া তরুণ সংঘের বৃহত্তর/ সামগ্রিক স্বার্থে যে কোন নীতি পরিবর্তন, পরিবর্ধন ও সংশোধন করতে পারবে।
            </p>
        </div>
    );
};

export default GeneralMember;