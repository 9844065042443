import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import Loading from "../Shared/Loading";
import districts from "../../data/district.json";
import upazilas from "../../data/upazila.json";
import unions from "../../data/union.json";

const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const AddBloodDonor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedUpazila, setSelectedUpazila] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  // Function to get district name by ID
  const getDistrictById = (id) => {
    const district = districts.find((district) => district.id === id);
    return district ? district.bn_name : null;
  };

  // Function to get upazila name by ID
  const getUpazilaById = (id) => {
    const upazila = upazilas.find((upazila) => upazila.id === id);
    return upazila ? upazila.bn_name : null;
  };

  // Function to get union name by ID
  const getUnionById = (id) => {
    const union = unions.find((union) => union.id === id);
    return union ? union.bn_name : null;
  };

  const onSubmit = async (data) => {
    if (!data.profileImage[0]) {
      return toast.error("Please provide a profile image");
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("bloodGroup", data.bloodGroup);
    formData.append("mobile", data.mobile);
    formData.append("district", getDistrictById(data.district));
    formData.append("upazila", getUpazilaById(data.upazila));
    formData.append("union", getUnionById(data.union));
    if (data.profileImage[0]) {
      formData.append("profileImage", data.profileImage[0]);
    }

    try {
      const response = await api.post("/blood-donor/create", formData);
      if (response.data.success) {
        toast.success(
          response.data.message
            ? response.data.message
            : "Successfully inserted blood donor"
        );
        reset();
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error ?? error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedUpazila("");
  };

  const handleUpazilaChange = (e) => {
    setSelectedUpazila(e.target.value);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-center font-bold text-red-500 text-3xl">
        ব্লাড ডোনার নিবন্ধন
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-10 place-content-center md:border-2 md:p-10 rounded">
        <div>
          <label className="label">
            <span className="label-text">Name *</span>
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            type="text"
            placeholder="Enter the name"
            className="input input-bordered w-full max-w-xs"
          />
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.name && errors.name.message}
            </span>
          </label>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Blood group *</span>
          </label>
          <select
            {...register("bloodGroup", { required: "Blood group is required" })}
            className="select select-bordered w-full max-w-xs"
          >
            <option value="">Select blood group</option>
            {bloodGroups.map((group) => (
              <option key={group} value={group}>
                {group}
              </option>
            ))}
          </select>
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.bloodGroup && errors.bloodGroup.message}
            </span>
          </label>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Mobile *</span>
          </label>
          <input
            {...register("mobile", { required: "Mobile is required" })}
            type="text"
            placeholder="Enter the mobile number"
            className="input input-bordered w-full max-w-xs"
          />
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.mobile && errors.mobile.message}
            </span>
          </label>
        </div>
        <div>
          <label className="label">
            <span className="label-text">District *</span>
          </label>
          <select
            {...register("district", { required: "District is required" })}
            className="select select-bordered w-full max-w-xs"
            onChange={handleDistrictChange}
          >
            <option value="">Select District</option>
            {districts.map((district) => (
              <option key={district.id} value={district.id}>
                {district.bn_name}
              </option>
            ))}
          </select>
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.district && errors.district.message}
            </span>
          </label>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Upazila *</span>
          </label>
          <select
            {...register("upazila", { required: "Upazila is required" })}
            className="select select-bordered w-full max-w-xs"
            onChange={handleUpazilaChange}
            disabled={!selectedDistrict}
          >
            <option value="">Select Upazila</option>
            {upazilas
              .filter((upazila) => upazila.district_id === selectedDistrict)
              .map((upazila) => (
                <option key={upazila.id} value={upazila.id}>
                  {upazila.bn_name}
                </option>
              ))}
          </select>
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.upazila && errors.upazila.message}
            </span>
          </label>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Union *</span>
          </label>
          <select
            {...register("union", { required: "Union is required" })}
            className="select select-bordered w-full max-w-xs"
            disabled={!selectedUpazila}
          >
            <option value="">Select Union</option>
            {unions
              .filter((union) => union.upazilla_id === selectedUpazila)
              .map((union) => (
                <option key={union.id} value={union.id}>
                  {union.bn_name}
                </option>
              ))}
          </select>
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.union && errors.union.message}
            </span>
          </label>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Profile Image</span>
          </label>
          <input
            type="file"
            {...register("profileImage")}
            className="file-input file-input-bordered file-input-primary w-full max-w-xs"
          />
        </div>
      </div>
      <div className="m-10">
        <input
          className="btn btn-primary text-white w-44"
          value={"নিবন্ধন"}
          type="submit"
        />
      </div>
    </form>
  );
};

export default AddBloodDonor;
