import React from "react";

const GetPrize = () => {
  // Sample data for organization's prizes
  const organizationPrizes = [
    {
      name: "Community Service Award",
      year: 2023,
      description: "Recognizing our dedication to community improvement.",
    },
    {
      name: "Humanitarian Award",
      year: 2022,
      description: "Honoring our commitment to humanitarian causes.",
    },
  ];

  return (
    <div
      className="bg-cover bg-center bg-no-repeat min-h-screen"
      style={{
        backgroundImage: `url('https://img.freepik.com/free-vector/winner-background-first-place-competition_91128-1587.jpg')`,
      }}
    >
      <div className="bg-black bg-opacity-70 min-h-screen flex items-center justify-center">
        <div className="max-w-2xl mx-auto p-4 bg-white rounded-lg shadow-md">
          <h1 className="text-3xl font-semibold text-center mb-4 text-primary">
            প্রাপ্ত পুরষ্কার সমূহ
          </h1>

          <div className="grid grid-cols-1 gap-4">
            {organizationPrizes.map((prize, index) => (
              <div
                key={index}
                className="p-4 bg-gray-200 bg-opacity-80 rounded-lg shadow-md"
              >
                <h2 className="text-xl font-semibold text-gray-800">
                  {prize.name}
                </h2>
                <p className="text-gray-600 text-sm mb-2">Year: {prize.year}</p>
                <p className="text-gray-700">{prize.description}</p>
              </div>
            ))}
          </div>

          <div className="mt-8 text-center">
            <p className="text-lg text-gray-600">
              Thank you for supporting our mission to make a positive impact on
              society.
            </p>
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
              Support Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetPrize;
