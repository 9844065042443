import React from 'react';
import { Link } from 'react-router-dom';

const EducationProject = () => {
    return (
        <div className='md:grid md:grid-cols-2 place-content-center place-items-center m-2 md:m-5'>
            <img src="https://englishtribuneimages.blob.core.windows.net/gallary-content/2022/6/2022_6$largeimg_1680036369.jpg" alt="education" />
            <div>
                <p className='text-xl font-bold text-secondary'><span>৳ ৫০০ -</span> (১ টি শিশুর জন্য শিক্ষা খরচ মাসিক)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ১০০০০ -</span> (২০ টি শিশুর জন্য শিক্ষা খরচ মাসিক)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৪০০০০ -</span> (৮০ টি শিশুর জন্য শিক্ষা খরচ মাসিক)</p>

                <br />
                <p className='font-bold text-red-500'>
                    # এছাড়াও আপনি যেকোনো পরিমান অর্থ শিক্ষা প্রকল্পের জন্য ডোনেট করতে পারেন।
                </p>
                <br />
                <Link to='/donation' className="btn bg-secondary text-white border-0">Donation</Link>
            </div>
        </div>
    );
};

export default EducationProject;