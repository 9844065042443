import { useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../utils/axios.config";
import Loading from "../Shared/Loading";
import { baseUrl } from "../../utils/url";
import { toast } from "react-hot-toast";

function AllUniversityStudent() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allStudent"],
    queryFn: async () => await api.get("university-student"),
  });

  const students = data?.data?.data;

  const handleDeleteStudent = async (id) => {
    const { data } = await api.delete(`university-student/${id}`);
    if (data.success) {
      refetch();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full grid place-content-center">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        সকল পাবলিক বিশ্ববিদ্যালয়ের শিক্ষার্থী সদস্য
      </p>
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
            <tr>
              <th>নাম ও ঠিকানা</th>
              <th>প্রতিষ্ঠান ও ডিপার্টমেন্ট</th>
              <th>যোগাযোগ</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {students?.map((student) => (
              <tr key={student._id}>
                <td>
                  <div class="flex items-center space-x-3">
                    <div class="avatar">
                      <div class="mask mask-squircle w-12 h-12">
                        <img
                          src={`${baseUrl}${student.imageUrl}`}
                          alt="Avatar Tailwind CSS Component"
                        />
                      </div>
                    </div>
                    <div>
                      <p>{student?.name}</p>
                      <p>{student?.address}</p>
                    </div>
                  </div>
                </td>
                <td>
                  {student?.university}
                  <br />
                  {student?.departmentName}
                </td>

                <td>
                  {student.mobile}
                  <br />
                  {student?.email}
                </td>
                <td>
                  <button
                    onClick={() => handleDeleteStudent(student._id)}
                    class="btn btn-link"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AllUniversityStudent;
