import React from 'react';

const QuestionsAnswers = () => {
    return (
        <div>
            <div>
                <div tabindex="0" class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-2 md:m-5">
                    <div class="collapse-title text-xl font-medium">
                        পূর্ব বড়ুয়া তরুণ সংঘ কি আনুষ্ঠানিকভাবে নিবন্ধিত?
                    </div>
                    <div class="collapse-content">
                        <p>না, এটি যুব উন্নয়ন অধিদপ্তর ও সমাজ সেবা অফিসের নিবন্ধনের আবেদন প্রক্রিয়া চলমান রয়েছে।</p>
                    </div>
                </div>
                <div tabindex="1" class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-2 md:m-5">
                    <div class="collapse-title text-xl font-medium">
                        পূর্ব বড়ুয়া তরুণ সংঘ কোন ধরনের কাজ করে?
                    </div>
                    <div class="collapse-content">
                        <p>পূর্ব বড়ুয়া তরুণ সংঘ মানুষের খাদ্য, পোশাক, আবাসন, শিক্ষা ও বেঁচে থাকার প্রয়োজনীয় মৌলিক চাহিদাগুলির জন্য বিভিন্ন ক্রিয়াকলাপ পরিচালনা করে। অনাথ ও বঞ্চিত মানুষের মৌলিক চাহিদা মেটানোর জন্য, শীতকালীন ও বিনামূল্যে শিক্ষা কর্মসূচী সহ বেশ কয়েকটি নিয়মিত কর্মসূচি পরিচালনা করছি।</p>
                    </div>
                </div>
                <div tabindex="2" class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-2 md:m-5">
                    <div class="collapse-title text-xl font-medium">
                        আমি কিভাবে যুক্ত হতে পারি?
                    </div>
                    <div class="collapse-content">
                        <p>আপনি আমাদের সাথে একজন দাতা/স্বেচ্ছাসেবক হয়ে যুক্ত হতে পারেন, বিস্তারিত জানতে ভিজিট করুন http://pbtsbd.org/join</p>
                    </div>
                </div>
                <div tabindex="3" class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-2 md:m-5">
                    <div class="collapse-title text-xl font-medium">
                        পূর্ব বড়ুয়া তরুণ সংঘ এর প্রতিষ্ঠাতা কে?
                    </div>
                    <div class="collapse-content">
                        <p>পূর্ব বড়ুয়া তরুণ সংঘ প্রতিষ্ঠার জন্য উদ্যোগ গ্রহন করেন জনাব আরমান রহমান (২৯তম) বিসিএস শিক্ষা ক্যাডার স্যার এবং সেই উদ্যোগে কাজে লাগিয়ে  সংগঠন টি এগিয়ে নিয়ে যাচ্ছেন মোঃ সায়হান সৈকত, উদ্যোক্তা, কুলাঘাট ইউনিয়ন ডিজিটাল সেন্টার</p>
                    </div>
                </div>
                <div tabindex="4" class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-2 md:m-5">
                    <div class="collapse-title text-xl font-medium">
                        পূর্ব বড়ুয়া তরুণ সংঘ কি ধরণের প্রতিষ্ঠান?
                    </div>
                    <div class="collapse-content">
                        <p>পূর্ব বড়ুয়া তরুণ সংঘ একটি অরাজনৈতিক সামাজিক স্বেচ্ছাসেবী সংগঠন</p>
                    </div>
                </div>
                <div tabindex="5" class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-2 md:m-5">
                    <div class="collapse-title text-xl font-medium">
                        কিভাবে আমার প্রতিষ্ঠান পূর্ব বড়ুয়া তরুণ সংঘ কে সহায়তা করতে পারে?
                    </div>
                    <div class="collapse-content">
                        <p>এই প্রতিষ্ঠান কে সমৃদ্ধি করার জন্য শুধু মাত্র আর্থিক সহায়তাই যথেষ্ট নয়। আপনার যোগাযোগ মাধ্যমের দক্ষতা দিয়ে আরো কিছু মহৎ প্রাণ মানুষদের আমাদের সাথে যুক্ত করতে পারেন। নিম্নবর্ণিত উপায়ে আপনি আমাদের সাথে যুক্ত হতে পারেনঃ ক) আপনাদের কর্মীদের আমাদের স্বেচ্ছাসেবক কাজে যুক্ত করে। </p>
                    </div>
                </div>
                <div tabindex="6" class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-2 md:m-5">
                    <div class="collapse-title text-xl font-medium">
                        আপনারা কি রাস্তায় দাঁড়িয়ে তহবিল সংগ্রহ করেন?
                    </div>
                    <div class="collapse-content">
                        <p>জ্বি না, আমরা আমাদের ব্যাংক অ্যাকাউন্ট, বিকাশ, রকেট, পেপল এবং শাখা অফিসের মাধ্যমে তহবিল সংগ্রহ করে থাকি।
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionsAnswers;