import React from 'react';

const Join = () => {
    return (
        <div className='px-2'>
            <div>
                <h3 className='text-3xl font-bold text-center text-primary my-5'>সদস্যের আবেদন ফরম</h3>

                <div className='md:grid grid-cols-2 lg:grid-cols-3 place-items-center mb-10'>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">নাম</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর নাম" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">পিতার নাম</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর পিতার নাম" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">মাতার নাম</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর মাতার নাম" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">বর্তমান ঠিকানা</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর বর্তমান ঠিকানা" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">স্থায়ী ঠিকানা</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর স্থায়ী ঠিকানা" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">জন্ম নিবন্ধন/NID নং</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর জন্ম নিবন্ধন/NID নং" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">জন্ম তারিখ</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর জন্ম তারিখ" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">রক্তের গ্রুপ</span>
                        </label>
                        <select class="select select-bordered">
                            <option value='' disabled selected>রক্তের গ্রুপ</option>
                            <option value="a+">A+</option>
                            <option value="a-">A-</option>
                            <option value="b+">B+</option>
                            <option value="b-">B-</option>
                            <option value="ab+">AB+</option>
                            <option value="ab-">AB-</option>
                            <option value="o+">O+</option>
                            <option value="o-">O-</option>
                        </select>
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">পেশা</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর পেশা" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">ধর্ম</span>
                        </label>
                        <select class="select select-bordered">
                            <option value='' disabled selected>ধর্ম</option>
                            <option value="islam">ইসলাম</option>
                            <option value="hindu">হিন্দু</option>
                            <option value="khristan">খ্রিষ্টান</option>
                            <option value="boddho">বৌদ্ধ</option>
                            <option value="unknow">অজানা</option>
                        </select>
                    </div>

                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">জাতীয়তা</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর জাতীয়তা" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">শিক্ষাগত যোগ্যতা</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর শিক্ষাগত যোগ্যতা" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs my-2">
                        <label class="label">
                            <span class="label-text">ফেসবুক আইডি</span>
                        </label>
                        <input type="text" placeholder="আবেদনকারীর ফেসবুক আইডি" class="input input-bordered w-full max-w-xs" />
                    </div>
                </div>
            </div>
            <div className='flex justify-center mb-10'>
                <button className='btn btn-secondary text-white w-52'>সাবমিট</button>
            </div>
        </div>
    );
};

export default Join;