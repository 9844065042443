import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div class="drawer drawer-mobile ">
      <input id="my-drawer-2" type="checkbox" class="drawer-toggle" />
      <div class="drawer-content flex flex-col p-5">
        <label
          for="my-drawer-2"
          class="btn btn-primary drawer-button lg:hidden"
        >
          Open drawer
        </label>
        <Outlet />
      </div>
      <div class="drawer-side">
        <label for="my-drawer-2" class="drawer-overlay"></label>
        <ul class="menu p-4 w-80 bg-secondary rounded-lg text-base-content">
          <li>
            <p className="font-bold text-white text-lg">ড্যাশবোর্ড</p>
          </li>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>হেডার ব্যানার</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-header-banner"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      নতুন ব্যানার যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/added-header-banner"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের ব্যানার সমূহ
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>নোটিশ</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-notice"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      নতুন নোটিশ যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/manage-notices"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের নোটিশ সমূহ
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>ব্লাড ডোনার</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-blood-donor"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      নতুন ব্লাড ডোনার যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/manage-blood-donor"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের ব্লাড ডোনার বৃন্দ
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>সাম্প্রতিক কার্যক্রম</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-activity"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      নতুন কার্যক্রম যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/activities"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের কার্যক্রম সমূহ
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>সদস্য</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-member"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      কার্যকরী সদস্য যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/all-member"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      সকল কার্যকরী সদস্য
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/add-public-university-student"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পাবলিক বিশ্ববিদ্যালয়ের শিক্ষার্থী যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/all-public-university-student"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পাবলিক বিশ্ববিদ্যালয়ের শিক্ষার্থী
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/all-user"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      সকল ব্যবহারকারীর তালিকা
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>শিক্ষা বৃত্তি</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/scholarship-application"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      সকল আবেদন
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>মেধাবী শিক্ষার্থী সংবর্ধনা</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/meritorious-student"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      সকল আবেদন
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>প্রধান উপদেষ্টার বাণী</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-adviser-message"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      বাণী যুক্ত করুন
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/dashboard/adviser-message"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের বাণী সমূহ
                    </NavLink>
                  </li> */}
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>প্রতিষ্ঠাতা সভাপতির বাণী</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-president-message"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      বাণী যুক্ত করুন
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/dashboard/president-message"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের বাণী সমূহ
                    </NavLink>
                  </li> */}
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>ডোনার ও পার্টনার</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-donor-partner"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      ডোনার ও পার্টনার যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/donor-partner"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের ডোনার ও পার্টনার সমূহ
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>সহযোগিতা প্রদান</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/add-provide-help"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      সহযোগিতা প্রদান যুক্ত করুন
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/provide-help"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      পূর্বের সহযোগিতা প্রদান সমূহ
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <ul class="menu w-full text-white">
            <li>
              <details open={false}>
                <summary>মিলন মেলা ২০২৩</summary>
                <ul>
                  <li>
                    <NavLink
                      to="/dashboard/picnic/application"
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white font-bold text-secondary"
                          : "font-thin text-white m-1"
                      }
                    >
                      সকল নিবন্ধন
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
