import React from 'react';
import { Link } from 'react-router-dom';

const JoinUsBanner = () => {
    return (
        <div class="hero min-h-screen bg-[url('/src/assets/image/team.jpeg')]" >
            <div class="hero-overlay bg-opacity-60"></div>
            <div class="hero-content text-center text-white">
                <div class="max-w-md">
                    <h1 class="mb-5 text-4xl font-bold">স্বেচ্ছাসেবক হোন</h1>
                    <p class="mb-5">আপনি কি একজন স্বেচ্ছাসেবক হতে প্রস্তুত? আপনি আমাদের সাথে স্বেচ্ছাশ্রমে মাধ্যমে আপনার সময়, দক্ষতা এবং জ্ঞান অবদান রাখতে পারেন । এটি একটি ইতিবাচক প্রভাব তৈরি করার একটি সুযোগ এবং শান্তি ও উন্নয়ন অর্জনের জন্য একটি গুরুত্বপূর্ণ শক্তি । অনেকের জীবনে একটা পার্থক্য গড়ে তুলুন!</p>
                    <Link to="/join" class="btn btn-primary text-white">এখনি যোগ দিন</Link>
                </div>
            </div>
        </div>


    );
};

export default JoinUsBanner;