import React from "react";
import logo from "../../assets/image/logo.png";

const Rules = () => {
  return (
    <div className="container mx-auto p-6 ">
      {/* Header */}
      <header className="text-center my-12">
        <h1 className="text-5xl font-extrabold text-primary">
          সংগঠনের গঠনতন্ত্র
        </h1>
      </header>

      {/* Section 1 */}
      <section className="mb-12 bg-primary text-white p-10 rounded">
        <h2 className="text-4xl font-bold text-white mb-6">
          ১ম ভাগঃ সংগঠনের পরিচিতি
        </h2>
        <p className="text-lg text-white mb-4">
          ‘পূর্ব বড়ুয়া তরুণ সংঘ’ ২০১৭ সালের ১৩ এপ্রিল বাংলাদেশের রংপুর বিভাগ
          লালমনিরহাট জেলার লালমনিরহাট সদর উপজেলার কুলাঘাট ইউনিয়নের পূর্ব বড়ুয়া
          গ্রামে প্রতিষ্ঠিত হয়।
        </p>

        {/* Subsection 1 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-১: সংগঠনের নামকরণ এবং স্লোগান
          </h3>
          <p className="text-lg text-white">
            এই সংগঠন 'পূর্ব বড়ুয়া তরুণ সংঘ'- ইংরেজিতে ‘East Barua Youth
            Association’ নামে অভিহিত হবে। সংগঠনের স্লোগান হবে- ‘আমরাই তরুন আমরাই
            উদ্যোমি’
          </p>
        </div>

        {/* Subsection 2 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-২: সংগঠনের ধরণ এবং বৈশিষ্ট্য
          </h3>
          <p className="text-lg text-white">
            আমরা ভালবাসা, আশা এবং সামাজিক ন্যায়বিচার সমবেত একটি পৃথিবীর স্বপ্ন
            দেখি, যেখানে মানুষ মর্যাদা ও নিরাপত্তা নিয়ে বাস করবে। সমাজের অনগ্রসর
            শ্রেণীকে বিশেষভাবে চরম দরিদ্র, গৃহহীন ও অনাথ শিশুদেরকে শিক্ষা,
            পুষ্টি ও আশ্রয় সরবরাহের মাধ্যমে তাদের জাতির জন্য সম্পদে পরিণত করা
            আমাদের প্রধান লক্ষ্য। ইহা সম্পূর্ণ অরাজনৈতিক, অলাভজনক, সামাজিক
            উন্নয়নমুলক, সাংস্কৃতিক ও সেচ্ছায় মানব সেবার সংগঠন। এই সংগঠন
            স্কুল/কলেজের ছাত্র, এলাকার তরুণ যুবক এবং ৩ ধরনের সদস্য নিয়ে গঠিত হবে
            এবং সর্ব শ্রেণীর উন্নয়নের জন্য কার্যক্রম পরিচালনা করবে।
          </p>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ১) ‘পূর্ব বড়ুয়া তরুণ সংঘ’ সম্পূর্ণ স্বাধীন, সামাজিক, সেচ্ছাসেবী,
              সাংস্কৃতিক ও সাম্প্রদায়িক মনোভাবসম্পন্ন সংগঠন। এই সংগঠন অন্য কোন
              সংগঠনের অঙ্গ-সংগঠন হিসেবে কাজ করবে না বরং ভবিষ্যতে বিভিন্ন গ্রামে
              আমাদের শাখা সংগঠন করা হবে এবং এর নেতৃত্বে পরিচালনা করা হবে।
            </li>
            <li>
              ২) ‘পূর্ব বড়ুয়া তরুণ সংঘ’ সম্পূর্ণ অরাজনৈতিক সংগঠন। অত্র সংগঠনের
              কোন সদস্য রাজনীতির সাথে জরিত থাকলে এটা তার ব্যক্তিগত কাজ। এখানে
              সংগঠন কোনভাবে হস্তক্ষেপ করতে পারবেনা।
            </li>
            <li>
              ৩) ‘পূর্ব বড়ুয়া তরুণ সংঘ’ সম্পূর্ণ অরাজনৈতিক সংগঠন। অত্র সংগঠনের
              কোন সদস্য রাজনীতির সাথে জরিত থাকলে এটা তার ব্যক্তিগত কাজ। এখানে
              সংগঠন কোনভাবে হস্তক্ষেপ করতে পারবেনা। কিন্তু এই সংগঠনের কোন সদস্য
              যদি সংগঠনের বিভিন্ন কর্মকান্ডকে রাজনীতির সাথে সম্পৃক্ত করেন বা
              করার চেষ্টা করেন তবে তাকে কার্যনির্বাহী পরিষদ বহিষ্কারের ক্ষমতা
              রাখে।
            </li>
            <li>
              ৩) ‘পূর্ব বড়ুয়া তরুণ সংঘ’ সম্পূর্ণ অরাজনৈতিক সংগঠন। অত্র সংগঠনের
              কোন সদস্য রাজনীতির সাথে জরিত থাকলে এটা তার ব্যক্তিগত কাজ। এখানে
              সংগঠন কোনভাবে হস্তক্ষেপ করতে পারবেনা। কিন্তু এই সংগঠনের কোন সদস্য
              যদি সংগঠনের বিভিন্ন কর্মকান্ডকে রাজনীতির সাথে সম্পৃক্ত করেন বা
              করার চেষ্টা করেন তবে তাকে কার্যনির্বাহী পরিষদ বহিষ্কারের ক্ষমতা
              রাখে।
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-৩: সংগঠনের কার্যালয়
          </h3>
          <p className="text-lg text-white">
            ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর একটি কার্যালয় বর্তমানে অস্থায়ী করা হবে,
            ভবিষ্যতে সংগঠনের আর্থিক সঙ্গতি বৃদ্ধি সাপেক্ষে পূর্ব বড়ুয়া গ্রামে যে
            কোন সুবিধাজনক স্থানে নিজস্ব অথবা ভাড়া করা স্থানে সংগঠনের কার্যালয়
            স্থাপন করা হবে।
          </p>
        </div>

        {/* Subsection 4 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-৪: কার্য এলাকা
          </h3>
          <p className="text-lg text-white">
            এই প্রতিষ্ঠানের কার্য এলাকা প্রাথমিকভাবে পূর্ব বড়ুয়া গ্রাম ও ইউনিয়নে
            সীমাবদ্ধ থাকবে। পরবর্তীতে আলাদা আলাদাভাবে কার্যনির্বাহী পরিষদ এবং
            উপদেষ্টা পরিষদের দুই তৃতীয়াংশ সদস্যের মতামতের ভিত্তিতে কার্য এলাকা
            সম্প্রসারণ করা যেতে পারে।
          </p>
        </div>

        {/* Subsection 5 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-৫: সংগঠনের লোগো/মনোগ্রামের বিবরণ
          </h3>
          <p className="text-lg text-white">
            ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর নিজস্ব মনোগ্রাম ও ওয়েব সাইট, ইমেইল,
            ফেইসবুক পেজ রয়েছে।
          </p>
          <p className="text-lg text-white">
            ওয়েব সাইট-{" "}
            <a href="http://pbtsbd.org/" className="text-blue-500">
              http://pbtsbd.org/
            </a>
            , Email:{" "}
            <a href="mailto:support@pbtsbd.org" className="text-blue-500">
              support@pbtsbd.org
            </a>
            ,
            <a href="mailto:info@pbtsbd.org" className="text-blue-500">
              info@pbtsbd.org
            </a>
          </p>
          <p className="text-lg text-white">
            ফেসবুক পেজ{" "}
            <a
              href="https://www.facebook.com/pbtsbd/"
              className="text-blue-500"
            >
              https://www.facebook.com/pbtsbd/
            </a>
          </p>
        </div>

        {/* Subsection 6 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-৬: লক্ষ্য ও উদ্দেশ্য
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>প্রধানত সামাজিক ও শিক্ষা কর্মকাণ্ড পরিচালনা করবে।</li>
            <li>
              এলাকাবাসির মধ্যে আত্মনির্ভরশীলতা অর্জনের প্রত্যয় সৃষ্টি করা।
            </li>
            <li>
              এলাকার গরীব, অসহায় মেধাবী ছাত্র-ছাত্রীদের পড়া লেখা চালিয়ে যেতে
              সহায়তা বা উদ্ভুদ্ব করা এবং বিনামুল্যে বই বিতরন করা।
            </li>
            <li>
              ছাত্র-ছাত্রীদের মেধা ও সৃজনশীলতার সর্বাধিক বিকাশের লক্ষ্যে তাদেরকে
              প্রণোদিত ও সংগঠিত করা ও মেধাবী ছাত্র ছাত্রীদেরকে উপবৃত্তি প্রদান
              করা।
            </li>
            <li>
              রমজান মাসে অসহায় গরিবদের মাঝে ইফতারি সামগ্রী বিতরন করা ও ইফতার
              মাহফিল আয়োজন করা।
            </li>
            <li>
              “ঈদ উৎসব” ঈদের আগের দিন অসহায় গরিবের মাঝে ঈদ প্যাকেজ বিতরন করা।
            </li>
            <li>
              সমাজের সবার মধ্যে সামাজিক দায়বদ্ধতা বোধ সৃষ্টি করে সমাজ সচেতন
              নাগরিক হিসেবে গড়ে তোলা।
            </li>
            <li>
              পূর্ব বড়ুয়া তরুন সংঘ সামাজিক সংগঠনের প্রতিটি সদস্যকে কাজের মাধ্যমে
              সফল, স্বয়ংক্রিয় ও স্বেচ্ছাসেবী হয়ে ওঠার লক্ষ্যে ক্ষমতায়িত করা।
            </li>
            <li>
              শিশু কল্যাণঃ এলাকার গরীব শিশু-কিশোরদের অক্ষরদান দেয়ার জন্য
              গণশিক্ষা কেন্দ্র/পাঠাগার প্রতিষ্ঠা করা এবং দরিদ্র শিশুদের
              খেলাধুলার পাশাপাশি সু-স্বাস্থ্য নিশ্চিত কল্পে ফ্রি চিকিৎসার
              ব্যবস্থা করা এবং শিশুদের বাল্যবিবাহ রোধে সভা-সেমিনার ও গনসচেতনতা
              সৃষ্টি করা।
            </li>
            <li>
              এলাকার সকল প্রকার খেলাধুলার আয়োজন, অংশগ্রহন ও প্রতিভাবান
              খেলোয়ারদের প্রশিক্ষন এর ব্যাবস্থা করা। সংশ্লিস্ট খেলায় জেলা, বিভাগ
              ও জাতীয় পর্যায়ে অংশ গ্রহন করার ব্যবস্থা করা।
            </li>
            <li>মাদক মুক্ত এলাকা গড়তে প্রশাসনকে সহযোগিতা করা।</li>
            <li>
              এলাকার মাদকাসক্ত, জুয়াড়ি, বখাটে ও অপরাধীদের সুস্থ জীবনে ফিরিয়ে
              আনার লক্ষে বিনোদন, গনসচেতনতা ও চিকিৎসার ব্যাবস্থা করা এবং
              কর্মসংস্থানের জন্য উৎসাহ প্রদান করা।
            </li>
            <li>
              সমাজ বিরোধী কার্যকলাপ হইতে জনগণকে বিরত রাখিবার উদ্দেশ্যে
              চিত্ত-বিনোদন ও সাংস্কৃতিক কর্মসূচীর ব্যবস্থা করা।
            </li>
            <li>
              যে কোন সেবামূলক কাজে জনগনকে উদ্ভুদ্ব করা এবং জনগনকে সেবামূলক কাজে
              সহযোগিতা করা। এমনকি খাদ্য দ্রব্যকে বিষ মুক্ত রাখা ও কৃষকদেরকে
              কেমিক্যাল ব্যতিত ফসল উৎপাদানের ব্যপারে পরামর্শ দেয়া।
            </li>
            <li>
              সরকারের উন্নয়ন মূলক সংস্থা সমূহের সহায়ক শক্তি হিসাবে কাজ করা।
              গণ-শিক্ষা গ্রহণে বয়স্কদের উদ্ভুদ্ব করা, উন্নত প্রযুক্তির কৃষি,
              মৎস্য চাষ, হাঁস মুরগী পালন, হস্ত ও কুটির শিল্প স্থাপন, হেচারী ও
              নার্সারী সহ অন্যান্য কার্যক্রম সম্মন্ধে গ্রামবাসীর মধ্যে জ্ঞান
              দানের নিমিত্তে সংশ্লিষ্ট উন্নয়ন মূলক কর্মকান্ডে আমন্ত্রন করা এবং
              সভা ও সেমিনারের আয়োজন করা।পরিবার পরিকল্পনার মাঠকর্মীদের সাথে
              সমন্বয় সাধন করে পরিকল্পিত পরিবার গঠনে জনগনকে উদ্ধুদ্ধ করা।
            </li>
            <li>
              দেশের দুর্ভিক্ষ, বন্যা, ঘুর্ণিঝড়, ভূমিকম্প, মহামারী, অনাবৃষ্টি,
              অতিবৃষ্টি, সকল প্রকার প্রাকৃতিক দুর্যোগে সাহায্য সামগ্রী নিয়ে
              দুঃস্হ, ও ক্ষতিগ্রস্থদের পাশে এগিয়ে যাওয়া শীত বস্ত্র বিতরন এবং
              তহিবল বৃদ্ধি ও সংরক্ষনার্থে দান।
            </li>
            <li>
              ফুটবল, ক্রিকেট বেটমিন্টন টুর্নামেন্ট ও সাংস্কৃতিক অনুষ্ঠানের আয়োজন
              করা।
            </li>
          </ul>
        </div>
      </section>
      <section className="mb-12  bg-secondary text-white p-10 rounded">
        <h2 className="text-4xl font-bold text-white mb-6">
          ২য় ভাগঃ সদস্যপদ সংক্রান্ত
        </h2>

        {/* Subsection 7 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-৭: সদস্য
          </h3>
          <p className="text-lg text-white mb-4">
            ক) কেবল মাত্র জন্মসূত্রে/পৈতৃকসূত্রে/মাতৃকসূত্রে
            ইউনিয়নের/উপজেলার/জেলার/বিভাগের/বাংলাদেশী এবং উৎসাহিত ও সংগঠনের
            লক্ষ্য ও উদ্দেশ্য,গঠনতন্ত্রের সাথে সমমনা কর্মীগণ এ সংগঠনের সদস্য হতে
            পারবে।
          </p>
          <p className="text-lg text-white mb-4">
            খ) সদস্যের শ্রেণীবিভাগ- নিম্নরূপ চার প্রকারের সদস্য থাকবেঃ
          </p>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              অ) সাধারণ সদস্য: শর্তের সাথে সঙ্গতিপূর্ণ যে কোন নাগরিক টাকা প্রদান
              সাপেক্ষে ফরম পূরণের মাধ্যমে সাধারণ সদস্য হতে পারবেন।
            </li>
            <li>
              আ) সহযোগী সদস্য: শর্তের সাথে সঙ্গতিপূর্ণ প্রবাসী নাগরিকগণ বাৎসরিক
              নূন্যতম টাকা প্রদান সাপেক্ষে ফরম পূরণের মাধ্যমে সহযোগী সদস্য হতে
              পারবেন।
            </li>
            <li>
              ই) সম্মানিত সদস্য: শর্তের সাথে সঙ্গতি রেখে স্ব স্ব ক্ষেত্রে বিশেষ
              অবদানের স্বীকৃতি স্বরূপ কোন ব্যক্তিকে কার্য নির্বাহী পরিষদের
              অনুমোদন ক্রমে সম্মানিত সদস্য পদ প্রদান করা হবে।
            </li>
            <li>
              ঈ) আজীবন সদস্য: শর্তের সাথে সঙ্গতি রেখে এককালীন টাকা প্রদান
              সাপেক্ষে ফরম পূরণের মাধ্যমে যে কোন প্রাপ্ত বয়স্ক ব্যক্তি সংগঠনের
              আজীবন সদস্য হতে পারবেন।
            </li>
            <li>
              উ) প্রত্যেক সদস্যকে বাধ্যতামূলক প্রতি মাসে সংগঠনের অনলাইন পেমেন্ট
              গেটওয়ের মাধ্যমে মাসিক ফি দিতে হবে।
            </li>
            <li>
              ঊ) নির্বাহী পরিষদ সদস্যদের মাসিক ফি পরিবর্তনের অধিকার সংরক্ষণ করে।
            </li>
          </ul>
        </div>

        {/* Subsection 8 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-৮: সদস্য হওয়ার যোগ্যতা
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর একটি সদস্য হবার নূন্যতম বয়স ১৪ বছর।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ কর্তৃক নির্ধারিত ওয়েব সাইটের মাধ্যমে আবেদন
              করতে হবে।
            </li>
            <li>ভদ্র, রুচিশীল, উদ্যোমী, সদাচারী ও মননশীল হতে হবে।</li>
            <li>
              প্রত্যেক সদস্যকে প্রতি মাসে কার্যনির্বাহী কমিটির সিদ্ধান্ত অনুযায়ী
              মাসিক চাঁদা দিতে হবে।
            </li>
            <li>
              যারা স্বেচ্ছাব্রতী মনোভাবাপন্ন এবং নিজেদের তথা দেশের ও এলাকার
              উন্নয়নে কাজ করার লক্ষ্যে সমাজ উন্নয়নমূলক বিভিন্ন সৃজনশীল কাজে
              সম্পৃক্ত কিংবা সম্পৃক্ত হতে ইচ্ছুক এবং নৈতিকতা বিরোধী কোনো
              কার্যক্রমে লিপ্ত নয়।
            </li>
            <li>
              যারা নিজেদের মেধার সর্বোচ্চ বিকাশে প্রতিশ্রুতিবদ্ধ এবং একটি
              ক্ষুধামুক্ত ও আত্মনির্ভরশীল বাংলাদেশ ও এলাকা গঠনে বিশেষ ভূমিকা
              রাখতে আগ্রহী।
            </li>
          </ul>
        </div>

        {/* Subsection 9 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-৯: সদস্যপদ বাতিল
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>‘পূর্ব বড়ুয়া তরুণ সংঘ’ কোন সদস্য যদি মানসিক ভারসাম্য হারান।</li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ কোন সদস্য যদি একটানা ৩ মাসের মাসিক চাঁদা
              প্রদান না করেন।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর কোন সদস্য সমাজ ও রাষ্ট্র বিরোধী
              কার্যকলাপে লিপ্ত হলে।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর গঠনতন্ত্র ও নিজ স্বার্থের পরিপন্থী কোন
              কাজ করেন বা তার স্বভাব আচার-আচরণ সংগঠনের অ-পরিপন্থী হয়।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর দায়িত্ব ও কর্তব্য যদি যথারীতি পালন না
              করেন বা সংগঠনের কাজে নিষ্ক্রিয় ও অকর্মণ্য হয়ে পরেন।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ প্রাসঙ্গিক কারণে কোন সদস্যকে বহিষ্কার করার
              এখতিয়ার সংগঠনের প্রধান ব্যক্তিগণ সংরক্ষণ করেন।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ কোন সদস্য অত্র সংগঠনের সংবিধান পরিপন্থী কোন
              কাজ করলে কার্যকরী পরিষদের সভায় তিন চতুর্থাংশের ভোটে তার সদস্যপদ
              বাতিল করা হবে।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ যে কোন সদস্য পদত্যাগ করিতে চাইলে তিনি
              সংশ্লিষ্ট কমিটির সভাপতি/সাধারন সম্পাদক এর নিকট পদত্যাগ পত্র পেশ
              করিবেন। কার্যকরী কমিটির সভায় উহা গৃহিত হইলে উক্ত সদস্যের সদস্য পদ
              বাতিল হইবে অথবা প্রত্যাহারের অনুরোধ জানানো যাইবে।
            </li>
            <li>
              সেচ্ছায় যদি কোন সদস্য তার সদস্যপদ বাতিল করে এবং উপড়ের যেকোন কারণে
              তার সদস্যপদ বাতিল হয়, উক্ত সদস্যের যদি সদস্যপদ ২ বছর পূর্ণ না হয়
              তাহলে উক্ত সদস্যের ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এ কোন প্রকার দাবী
              গ্রহণযোগ্য নয় । তবে এ কেত্রে উক্ত সদস্যের পরিস্থিতি বিবেচনা করে
              কার্য নির্বাহী পরিষদ যে কোন সিদ্ধান্ত গ্রহণ করতে পারবে ।
            </li>
          </ul>
        </div>

        {/* Subsection 10 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-১০: পদ হতে ইস্তফা
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              কার্যনির্বাহী পরিষদের কোন সদস্য অথবা যে কোন সাধারন সদস্যও ইস্তফা
              দিলে অবশ্যই তার কারণ উল্লেখ করে সভাপতি বরাবর পেশ করতে হবে।
            </li>
            <li>
              সভাপতি কার্যকরী পরিষদের সর্বসম্মতি ক্রমে সদস্যের পদত্যাগ পত্র
              গ্রহণ কিংবা বাতিল করতে পারবেন। অবশ্য উপদেষ্টা পরিষদের ক্ষেত্রে
              এরকম ঘটনা ঘটলে উপদেষ্টা পরিষদ নিজেই তার সমাধান করবে।
            </li>
          </ul>
        </div>

        {/* Subsection 11 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-১১: সদস্যদের অধিকার
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              সাধারণ সদস্যগণের ভোটাধিকার সংরক্ষিত থাকবে এবং সংগঠনের গুরুত্বপূর্ণ
              বিষয়ে মতামত প্রদানের অধিকারও সংরক্ষিত থাকবে।
            </li>
            <li>
              সাধারণ সদস্যগণ কর্তৃক সাধারন সদস্যগণের মধ্য থেকে কার্যনির্বাহী
              পরিষদ নির্বাচন করা হবে।
            </li>
            <li>
              সংগঠনের উন্নয়ন ও সমাজ উন্নয়নে সাধারণ সদস্যগণ মতামত ও সুপারিশ পেশ
              করবেন বা মতামত প্রকাশ করবেন।
            </li>
            <li>
              সাধারণ সদস্যগণ নিম্নবর্ণিত বিষয়সমূহ অনুমোদন করবেন:
              <ul className="list-disc list-inside text-lg text-white space-y-2 ml-6">
                <li>গঠনতন্ত্র পরিবর্তন, পরিবর্ধন/পরিমার্জন ও সংযোজন।</li>
                <li>বার্ষিক হিসাব প্রতিবেদন।</li>
                <li>বার্ষিক হিসাব ও বাজেট।</li>
                <li>কার্যনির্বাহী পরিষদ গঠন।</li>
                <li>ভোটাধিকার প্রয়োগ করা।</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section className="mb-12  bg-primary text-white p-10 rounded">
        <h2 className="text-4xl font-bold text-white mb-6">
          ৩য় ভাগঃ সাংগঠনিক কাঠামো
        </h2>

        {/* Subsection 12 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-১২: ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর সাংগঠনিক পরিষদ
          </h3>
          <p className="text-lg text-white mb-4">
            ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর সাংগঠনিক পরিষদ নিম্নরুপঃ
          </p>
          <ul className="list-decimal list-inside text-lg text-white space-y-2">
            <li>উপদেষ্টা পরিষদ</li>
            <li>কার্যনির্বাহী পরিষদ</li>
            <li>সাধারণ সদস্য</li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১) উপদেষ্টা পরিষদঃ
          </h4>
          <p className="text-lg text-white mb-4">
            সমাজের বিশেষ কোন ব্যক্তিবর্গের সমন্বয়ে উপদেষ্টা পরিষদ গঠিত হবে। এই
            পরিষদ ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর উন্নয়নের জন্য সাধারণ পরিষদ ও কর্যকরী
            পরিষদকে পরামর্শ দাতা হিসাবে কাজ করবেন। এই পরিষদ ‘পূর্ব বড়ুয়া তরুণ
            সংঘ’ এর বিশেষ কোন অনুষ্ঠান কিংবা সাধারণ পরিষদ সভায় উপস্থিত থাকবে। এই
            পরিষদ সভায় কার্যনির্বাহী পরিষদের কর্মকর্তাগনও উপস্থিত থাকবে।
          </p>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              কার্যনির্বাহী পরিষদকে প্রয়োজনীয় পরামর্শ দিয়ে সহযোগীতা করবেন।
            </li>
            <li>
              সদস্যদের মধ্যে কোন কারনে মতানৈক্য দেখা দিলে উপদেষ্টা পরিষদ সমাধান
              করবেন। তবে এক্ষেত্রে সভাপতিকে সভার আহবান করতে হবে।
            </li>
            <li>
              সার্বিক পরিকল্পনা প্রণয়নে উপদেষ্টা পরিষদ দিক নির্দেশকের ভূমিকা
              পালন করবেন।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর উন্নয়নের জন্য আর্থিক সহযোগিতা করবেন।
            </li>
            <li>বিভিন্ন দাতা গোষ্ঠীর সাথে যোগযোগ পূর্বক উদ্ভুদ্ব করবেন।</li>
            <li>‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর কল্যাণে পরামর্শ প্রদান করবেন।</li>
            <li>বৎসরে কমপক্ষে একবার এই পরিষদ সভা করবেন।</li>
            <li>প্রতিষ্ঠানের যে কোন অনুষ্ঠানে যোগদান করবেন।</li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (২) কার্যনির্বাহী পরিষদঃ
          </h4>
          <p className="text-lg text-white mb-4">
            এই পরিষদ ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর উন্নয়নের জন্য সর্বাধিক কাজ করবেন।
            এই পরিষদ সংগঠনের যাবতীয় আয়, ব্যয়, বাজেট কর্ম-পরিকল্পনার রিপোর্ট তৈরী
            করবেন।
          </p>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সাধারন সভা ও মাসিক সভা আহবান করা।</li>
            <li>
              বিশেষ প্রয়োজনে সভাপতির আহবানে জরুরী সিদ্ধান্তের মাধ্যমে কোন
              সিদ্ধান্ত গ্রহন করা।
            </li>
            <li>
              সভায় গৃহিত সিদ্ধান্ত সভাপতি ও সাধারণ সম্পাদকের যুক্ত স্বাক্ষরে
              বাস্তবায়িত হবে।
            </li>
            <li>
              নির্বাহী পরিষদ এ সংগঠনের যাবতীয় কার্যাবলীর চুড়ান্ত সিদ্ধান্ত
              প্রদানকারী ও নীতি নির্ধারক বলে বিবেচিত হবে।
            </li>
            <li>
              নির্বাহী পরিষদ সংগঠনের সাথে সংগতি রেখে গঠনতন্ত্রে বর্ণিত নেই এমন
              যে কোন ধরনের আর্থিক সাহায্য প্রদানের সিদ্ধান্ত গ্রহণ করতে পারবে।
            </li>
            <li>
              নির্বাহী পরিষদ সংগঠনের বার্ষিক আয়-ব্যয়ের হিসাব পরিক্ষা-নিরিক্ষা ও
              অনুমোদন করবে।
            </li>
            <li>
              সংগঠনের উন্নয়ন ও আয় বৃদ্ধির জন্য কার্যকরী পদক্ষেপ গ্রহন করবে।
            </li>
            <li>
              বিভিন্ন উন্নয়ন মুলক কাজ করার জন্য সকল সদস্যকে নির্দেশ প্রদান করবে।
            </li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            কার্যনির্বাহী পরিষদের গঠন কাঠামোঃ
          </h4>
          <p className="text-lg text-white mb-4">
            কমিটির মেয়াদ: ২ বছর এই সময়ের মধ্যে কমিটির মেয়াদ বহাল থাকবে। মেয়াদ
            উর্ত্তীণ হলে কমিটি বিলুপ্ত হবে।
          </p>
          <ul className="list-decimal list-inside text-lg text-white space-y-2">
            <li>সভাপতি ১ জন</li>
            <li>সহ-সভাপতি ২জন</li>
            <li>সাধারণ সম্পাদক ১ জন</li>
            <li>সহ-সাধারণ সম্পাদক ১ জন</li>
            <li>সাংগঠনিক সম্পাদক ১ জন</li>
            <li>সহঃ সাংগঠনিক সম্পাদক ১ জন</li>
            <li>প্রচার ও প্রকাশনা বিষয়ক সম্পাদক ১ জন</li>
            <li>সহঃ প্রচার ও প্রকাশনা বিষয়ক সম্পাদক ১ জন</li>
            <li>অর্থ বিষয়ক সম্পাদক ১ জন</li>
            <li>সহ-অর্থ বিষয়ক সম্পাদক ১জন</li>
            <li>দপ্তর বিষয়ক সম্পাদক ১ জন</li>
            <li>ত্রান ও দূযোর্গ বিষয়ক সম্পাদক ১ জন</li>
            <li>শিক্ষা ও আইসিটি বিষয়ক সম্পাদক ১ জন</li>
            <li>সহঃ শিক্ষা ও আইসিটি বিষয়ক সম্পাদক ১ জন</li>
            <li>সংস্কৃতি বিষয়ক সম্পাদক ১ জন</li>
            <li>ক্রীড়া বিষয়ক সম্পাদক ১ জন</li>
            <li>সহঃ ক্রীড়া বিষয়ক সম্পাদক ১ জন</li>
            <li>ধর্ম বিষয়ক সম্পাদক ১ জন</li>
            <li>সাহিত্য ও সাংস্কৃতিক বিষয়ক সম্পাদক ১ জন</li>
            <li>আইন বিষয়ক সম্পাদক ১ জন</li>
            <li>নারী ও শিশু বিষয়ক সম্পাদক ১ জন</li>
            <li>বন ও পরিবেশ বিষয়ক সম্পাদক ১ জন</li>
            <li>নির্বাহী সদস্য</li>
          </ul>
        </div>

        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-১৩: কার্যনির্বাহী কমিটির দায়িত্ব, কার্যাবলী এবং যোগ্যতা
          </h3>

          {/* President */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১) সভাপতিঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সংগঠনের প্রধান হিসেবে বিবেচিত হবেন।</li>
            <li>সংগঠনের সকল সভায় সভাপতিত্ব করবেন।</li>
            <li>সংগঠনের নির্বাহী পরিচালক হিসেবে দায়িত্বে থাকবেন।</li>
            <li>সভাপতির স্বাক্ষর ছাড়া কোন প্রস্তাবই অনুমোদিত হবে না।</li>
            <li>সভাপতি সভা পরিচালনার পূর্ণ দায়িত্বে থাকবেন।</li>
            <li>
              সংগঠনের স্বার্থে ও কল্যাণে যে কোন প্রকার দায়িত্ব পালন করবেন।
            </li>
            <li>
              কোন সভায় যে কোন সিদ্ধান্তের ব্যাপারে সম-সংখ্যক ভোট পরলে সভাপতি
              একটি কাষ্টিং ভোট প্রদান করবেন।
            </li>
            <li>বিশেষ প্রয়োজনে জরুরী সভা আহ্বান করবেন।</li>
            <li>
              নির্বাহী পরিষদের সদস্যদের মতামতের ভিত্তিতে সংগঠনের করণীয় ও
              কার্যাবলী নির্ধারণ করবেন।
            </li>
            <li>
              সকল প্রকার যোগাযোগ, চিঠি লেখা ও চিঠিপত্র ইস্যুর ক্ষেত্রে তিনি
              স্বাক্ষর প্রদান করবেন।
            </li>
          </ul>

          {/* Vice President */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (২) সহ-সভাপতিঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সভাপতিকে সহযোগিতা করা।</li>
            <li>সভাপতির অনুপস্থিতে তার দায়িত্ব পালন করা।</li>
          </ul>

          {/* General Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৩) সাধারণ সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              অফিস নির্বাহী হবেন ও থাকবেন। নির্বাহী পরিষদের নিকট সংগঠনের কার্য
              সুষ্ঠুভাবে পরিচালনার জন্য দায়ী থাকবেন।
            </li>
            <li>
              সকল প্রকার যোগাযোগ, চিঠি লেখা ও চিঠিপত্র ইস্যুর ক্ষেত্রে তিনি
              স্বাক্ষর প্রদান করবেন।
            </li>
            <li>
              সংগঠনের কার্যক্রম, কর্মসূচি ও প্রকল্প প্রস্তাবনা ও বাস্তবায়ন এবং
              নির্বাহী পরিষদের সদস্যদের সাথে সমন্বয় সাধন করবেন।
            </li>
            <li>
              সংগঠনের সকল প্রকার চিঠিপত্র, কাগজপত্র, তথ্য ও দলিল রক্ষণাবেক্ষণ
              করবেন।
            </li>
            <li>
              প্রশাসন, প্রকল্প তৈরি, বাজেট তৈরির কার্যক্রম বাস্তবায়ন ও মূল্যায়নে
              সভাপতি কে সহযোগীতা করবেন।
            </li>
            <li>
              সুষ্ঠু প্রশাসন ব্যবস্থার স্বার্থে সংগঠনের কার্যক্রম পরিচালনার জন্য
              কর্মচারী নিয়োগ, কর্মচুক্তি ও ছাটাইয়ের চূড়ান্ত ক্ষমতার অধিকারী
              হবেন। তবে কার্যনির্বাহী পরিষদের সিদ্ধান্তক্রমে ব্যবস্থা গ্রহণ
              করবেন।
            </li>
            <li>
              সংগঠনের সার্বিক সকল নির্বাহী ও সাধারণ সদস্যদের সাথে যোগাযোগ
              আলাপ-আলোচনা এবং পরামর্শ বজায় রাখবেন। সংগঠনের বার্ষিক রিপোর্ট ও
              বাজেট পেশ করবেন।
            </li>
            <li>
              সভাপতির সাথে আলোচনা করে সভা আহ্বানের দিন, তারিখ, সময় ও স্থান
              নির্ধারণসহ আলোচ্য বিষয়-সূচী উল্লেখ করে বিজ্ঞপ্তি বিতরণের ব্যবস্থা
              করবেন।
            </li>
            <li>
              অর্থ সম্পাদক কর্তৃক মাসিক, ত্রৈমাসিক, বার্ষিক জমা খরচের হিসাব
              প্রস্তুত করিয়ে নিবেন এবং যথাযথ সভায় অনুমোদন ও পেশ করার ব্যবস্থা
              নিবেন।
            </li>
            <li>নির্বাহী পরিষদ কর্তৃক অর্পিত যে কোন দায়িত্ব পালন করবেন।</li>
          </ul>

          {/* Assistant General Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৪) সহ-সাধারণ সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সাধারণ সম্পাদকের সকল কাজে সহায়তা প্রদান করবেন।</li>
            <li>সাধারণ সম্পাদকের অনুপস্থিতিতে তার সকল দায়িত্ব পালন করবেন।</li>
            <li>নির্বাহী পরিষদ প্রদত্ত অন্যান্য দায়িত্ব পালন করবেন।</li>
          </ul>

          {/* Organizational Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৫) সাংগঠনিক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              সংগঠনের কার্যক্রমকে গতিশীল রাখার জন্য প্রয়োজনীয় পদক্ষেপ গ্রহণ
              করবেন।
            </li>
            <li>
              সংগঠনের কার্যক্রমে স্থীরতা প্রকাশ পেলে এর কারণ নির্ণয় করে তা
              দূরীকরণের জন্য সভাপতি/ সাধারণ সম্পাদকের সাথে আলোচনাপূর্বক করণীয়
              নির্ধারণ করবেন।
            </li>
            <li>
              সংগঠনের কোন সদস্যের অনুপস্থিতি বা সংগঠনের স্বার্থ বিরোধী কোন কাজ
              নির্ণয় এবং সমস্যা সমূহ দেখে সংগঠনের স্বার্থে সবাইকে তা অবহিত
              করবেন।
            </li>
            <li>
              সাংগঠনিক সম্পাদক সংগঠনকে শক্তিশালী করার জন্য সর্বদা নিয়োজিত
              থাকবেন।
            </li>
            <li>সংগঠন কোন হুমকির শিকার হলে সেটি সভাপতিকে অবগত করবেন।</li>
            <li>
              সংগঠনের শৃঙ্খলা এবং ব্যাপ্তি ঘটানোর জন্য নিবেদিত প্রাণ হিসেবে কাজ
              করাই তার প্রধান কাজ।
            </li>
          </ul>

          {/* Assistant Organizational Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৬) সহ-সাংগঠনিক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              সাংগঠনিক সম্পাদকের কাজে সহযোগিতা করাই সহ-সাংগঠনিক সম্পাদকের কাজ।
            </li>
            <li>
              সাংগঠনিক সম্পাদকের অনুপস্থিতিতে তিনি সাংগঠনিক সম্পাদক হিসেবে
              দায়িত্ব পালন করবেন।
            </li>
          </ul>

          {/* Publicity and Publication Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৭) প্রচার সম্পাদক ও প্রকাশনা বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              সংগঠনের বিকাশ সাধনের জন্য সংগঠন হতে ঘোষিত প্রচারপত্র, পোস্টার এবং
              বক্তব্য অত্র সংগঠনের সদস্যদের মধ্যে পৌছে দেয়া প্রচার সম্পাদকের
              কাজ।
            </li>
            <li>
              সংগঠন হতে সকল প্রকার প্রকাশনার ডিজাইন, তথ্য সংগ্রহ, প্রুফ দেখা
              সম্পন্ন করে থাকবেন।
            </li>
            <li>
              সংগঠনের বাহ্যিক প্রচারে বেশি গুরুত্ব দিয়ে বিভিন্ন প্রস্তাব
              নির্বাহী সভায় উপস্থাপন করবেন।
            </li>
            <li>
              প্রয়োজন অনুযায়ী সংবাদ সম্মেলন ও গোলটেবিল আলোচনার ব্যবস্থা করবেন।
            </li>
            <li>
              সংগঠনের বিভিন্ন অনুষ্ঠান বা কার্যক্রমের সময় প্রচারের ব্যবস্থা করা
              এবং তা যথাযথ ভাবে হচ্ছে কিনা তা খতিয়ে দেখবেন।
            </li>
            <li>
              বিভিন্ন সামাজিক গণমাধ্যমে সংগঠনের প্রচারনার দায়িত্বও তার অধীনে।
            </li>
            <li>
              সংগঠনের বিভিন্ন খবর পত্রিকায় প্রকাশের ব্যবস্থা করা তার দায়িত্ব।
            </li>
          </ul>

          {/* Assistant Publicity and Publication Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৮) সহ-প্রচার সম্পাদক ও প্রকাশনা বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              প্রচার সম্পাদকের কাজে সহযোগিতা করাই সহ-প্রচার সম্পাদকের কাজ।
            </li>
            <li>
              প্রচার সম্পাদকের অনুপস্থিতিতে তিনি প্রচার সম্পাদক হিসেবে দায়িত্ব
              পালন করবেন।
            </li>
          </ul>

          {/* Treasurer */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৯) অর্থ সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              সংগঠনের যাবতীয় আয়-ব্যয়ের হিসাব রক্ষন, হিসাব সংরক্ষণ এবং তদারকি
              করবেন।
            </li>
            <li>
              প্রতি মাসে আয়-ব্যয়ের হিসাব সাধারণ সম্পাদক এর মাধ্যমে কার্য
              নির্বাহী কমিটির নিকট উপস্থাপন করবেন।
            </li>
            <li>তিনিই সমুদয় অর্থের রক্ষক থাকবেন।</li>
            <li>সংগঠনের সকল অর্থের উৎসের হিসাব সংরক্ষণ করবেন।</li>
            <li>সমস্ত অর্থ ব্যয়ের হিসাব সঠিক ভাবে রক্ষনাবেক্ষণ করবেন।</li>
            <li>
              অফিস সহকারীর মাধ্যমে সংগঠনের যাবতীয় আদান-প্রদানের হিসাব সংরক্ষণ
              করবেন।
            </li>
          </ul>

          {/* Assistant Treasurer */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১০) সহ-অর্থ সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>অর্থ সম্পাদকের কাজে সহযোগিতা করাই সহ-অর্থ সম্পাদকের কাজ।</li>
            <li>
              অর্থ সম্পাদকের অনুপস্থিতিতে তিনি অর্থ সম্পাদক হিসেবে দায়িত্ব পালন
              করবেন।
            </li>
          </ul>

          {/* Assistant Treasurer */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১১) সহ-অর্থ সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>অর্থ সম্পাদকের কাজে সহযোগিতা করা।</li>
            <li>অর্থ সম্পাদকের অনুপস্থিতে তার দায়িত্ব পালন করবে।</li>
          </ul>

          {/* Office Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১২) দপ্তর সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              সংগঠনের সমস্ত তথ্য, রিপোর্ট,চিঠিপত্র,দপ্তর ও সংস্থাপন সংক্রান্ত
              যাবতীয় বিষয় সংরক্ষণ করবেন।
            </li>
            <li>
              সকল সভা কার্য দিবসের নোটিশ সভাপতি/সাধারণ সম্পাদকের অনুমতি সাপেক্ষে
              সকল সদস্যকে অবহিত করবেন।
            </li>
            <li>
              সংগঠনের বিভিন্ন সভা/অনুষ্ঠানে আগত ব্যক্তি/অতিথীদের বক্তব্য/মতামত
              লিপিবদ্ধ করে প্রেস রিলিজ আকারে তা জনসম্মুখে প্রকাশ করবেন।
            </li>
            <li>
              সংগঠনের সকল প্রকার প্রয়োজনীয় কাগজপত্রাদি নিজ দায়িত্বে সংরক্ষণ
              করবেন।
            </li>
          </ul>

          {/* Education and ICT Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১৩) শিক্ষা ও আইসিটি বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>শিক্ষাসংক্রান্ত যেকোন কাজে পরিচালনার দায়িত্ব নিবেন।</li>
            <li>শিক্ষার প্রসারে ভূমিকা পালন করবেন।</li>
          </ul>

          {/* Assistant Education Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১৪) সহ-শিক্ষা বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              শিক্ষা বিষয়ক সম্পাদকের কাজে সহযোগিতা করাই সহ-শিক্ষা বিষয়ক
              সম্পাদকের কাজ।
            </li>
            <li>
              শিক্ষা বিষয়ক সম্পাদকের অনুপস্থিতিতে তিনি শিক্ষা বিষয়ক সম্পাদক
              হিসেবে দায়িত্ব পালন করবেন।
            </li>
          </ul>

          {/* Sports Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১৫) ক্রীড়া বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সংগঠনের ক্রীড়া কার্যক্রম পরিচালনা করবেন।</li>
            <li>ক্রীড়ার উন্নয়নে যেকোনো পরামর্শ সভায় পেশ করবেন।</li>
          </ul>

          {/* Assistant Sports Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১৬) সহ-ক্রীড়া বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ক্রীড়া বিষয়ক সম্পাদকের কাজে সহযোগিতা করাই সহ-ক্রীড়া বিষয়ক
              সম্পাদকের কাজ।
            </li>
            <li>
              ক্রীড়া বিষয়ক সম্পাদকের অনুপস্থিতিতে তিনি ক্রীড়া বিষয়ক সম্পাদক
              হিসেবে দায়িত্ব পালন করবেন।
            </li>
          </ul>

          {/* Religious Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১৭) ধর্ম বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>আলোকবর্তিকার ধর্মীয় কার্যক্রম পরিচালনা করবেন।</li>
            <li>ধর্মীয় সংহতি বজায় রাখতে যেকোনো পরামর্শ সভায় পেশ করবেন।</li>
          </ul>

          {/* Literature and Cultural Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১৮) সাহিত্য, সংস্কৃতি বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সাংস্কৃতিক অনুষ্ঠান পরিচালনার দায়িত্ব নিবেন।</li>
            <li>বিভিন্ন ঐতিহাসিক দিবস উদযাপনের উদ্যোগ নিবেন।</li>
            <li>বাংলাদেশের সংস্কৃতির সুস্থ বিকাশের কার্যকরী পদক্ষেপ নিবেন।</li>
            <li>
              ম্যাগাজিন, লিফলেট, প্যাড ইত্যাদি প্রকাশ করা এবং গ্রন্থনা করা তার
              প্রধান কাজ।
            </li>
            <li>বাংলা সাহিত্য উন্নয়নে করণীয় বিষয়াবলী সভায় তুলে ধরবেন।</li>
          </ul>

          {/* Legal Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (১৯) আইন বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সংগঠনের আইনবিভাগ পরিচালনা করবেন।</li>
            <li>
              সংগঠনের সদস্যগণ গঠনতন্ত্র মেনে চলছে কিনা সেদিকে লক্ষ্য রাখবেন।
            </li>
            <li>সংগঠন কোন আইনসংক্রান্ত নোটিশ পেলে তা সভাপতিকে অবহিত করবেন।</li>
          </ul>

          {/* Women and Children Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (২০) নারী ও শিশু বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              দুস্থ,নিরক্ষর,অসহায়,নির্যাতিত মহিলাদের সংগঠিত করবেন এবং তাদের
              সম্পর্কে পরিষদকে অবহিত করবেন।
            </li>
            <li>
              কার্যনির্বাহী পরিষদ কর্তৃক মহিলা বিষয়ক গৃহীত যাবতীয় কার্যক্রম
              পরিচালনা করার দায়িত্ব তার উপর ন্যাস্ত থাকবে।
            </li>
          </ul>

          {/* Environment Secretary */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (২১) পরিবেশ বিষয়ক সম্পাদকঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>আলোকবর্তিকার পরিবেশবান্ধব কর্মসূচী পরিচালনা করবেন।</li>
            <li>দেশে সংগঠিত প্রাকৃতিক দুর্যোগ সম্পর্কে পরিষদকে অবগত করবেন।</li>
          </ul>

          {/* Executive Member */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (২২) নির্বাহী সদস্যঃ
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>সাংগঠনিক যেকোনো সিদ্ধান্ত বাস্তবায়নে অগ্রণী ভূমিকা রাখবেন।</li>
            <li>
              কার্যনির্বাহী কমিটির সকল কাজে অংশগ্রহণ ও সহযোগিতা করা সদস্যদের
              প্রধান কাজ।
            </li>
            <li>
              বিভিন্ন অনুষ্ঠানের ক্ষেত্রে সদস্যদের সমন্বয়ে গঠিত কমিটিতে থাকা ও
              কাজ করা।
            </li>
          </ul>

          {/* General Member */}
          <h4 className="text-2xl font-semibold text-white mb-4 mt-6">
            (৩) সাধারণ সদস্যঃ
          </h4>
          <p className="text-lg text-white mb-4">
            সাধারণ সদস্যদের যোগ্যতা ৭ (সাত) ধারা মোতাবেক করা হবে। সংগঠনের সকল
            সদস্যদের নিয়ে বৎসরে কমপক্ষে ২ (দুই) টি সভা অনুষ্ঠিত হবে।
            কার্যনির্বাহী পরিষদের সভাপতি/সাঃসম্পাদক সভার কাজ পরিচালনা করবেন।
          </p>

          {/* Article 14: Organization Fund Management */}
          <h2 className="text-3xl font-semibold text-white0 mb-6 mt-10">
            • অনুচ্ছেদ-১৪: সংগঠনের তহবিল সংক্রান্ত বিষয়াবলী
          </h2>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর সদস্যদের প্রাথমিক সদস্য ফি এবং মাসিক
              চাঁদা সংগঠনের তহবিলের প্রধান উৎস হিসেবে গণ্য হবে। তবে শুভাকাংখীদের
              নিঃস্বার্থ এককালীন দান, অনুদান বা সাহায্যও তহবিলের উৎস হিসেবে
              বিবেচিত হবে।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর তহবিল হতে অন্য যেকোনো শুভ উপায়ে অর্জিত
              অর্থ সংগঠনের তহবিলে যোগ হবে।
            </li>
            <li>
              সংগঠনের অনলাইন পেমেন্ট গেটওয়ে এবং কার্যনির্বাহী কমিটির সিদ্ধান্ত
              ব্যতিত অত্র সংগঠনের নামে কোন চাঁদা, অনুদান বা সাহায্যও গ্রহন করা
              যাবে না।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ এর সদস্যদের কল্যাণ ফি, বিশিষ্ঠ ব্যক্তিবর্গ,
              দাতা সদস্যদের ও সংগঠনের অনুদানই হবে এই সংগঠনের আয়ের আরও একটি উৎস।
            </li>
            <li>
              ‘পূর্ব বড়ুয়া তরুণ সংঘ’ নির্বাহী পরিষদ সদস্যদের মাসিক ফি পরিবর্তনের
              অধিকার সংরক্ষণ করবে।
            </li>
          </ul>

          {/* Article 15: Financial Management */}
          <h2 className="text-3xl font-semibold text-white0 mb-6 mt-10">
            • অনুচ্ছেদ-১৫: আর্থিক ব্যবস্থাপনা
          </h2>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              সংগঠনের আর্থিক লেনদেনের ক্ষেত্রে এলাকাস্থ বা দেশের যে কোন সিডিউল
              ব্যাংকে সংগঠনের নামে একটি সঞ্চয়ী/ চলতি হিসাব খুলতে হবে।
            </li>
            <li>
              উক্ত সঞ্চয়ী/ চলতি হিসাব নম্বর সংগঠনের সভাপতি, সাধারণ সম্পাদক এবং
              অর্থ সম্পাদক এই তিন জনের মধ্যে যে কোন ২ জনের যৌথ স্বাক্ষরে ব্যাংক
              হিসাব পরিচালিত হবে।
            </li>
            <li>
              সংগঠনের সভাপতি ও সাধারণ সম্পাদকের পরামর্শক্রমে অর্থ সম্পাদক চলমান
              খরচ নির্বাহের জন্য ৫,০০০/- (পাঁচ হাজার) টাকা হস্তমজুদ রাখতে
              পারবেন। হস্তমজুদের টাকা খরচের পর তা পরবর্তী কার্যনির্বাহী পরিষদের
              সভায় অনুমোদন গ্রহণ করতে হবে।
            </li>
            <li>
              আর্থিক বছর শেষে তহবিলের অর্থ বা জমাকৃত তহবিলের অর্থ সদস্যদের মধ্যে
              বন্টন করা যাবে না। শুধুমাত্র সংগঠনের আদর্শ ও উদ্দেশ্য অর্জনে এবং
              কর্মসূচী বাস্তবায়নের ক্ষেত্রে কল্যাণমুখী, প্রাকৃতিক দুর্যোগ ও
              অসহায়দের কাজে খরচ করা যাবে।
            </li>
            <li>
              সংগঠনের প্রয়োজনীয় অর্থ খরচের পূর্বে উত্তোলনের জন্য কার্যনির্বাহী
              পরিষদের সভায় অনুমোদন গ্রহণ করতে হবে।
            </li>
            <li>
              সংগঠনের নামে সংগৃহিত অর্থ কোন অবস্থাতে হাতে রাখা যাবে না। সংগৃহিত
              অর্থ প্রাপ্তির পর যথাশীঘ্র সংশ্লিষ্ট ব্যাংকে জমা দিয়ে জমার রশিদ
              সংগ্রহ করতে হবে।
            </li>
            <li>
              সকল ব্যাংক লেনদেনের ক্ষেত্রে ব্যাংকিং নীতিমালা অনুসরণ করতে হবে।
              যথাযোগ্য রশিদ ছাড়া এবং কার্যনির্বাহী কমিটির সিদ্ধান্ত ব্যতীত অত্র
              সংগঠনের নামে কোন চাঁদা গ্রহন করা যাবে না।
            </li>
            <li>
              উপদেষ্টা এবং কার্যনির্বাহী পরিষদের সিদ্ধান্ত মোতাবেক রশিদ বই,
              ক্যাশ বই,মজুদ রেজিস্টার,বিতরণ রেজিস্টার,জমাখরচ রেজিস্টার,বিল
              ভাউচার সহ আর্থিক স্বচ্ছতার জন্য প্রয়োজনীয় যাবতীয় কার্যক্রম গ্রহণ
              করতে হবে।
            </li>
          </ul>

          {/* Article 16: Audit */}
          <h2 className="text-3xl font-semibold text-white0 mb-6 mt-10">
            • অনুচ্ছেদ – ১৬: অডিট
          </h2>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              প্রতি ১ বৎসর পর পর সংগঠনের সকল আয় ও ব্যয় উপদেষ্টা পরিষদের নিকট
              দাখিল করা হবে।
            </li>
            <li>
              উপদেষ্টা পরিষদ সংগঠনের আয় ব্যয় নিরীক্ষার জন্য একটি অভ্যান্তরীন
              নিরীক্ষা কমিটি গঠন করবেন। সংগঠনের উপদেষ্টা পরিষদ সাধারণ সদস্যদের
              মধ্যে থেকে ৩ (তিন) সদস্য বিশিষ্ট নিরীক্ষা কমিটি গঠন করবে। প্রতি
              আর্থিক বছরে অভ্যান্তরীন নিরীক্ষা কমিটি সংগঠনের আয় ব্যয় নিরীক্ষা
              করবে। প্রয়োজনে উপদেষ্টা পরিষদ অভ্যান্তরীন নিরীক্ষা কমিটির সদস্য
              রদবদল করতে পারবে।
            </li>
          </ul>

          {/* Article 17: Miscellaneous */}
          <h2 className="text-3xl font-semibold text-white0 mb-6 mt-10">
            • অনুচ্ছেদ-১৭: বিবিধ
          </h2>
          <p className="text-lg text-white mb-4">
            নির্বাহী পরিষদের বিবেচনায় সংগঠনের কর্মকান্ড পরিচালনায় সরল বিশ্বাসে
            কৃত কোন কাজের জন্য কোন সদস্য/কর্মকর্তা দেওয়ানী/ফৌজদারী মোকদ্দমার
            সম্মুখীন হলে সংগঠন তাকে আর্থিক সহায়তা সহ প্রয়োজনীয় সকল প্রকার সহায়তা
            প্রদান করবে।
          </p>
        </div>
      </section>
      <section className="mb-12  bg-secondary text-white p-10 rounded">
        <h2 className="text-4xl font-bold text-white mb-6">
          ৪র্থ ভাগঃ সভা ও নির্বাচন
        </h2>

        {/* Subsection 18 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-১৮: বিভিন্ন প্রকার সভা ও সভার নিয়মাবলী
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>ক) সাধারণ সভা।</li>
            <li>খ) কার্যনির্বাহী পরিষদের সভা।</li>
            <li>গ) জরুরী সভা।</li>
            <li>ঘ) বিশেষ সাধারণ সভা।</li>
            <li>ঙ) মূলতবী সভা।</li>
            <li>চ) তলবী সভা।</li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4">
            সাধারণ সভা:
          </h4>
          <p className="text-lg text-white mb-4">
            কমপক্ষে বছরে একবার সাধারণ সভা অনুষ্ঠিত হবে এবং উহা বার্ষিক সাধারণ
            সভা রূপে গন্য হবে। তবে বিশেষ জরুরী প্রয়োজনে বিশেষ সাধারণ সভাও আহবান
            করা যাবে। সাধারণ সভায় নিম্নলিখিত বিষয়গুলি অনুমোদন লাভ করবে। সাধারণ
            সভা ১৫ (পনের) দিনের নোটিশে সময়, তারিখ ও স্থান উল্লেখ করে আহবান করা
            হবে।
          </p>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>১। প্রতিষ্ঠানের বার্ষিক প্রতিবেদন।</li>
            <li>২। বার্ষিক বাজেট ও হিসাব।</li>
            <li>
              ৩। বার্ষিক সাধারন সভায় সংগঠনের আয় ব্যয়ের অভ্যন্তরীণ অডিটের জন্য
              অডিটর মনোনয়ন করা।
            </li>
            <li>
              ৪। সংগঠনের গঠনতন্ত্রের ধারা, উপ-ধারা পরিবর্তন, পরিবর্ধন বা সংযোজন।
            </li>
            <li>
              ৫। সভার সিদ্ধান্ত মোট সদস্যের নূন্যতম ১/৩ অংশের উপস্থিতিতে কোরাম
              পূর্ন হবে। কোরাম পূর্ন সভায় সংখ্যাগরিষ্ঠ সদস্যের প্রস্তাব সভার
              সিদ্ধান্ত হিসেবে গৃহীত হবে।
            </li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4">
            কার্যর্নির্বাহী পরিষদের সভা:
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ১। বৎসরে কমপক্ষে কার্য্যনির্বাহী পরিষদের ১২টি সভা অনুষ্ঠিত হবে।
            </li>
            <li>
              ২। নূন্যতম ৩ দিন পূর্বে সময়, তারিখ ও স্থান উল্লেখপূর্বক সভার নোটিশ
              জারী করিতে হবে। নূন্যতম ১/২ অংশ কার্যনির্বাহী পরিষদের সদস্যদের
              উপস্থিতিতে কোরাম পূর্ন হবে। কোরাম পূর্ন সভার সংখ্যাগরিষ্ঠ সদস্যের
              প্রস্তাব সভার সিদ্ধান্ত হিসেবে গৃহীত হবে।
            </li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4">জরুরী সভা:</h4>
          <p className="text-lg text-white mb-4">
            জরুরী সভা ৩ (তিন) দিনের নোটিশে সময়, তারিখ ও স্থান উল্লেখ করে আহবান
            করা যাবে। মোট সদস্যদের নূন্যতম (দুই তৃতীয়াংশে) এর উপস্থিতিতে কোরাম
            পূর্ন হবে। কোরাম পূর্ন সংখ্যাগরিষ্ঠ সদস্যের প্রস্তাব সভার সিদ্ধান্ত
            হিসেবে গৃহীত হবে।
          </p>

          <h4 className="text-2xl font-semibold text-white mb-4">
            বিশেষ সাধারণ সভা:
          </h4>
          <p className="text-lg text-white mb-4">
            যে কোন বিশেষ কারণে সাধারণ সভা ১৫ (পনের) দিনের মধ্যে নোটিশে আহবান করা
            যাবে। তবে এ সভায় বিশেষ এজেন্ডা ছাড়া অন্য কোন বিষয়ে আলোচনা ও
            সিদ্ধান্ত নেওয়া যাবে না। বিশেষ এজেন্ডার উদ্দেশ্য লিপিবদ্ধ করে
            যথারীতি নোটিশ প্রদান করতে হবে। মোট সদস্যের নূন্যতম (দুই তৃতীয়াংশ) এর
            উপস্থিতে কোরাম পূর্ন হবে। কোরাম পূর্ন সংখ্যাগরিষ্ঠ সদস্যের প্রস্তাব
            সভার সিদ্ধান্ত হিসেবে গৃহীত হবে।
          </p>

          <h4 className="text-2xl font-semibold text-white mb-4">
            মূলতবী সভা:
          </h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ১। কোরামের অভাবে মূলতবী সাধারণ সভা মূলতবীর তারিখ থেকে পরবর্তী ২১
              (একুশ) দিনের মধ্যে সম্পন্ন করতে হবে। মূলতবী সভার তারিখ হতে ৭ (সাত)
              দিনের মধ্যে নোটিশ জারী করতে হবে। অনুষ্ঠিত সভার গৃহীত সিদ্ধান্ত মোট
              সাধারণ পরিষদ সদস্যদের নূন্যতম (দুই তৃতীয়াংশ) এর সিদ্ধান্তক্রমে
              চূড়ান্ত বলে গন্য হবে।
            </li>
            <li>
              ২। কার্যনির্বাহী পরিষদের সভা ৩ (তিন) দিনের নোটিশে কোরামের অভাবে
              মূলতবী হলে দ্বিতীয়বার ৩ (তিন) দিনের নোটিশে অনুষ্টিত সভার কোরাম
              পূর্ণ না হলেও যত জন সদস্য উপস্থিত থাকবেন তাদের নিয়েই মূলতবী সভা
              অনুষ্ঠিত হবে এবং সভার সিদ্ধান্ত চূড়ান্ত বলে গন্য হবে।
            </li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4">তলবী সভা:</h4>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ১। গঠনতন্ত্রের বিধান অনুযায়ী সভাপতি/ সাধারণ সম্পাদক সংগঠনের সভা
              আহবান না করলে কমপক্ষে মোট সদস্যদের নূন্যতম (দুই তৃতীয়াংশ) সদস্য-
              একজন আহবায়ক মনোনীত করে বিশেষ সাধারণ সভার কর্মসূচীর এজেন্ডা বা
              উদ্দেশ্য ব্যক্ত করে স্বাক্ষর দান করতঃ তলবী সভার আবেদন সংগঠনের
              সভাপতি/ সাধারণ সম্পাদকের কাছে জমা দিতে পারবেন।
            </li>
            <li>
              ২। সভাপতি/ সাধারণ সম্পাদক কর্তৃক তলবী সভার আবেদন প্রাপ্তির ২১
              (একুশ) দিনের মধ্যে তলবী সভার আহবান করবেন। তলবী সভার আবেদন
              প্রাপ্তির ২১ (একুশ) দিনের মধ্যে সভাপতি/ সাধারণ সম্পাদক কর্তৃক তলবী
              সভা আহবান না করলে ২১ (একুশ) দিনের মেয়াদ উর্ত্তীনের তারিখ হতে
              পরবর্তী ৩০ (ত্রিশ) দিনের মধ্যে ১৫ (পনের) দিনের নোটিশে সাধারন
              সদস্যগন আহবায়কের নের্তৃত্বে তলবী সভা আহবান করতে পারবেন। মোট
              সদস্যের (দুই তৃতীয়াংশ) এর উপস্থিতিতে কোরাম পূর্ন হবে। কোরাম পূর্ন
              সভার সংখ্যাগরিষ্ঠ সদস্যের প্রস্তাব সভার সিদ্ধান্ত হিসেবে গৃহীত
              হবে। তলবী সভা সংগঠনের কার্যালয়ে আহবান করতে হবে।
            </li>
          </ul>

          <h4 className="text-2xl font-semibold text-white mb-4">
            অন্যান্য অনুষ্ঠানাদিঃ
          </h4>
          <p className="text-lg text-white">
            সম্ভব হলে বছরে একটি বনভোজন, সম্মেলন, মিলনমেলা, নবীন বরণ, ইফতার
            পার্টি ইত্যাদি অনুষ্ঠান আয়োজন করা হবে। তবে পারস্পরিক পরিচিতি,
            সৌহার্দবোধ, বন্ধন এবং ভ্রাতৃত্ববোধ সৃষ্টিই অনুষ্ঠানাদির মূল লক্ষ্য
            বলে বিবেচিত হবে।
          </p>
        </div>

        {/* Subsection 19 */}
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-১৯: নির্বাচন পদ্ধতি
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ক) কার্যনির্বাহী পরিষদ: সাধারণ সদস্যবৃন্দের প্রস্তাবনা, সমর্থন ও
              গোপন ব্যালটের মাধ্যমে/অনলাইনে কার্যনির্বাহী পরিষদ নির্বাচিত হবে।
              সদস্যবৃন্দের প্রত্যক্ষ ভোটের মাধ্যমে সভাপতি ও সাধারণ সম্পাদক
              নির্বাচিত হবে। অতঃপর সভাপতি ও সাধারণ সম্পাদক উপদেষ্টা পরিষদের
              পরামর্শক্রমে পূর্নাঙ্গ কমিটি ঘোষণা করবেন।
            </li>
            <li>
              খ) মেয়াদ: নির্বাচিত বা মনোনীত হওয়ার দিন হতে পরবর্তী দুই বছর মেয়াদ
              পর্যন্ত কার্যনির্বাহী পরিষদের মেয়াদকাল বলবৎ থাকবে।
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-২০: নির্বাচন কমিশন
          </h3>
          <p className="text-lg text-white">
            সংগঠনের নির্বাচনে অংশ গ্রহণ করবেন না বা সংগঠনের সদস্য নন এমন ৩ (তিন)
            জন বিশিষ্ট ব্যক্তির সমন্বয়ে ১ জন প্রধান নির্বাচন কমিশনার ও ২ জনকে
            সদস্য করে উপদেষ্টা পরিষদ কর্তৃক ৩ সদস্য বিশিষ্ট নির্বাচন কমিশন গঠিত
            হবে। নির্বাচনের পর নির্বাচন কমিশন বিলুপ্ত হবে।
          </p>
        </div>
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-২১: ভোটের প্রনালী:
          </h3>
          <p className="text-lg text-white">
            এক ব্যক্তি একটি পদে একটি করে ভোট প্রদান করবেন এবং কোন প্রতিনিধির
            মাধ্যমে ভোট দেওয়া যাবে না। নির্বাচনের ৩০ (ত্রিশ) দিন পূর্বে
            নির্বাচনী তফসিল ঘোষণা করবেন। নির্বাচন বিষয়ে কমিশন কর্তৃক গৃহীত
            সিদ্ধান্তই চূড়ান্ত বলে বিবেচিত হবে।
          </p>
        </div>
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-২২: বিবিধ
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ক) যেকোনো সদস্য সভাপতি ও সাধারণ সম্পাদক পদের জন্য প্রার্থী হতে
              পারবে।
            </li>
            <li>
              খ) একজন সদস্য একই সাথে সভাপতি ও সাধারণ সম্পাদক পদের জন্য মনোনয়ন
              পেতে পারবে। তবে যদি দুটি পদেই একই ব্যাক্তি জয়লাভ করে তবে, উক্ত
              ব্যক্তির পছন্দের পদটি তাকে দেয়া হবে। এবং অন্য পদের জন্য দ্বিতীয়
              ব্যক্তি স্বয়ংক্রিয়ভাবে নির্বাচিত হয়ে যাবে।
            </li>
            <li>
              গ) কোনো পদের বিপরীতে একের অধিক প্রার্থী না পাওয়া গেলে তিনিই বিনা
              ভোটে নির্বাচিত হবে, কিন্তু কার্যনির্বাহী পরিষদ চাইলে যে কোনো
              সদস্যকে প্রার্থী হিসেবে মনোনয়ন দিবে। এবং ঐ সদস্য তা মেনে নিতে
              বাধ্য থাকবে।
            </li>
            <li>
              ঘ) কার্যনির্বাহী পরিষদের মেয়াদ হবে ২(দুই) বছর।তবে অনিবার্য কারণে
              নির্বাচন আয়োজন করা সম্ভব না হলে উপদেষ্টা পরিষদ কার্যনির্বাহী
              পরিষদের মেয়াদ বৃদ্ধি করতে পারবেন অথবা অন্য সদস্যদের সমন্বয়ে নতুন
              অন্তর্বর্তীকালীন কমিটি গঠন করতে পারবেন তবে উক্ত কমিটির মেয়াদ ৬(ছয়)
              মাসের বেশি হবেনা। যদি এরপরও নির্বাচন আয়োজন সম্ভব না হয় তবে
              উপদেষ্টা পরিষদ কার্যকরী যেকোনো সিদ্ধান্ত গ্রহণ করতে পারবেন।
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-২৩: গঠনতন্ত্র সংশোধন,পরিমার্জন এবং সংযোজন
          </h3>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              ক) গঠনতন্ত্রে বর্ণিত কোনো অনুচ্ছেদ/ধারা বা উপ অনুচ্ছেদ/উপ বিধি
              সংশোধন, সংক্ষেপণ অথবা পরিমার্জনের জন্য উপদেষ্টা পরিষদ কারণ দর্শানো
              সাপেক্ষে সদস্যদের হ্যাঁ /না ভোট গ্রহণ করবেন। সাধারণ সভায় মোট
              সদস্যের নূন্যতম (দুই তৃতীয়াংশ) সদস্যের সংখ্যাগরিষ্ঠ ভোটে/ সমর্থনের
              মাধ্যমে তা গৃহীত হবে।
            </li>
            <li>
              খ) বিশেষ পরিস্থিতিতে উপদেষ্টা পরিষদ গঠনতন্ত্রের কোনো অনুচ্ছেদ/ধারা
              অস্থায়ী ভাবে সংশোধন ও সংযোজন করার ক্ষমতা সংরক্ষণ করবেন।
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-২৪ বিধি ও আইনের প্রাধান্য:
          </h3>
          <p className="text-lg text-white">
            অত্র গঠনতন্ত্রের যা-কিছু উল্লেখ থাকুক না কেন উক্ত সংগঠনটি ১৯৬১ সনের
            ৪৬ নং অধ্যাদেশের আওতায় এবং দেশের প্রচলিত আইনানুযায়ী ও সংশ্লিষ্ট
            বিভাগের অনুমোদনক্রমে সকল কার্যক্রম পরিচালিত হবে।
          </p>
        </div>
        <div className="mb-8">
          <h3 className="text-3xl font-semibold text-white mb-4">
            অনুচ্ছেদ-২৭ সংগঠনের বিলুপ্তি:
          </h3>
          <p className="text-lg text-white">
            যদি কোন অনিবার্য কারণে সংগঠনের বিলুপ্তির প্রশ্ন ওঠে তবে সংগঠনের সকল
            দায়দেনা কার্যনির্বাহী পরিষদ কর্তৃক পরিশোধ করে মোট সদস্যের নূন্যতম
            ৩/৫ (তিন পঞ্চামাংশ) সাধারণ সদস্যের সিদ্ধান্তক্রমে সংগঠনের বিলুপ্তি
            করা যাবে
          </p>
        </div>
      </section>
    </div>
  );
};

export default Rules;
