import React, { useEffect, useState } from 'react';
import api from '../../utils/axios.config';
import { baseUrl } from '../../utils/url';
import { BsTrashFill } from 'react-icons/bs';
import { GrEdit } from 'react-icons/gr';

function RecentActivities() {
  const [activities, setActivities] = useState([]);

  const handleGetActivities = async () => {
    const { data } = await api.get('recent-activities/get');

    setActivities(data.data.activities);
  };

  useEffect(() => {
    handleGetActivities();
  }, []);
  return (
    <div>
      <div className="flex justify-around border-b-2">
        <p>Image</p>
        <p>Title</p>
        <p>Action</p>
      </div>
      {activities?.map((activity) => (
        <div
          key={activity._id}
          className="flex justify-around items-center border-b-2"
        >
          <div class="avatar">
            <div class="w-24 rounded-full">
              <img src={`${baseUrl}${activity.imageUrl}`} />
            </div>
          </div>
          <p>{activity.title}</p>
          <div className="flex">
            <BsTrashFill className="mx-2" size={30} color="red" />
            <GrEdit className="mx-2" size={30} color="blue" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default RecentActivities;
