import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";

const RecentActivities = () => {
  const [activities, setActivities] = useState([]);

  const handleGetActivities = async () => {
    const { data } = await api.get("recent-activities/get");

    if (data.success && data.data.activities.length) {
      setActivities(data?.data?.activities);
    }
  };

  useEffect(() => {
    handleGetActivities();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="mx-auto">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        autoPlay={true}
        ssr={true}
        infinite={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {activities?.map((activity) => (
          <div key={activity._id} className="flex justify-center">
            <div class="card bg-base-100 w-full max-w-sm mx-5  my-2 h-[500px] border">
              <figure>
                <img
                  className="h-56 w-full"
                  src={`${baseUrl}${activity?.imageUrl}`}
                  alt="activities"
                />
              </figure>
              <div class="card-body">
                <h2 class="card-title">{activity.title}</h2>
                <p>{activity.shortDescription}</p>
                <button class="btn btn-secondary text-white ">বিস্তারিত</button>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default RecentActivities;
