import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../utils/axios.config';
import { toast } from 'react-hot-toast';
import role from '../../data/role.json';
import Loading from '../Shared/Loading';

function SponsorInfo() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const bloodGroup = ['a+', 'a-', 'b+', 'b-', 'ab+', 'ab-', 'o+', 'o-'];

  const onSubmit = async ({
    confirmPassword,
    designation,
    districtId,
    divisionId,
    dob,
    educationQualification,
    file,
    institute,
    lastBloodDonateDate,
    mobile,
    name,
    nid,
    password,
    unionId,
    upazilaId,
    email,
    isAgreeDonateBlood,
    facebook,
    bloodGroup,
    role,
  }) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('image', file[0]);
    formData.append('name', name);
    formData.append('institute', institute);
    formData.append('designation', designation);

    formData.append('password', password);
    formData.append('confirmPassword', confirmPassword);
    formData.append('mobile', mobile);
    formData.append('email', email);
    formData.append('division', divisionId);
    formData.append('district', districtId);
    formData.append('upazila', upazilaId);
    formData.append('union', unionId);
    formData.append('facebookUrl', facebook);
    formData.append('bloodGroup', bloodGroup);
    formData.append('isAgreeDonateBlood', isAgreeDonateBlood);
    if (lastBloodDonateDate) {
      formData.append('lastBloodDonation', lastBloodDonateDate);
    }
    formData.append('educationQualification', educationQualification);
    formData.append('nid', nid);
    formData.append('dob', dob);
    formData.append('role', role);

    try {
      const { data } = await api.post('user/signup', formData);

      if (data.success) {
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="my-32 md:mx-10 mx-2">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        পূর্ব বড়ুয়া তরুণ সংঘ এ আপনাকে স্বাগতম, স্পন্সর করুন
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
          <div>
            <label class="label">
              <span class="label-text">ছবি নির্বাচন করুন *</span>
            </label>
            <input
              type="file"
              {...register('file', { required: 'ছবি আবশ্যক' })}
              class="file-input file-input-bordered file-input-primary w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.file && errors.file.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">নাম *</span>
            </label>
            <input
              {...register('name', {
                required: 'নাম আবশ্যক',
              })}
              type="text"
              placeholder="নাম লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.name && errors.name.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">মোবাইল *</span>
            </label>
            <input
              {...register('mobile', {
                required: 'মোবাইল আবশ্যক',
              })}
              type="text"
              placeholder="মোবাইল লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.mobile && errors.mobile.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ইমেইল *</span>
            </label>
            <input
              {...register('email', {
                required: 'ইমেইল আবশ্যক',
              })}
              type="text"
              placeholder="ইমেইল লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.email && errors.email.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ঠিকানা *</span>
            </label>
            <input
              {...register('address', {
                required: 'ঠিকানা আবশ্যক',
              })}
              type="text"
              placeholder="ঠিকানা লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.address && errors.address.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">স্পন্সর সম্পর্কে</span>
            </label>
            <textarea
              {...register('description')}
              placeholder="স্পন্সর সম্পর্কে"
              class="textarea textarea-bordered textarea-lg w-full max-w-xs lg:max-w-lg"
            />

            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.description && errors.description.message}
              </span>
            </label>
          </div>
        </div>
        <div className="w-full flex justify-center mt-10">
          <input
            className="btn btn-primary text-white w-44"
            value={'সাবমিট করুন'}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}

export default SponsorInfo;
