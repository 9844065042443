import React, { useEffect, useState } from "react";
import adviser from "../../assets/members/saikat.jpg";
import api from "../../utils/axios.config";
import logo from "../../assets/image/logo.png";

const PresidentMessage = () => {
  const [message, setMessage] = useState([]);

  const handleGetMessage = async () => {
    const { data } = await api.get("president-message");

    setMessage(data.presidentMessage);
  };

  useEffect(() => {
    handleGetMessage();
  }, []);

  return (
    <div className="mx-2 md:mx-5 lg:mx-20 mt-10">
      <div className="w-44">
        <p className="border-b-4 border-b-orange-500 font-bold mb-2 mt-5">
          প্রতিষ্ঠাতা সভাপতির বাণী
        </p>
      </div>
      <div className="md:flex">
        <div className="border rounded-lg md:flex-1">
          <div className="bg-secondary">
            <p className="font-bold text-white p-1 text-center">
              প্রতিষ্ঠাতা সভাপতি
            </p>
          </div>

          <div className=" flex justify-center items-center">
            <div>
              <div className="flex justify-center items-center">
                <img className="h-44 w-36 m-2" src={adviser} alt="" />
              </div>
              <p className="font-bold text-lg text-center">মোঃ সায়হান সৈকত</p>
              <p className="font-bold text-center">প্রতিষ্ঠাতা সভাপতি</p>
              <p className="font-bold text-center">পূর্ব বড়ুয়া তরুণ সংঘ</p>
            </div>
          </div>
        </div>
        <div className="border rounded-lg md:flex-[2]">
          <div className="bg-secondary">
            <p className="font-bold text-white p-1 text-center">
              প্রতিষ্ঠাতা সভাপতির বাণী
            </p>
          </div>
          <div className="flex items-center h-62 p-5 md:p-10">
            <div>
              <img
                width={100}
                height={100}
                className="float-left px-2"
                src={logo}
                alt=""
              />
              <p>{message[message?.length - 1]?.message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresidentMessage;
