import React, { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../utils/axios.config";
import { useEffect } from "react";
import logo from "../../assets/image/logo.png";
import { baseUrl } from "../../utils/url";
import division from "../../data/division.json";
import district from "../../data/district.json";
import upazila from "../../data/upazila.json";
import union from "../../data/union.json";
import QRCode from "react-qr-code";

function PrintApplication() {
  const [applicationInfo, setApplicationInfo] = useState(null);
  const { id } = useParams();

  const handleGetApplicationInfo = async () => {
    const { data } = await api.get(`scholarship-application/${id}`);

    if (data.success) {
      setApplicationInfo(data.data);
    }
  };
  useEffect(() => {
    handleGetApplicationInfo();
  }, [id]);

  const getDivisionName = (id) => {
    const divisionInfo = division.find((div) => div.id === id);
    return divisionInfo.bn_name;
  };

  const getDistrictName = (id) => {
    const districtInfo = district.find((dis) => dis.id === id);
    return districtInfo.bn_name;
  };

  const getUpazilaName = (id) => {
    const upazilaInfo = upazila.find((upa) => upa.id === id);
    return upazilaInfo.bn_name;
  };

  const getUnionName = (id) => {
    const unionInfo = union.find((uni) => uni.id === id);
    return unionInfo.bn_name;
  };

  const {
    id: applicationId,
    name,
    fatherName,
    motherName,
    idNo,
    dateOfBirth,
    mobile,
    bkashNumber,
    email,
    maritalStatus,
    religion,
    guardianIncome,
    guardianProfession,
    familyMember,
    instituteName,
    healthStatus,
    lastExam,
    profileImageUrl,
    signatureImageUrl,
    division: divisionId,
    district: districtId,
    upazila: upazilaId,
    union: unionId,
    village,
  } = applicationInfo || {};

  const { board, educationDivision, year, result } =
    applicationInfo?.[lastExam + "Result"] || {};

  return (
    <div className="mb-10 ">
      {!applicationInfo ? (
        <div className="h-[60vh] w-full flex justify-center items-center">
          <div>
            <h1 className="text-3xl font-bold text-red-500 text-center">
              দুঃখিত! তোমার আবেদন খুঁজে পাওয়া যাই নি
            </h1>
            <h2 className="text-xl font-semibold text-center text-primary">
              তোমার সার্চকৃত আবেদন নং- {id}
            </h2>
            <h3 className="text-lg font-medium text-center text-primary">
              দয়া করে সঠিক আবেদন নম্বর দিয়ে চেষ্টা কর
            </h3>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-[210mm] h-[305mm] m-auto ">
          <div className=" h-[300mm]  bg-contain  w-[205mm] relative  rounded  p-10 border-2 border-green-500">
            <div className="flex bg-secondary mb-5 p-2 items-center rounded">
              <img src={logo} alt="logo" className="w-20 h-20" />
              <h2 className="text-2xl font-bold text-white mx-5">
                পূর্ব বড়ুয়া তরুণ সংঘ শিক্ষা বৃত্তি - ২০২৩
              </h2>
            </div>
            <div className="border-2 p-5 rounded">
              <div className="bg-base-200">
                <h3 className="text-xl text-center mb-5  ">আবেদনপত্র</h3>
              </div>
              <div className="flex w-full justify-end absolute top-[30] right-16">
                <div className="w-[120px]">
                  <img
                    className="h-[150px] w-[120px]"
                    src={`${baseUrl}${profileImageUrl}`}
                    alt="profile"
                  />
                </div>
              </div>
              <div className="text-small">
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">আবেদন আইডি</p>

                  <p>: {applicationId}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">নাম</p>

                  <p>: {name}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">পিতার নাম</p>

                  <p>: {fatherName}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">মাতার নাম</p>

                  <p>: {motherName}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">জাতীয় পরিচয়পত্র/জন্ম সনদ নং</p>

                  <p>: {idNo}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">জন্ম তারিখ</p>

                  <p>: {dateOfBirth}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">মোবাইল নং</p>

                  <p>: {mobile}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">বিকাশ নাম্বার</p>
                  <p>: {bkashNumber}</p>
                </div>
                {email && (
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">ইমেইল</p>

                    <p>: {email}</p>
                  </div>
                )}
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">ধর্ম</p>

                  <p>: {religion}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">বিভাগ</p>

                  <p>: {divisionId && getDivisionName(divisionId)}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">জেলা</p>

                  <p>: {districtId && getDistrictName(districtId)}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">উপজেলা/থানা</p>

                  <p>: {upazilaId && getUpazilaName(upazilaId)}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">ইউনিয়ন</p>

                  <p>: {unionId && getUnionName(unionId)}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">গ্রাম</p>

                  <p>: {village}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">অভিভাবকের পেশা</p>

                  <p>: {guardianProfession}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">অভিভাবকের মাসিক আয়</p>

                  <p>: {guardianIncome}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">পরিবারের সদস্য সংখ্যা</p>

                  <p>: {familyMember}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">শারীরিক অবস্থা</p>

                  <p>: {healthStatus === "good" ? "সুস্থ্য" : "প্রতিবন্ধী"}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">বৈবাহিক অবস্থা</p>

                  <p>: {maritalStatus === "yes" ? "বিবাহিত" : "অবিবাহিত"}</p>
                </div>

                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">প্রতিষ্ঠানের নাম</p>

                  <p>: {instituteName}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">
                    যে পরীক্ষার ফলাফলের জন্য আবেদন
                  </p>

                  <p>: {lastExam === "ssc" ? "এসএসসি" : "এইচএসসি"}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">শিক্ষা বোর্ড</p>

                  <p>: {board}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">বিভাগ</p>

                  <p>: {educationDivision}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">পরীক্ষার সন</p>

                  <p>: {year}</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="w-64 font-bold">রেজাল্ট</p>

                  <p>: {result}</p>
                </div>
              </div>
              <div className="flex w-full justify-end">
                <div>
                  <img
                    className="h-[80px] w-[150px]"
                    src={`${baseUrl}${signatureImageUrl}`}
                    alt="profile"
                  />
                  <p className="text-center">
                    <span>শিক্ষার্থীর স্বাক্ষর</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="border-2 my-2 p-5 rounded-lg flex items-center justify-between max-h-32">
              <div className="text-sm col-span-2">
                <h3 className="font-bold text-lg">নির্দেশনা:</h3>
                <p>১। আবেদনপত্রের প্রিন্ট কপি অবশ্যই সংগ্রহ করতে হবে।</p>
                <p>
                  ২। বৃত্তির ফলাফল এসএমএস এর মাধ্যমে জানানো হবে বিধায় মোবাইলের
                  এসএমএস যাচাই করতে হবে।
                </p>
                <p>
                  ৩। একই শিক্ষার্থীর জন্য একাধিক আবেদন করলে তার আবেদন বাতিল করা
                  হবে।
                </p>
              </div>
              <QRCode
                size={256}
                className="w-16"
                value={`Purbo Borua Torun Songho Education Scholarship.\nApplication ID: ${applicationId}\nMobile: ${mobile}\nBkash number: ${bkashNumber}`}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrintApplication;
