import { useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../utils/axios.config";
import Loading from "../Shared/Loading";

function PicnicApplication() {
  const { isLoading, data } = useQuery({
    queryKey: ["allMember"],
    queryFn: async () => await api.get("picnic"),
  });

  const members = data?.data?.data;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full grid place-content-center">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        সকল আবেদন
      </p>
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
            <tr>
              <th>নাম,পদবী ও ঠিকানা</th>
              <th>টি শার্ট সাইজ</th>
              <th>সদস্য সংখ্যা</th>
              <th>পেমেন্ট</th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member) => (
              <tr key={member._id}>
                <td>
                  <div class="flex items-center space-x-3">
                    <div>
                      <p>{member?.name}</p>
                      <p>{member?.designation}</p>
                      <p>{member?.village}</p>
                    </div>
                  </div>
                </td>
                <td>{member?.tShirtSize}</td>

                <td>{member?.totalMember}</td>
                <td>
                  {member?.paymentMethod}
                  <br />
                  {member?.accountNo}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PicnicApplication;
