import React from "react";
import member1 from "../../assets/members/saikat.jpg";
import { BsFacebook } from "react-icons/bs";

const BloodDonors = () => {
  return (
    <div className="m-2 md:m-5">
      <h3 className="text-center font-bold text-2xl text-red-500">
        ব্লাড দাতাদের তথ্য
      </h3>
      <div className="my-2 flex justify-end">
        <input
          type="text"
          placeholder="মোবাইল নাম্বার লিখুন"
          class="input input-bordered input-md w-full max-w-xs"
        />
        <button className="btn btn-secondary text-white h-[1px] mx-2">
          অনুসন্ধান
        </button>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-compact w-full">
          <thead>
            <tr>
              <th className="bg-secondary text-white">ক্রমিক নং</th>
              <th className="bg-secondary text-white">ব্লাড দাতার নাম</th>
              <th className="bg-secondary text-white">ব্লাডের গ্রুপ</th>
              <th className="bg-secondary text-white">
                সর্বশেষ রক্ত প্রদানের তারিখ
              </th>
              <th className="bg-secondary text-white">
                বর্তমান ব্লাড প্রদানে ইচ্ছুক কিনা
              </th>
              <th className="bg-secondary text-white">মোবাইল</th>
              <th className="bg-secondary text-white">ই-মেইল</th>
              <th className="bg-secondary text-white">ঠিকানা</th>
              <th className="bg-secondary text-white">ছবি</th>
              <th className="bg-secondary text-white">ফেসবুক</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
            <tr>
              <th>1</th>
              <td>মোঃ সায়হান সৈকত</td>
              <td className="text-red-500 font-bold">B+</td>
              <td>01-02-2023</td>
              <td>হ্যাঁ</td>
              <td>01811223344</td>
              <td>saikat.kulaghatudc@gmail.com</td>
              <td>পূর্ব বড়ুয়া, কুলাঘাট</td>
              <td>
                <div class="avatar">
                  <div class="mask mask-squircle w-12 h-12">
                    <img src={member1} alt="Avatar Tailwind CSS Component" />
                  </div>
                </div>
              </td>
              <td>
                <div className="cursor-pointer">
                  <BsFacebook color="blue" size={30} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BloodDonors;
