import React from 'react';
import image from '../../assets/image/404.png';

const NotFound = () => {
    return (
        <div>
            <img className='h-[80vh] w-full' src={image} alt="" />
        </div>
    );
};

export default NotFound;