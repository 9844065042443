import { useQuery } from '@tanstack/react-query';
import { baseUrl } from '../utils/url';

const useAdmin = () => {
  const { token } = JSON.parse(localStorage.getItem('user')) || {};
  const {
    isLoading,
    error,
    data: user,
    refetch,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () =>
      fetch(`${baseUrl}verify-admin`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json()),
  });

  return [user, isLoading, error, refetch];
};

export default useAdmin;
