// components/Notices/ManageNotices.js
import React, { useEffect, useState } from "react";
import api from "../../utils/axios.config";
import { BsTrashFill } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import { toast } from "react-hot-toast";

function ManageNotices() {
  const [notices, setNotices] = useState([]);

  const handleGetNotices = async () => {
    try {
      const { data } = await api.get("/notice");
      setNotices(data.data);
    } catch (error) {
      toast.error(error.response.data.error ?? error.message);
    }
  };

  const handleDeleteNotice = async (id) => {
    try {
      await api.delete(`/notice/${id}`);
      toast.success("Notice deleted successfully");
      setNotices(notices.filter((notice) => notice._id !== id));
    } catch (error) {
      toast.error(error.response.data.error ?? error.message);
    }
  };

  useEffect(() => {
    handleGetNotices();
  }, []);

  return (
    <div>
      <div className="flex justify-around border-b-2">
        <p>Title</p>
        <p>Attachments</p>
        <p>Actions</p>
      </div>
      {notices.map((notice) => (
        <div
          key={notice._id}
          className="flex justify-around items-center border-b-2"
        >
          <div>
            <p>{notice.title}</p>
          </div>
          <div>
            {notice.attachments.map((attachment, index) => (
              <a
                key={index}
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.type === "image" ? (
                  <img
                    src={attachment.url}
                    alt="attachment"
                    className="w-24 h-24"
                  />
                ) : (
                  <span>{`Attachment ${index + 1}`}</span>
                )}
              </a>
            ))}
          </div>
          <div className="flex">
            <BsTrashFill
              className="mx-2 cursor-pointer"
              size={30}
              color="red"
              onClick={() => handleDeleteNotice(notice._id)}
            />
            <GrEdit className="mx-2 cursor-pointer" size={30} color="blue" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ManageNotices;
