import React from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

const AboutDonation = () => {
    const [currency, setCurrency] = useState("");
    const [paymentMethod, setPayloadMethod] = useState("");

    const btnInActiveClass = 'border w-32 border-secondary rounded-lg py-2 text-secondary m-2';
    const btnActiveClass = 'btn btn-secondary w-32 border-0 rounded-lg py-2 text-white m-2';
    return (
        <div className='max-w-2xl mx-auto'>
            <div className="my-10">
                <p className='text-3xl font-bold text-secondary text-center'>দান অনুমোদন ফর্ম</p>
                <p className='text-center ml:0 md:ml-96'>তারিখ: {new Date().toLocaleDateString()}</p>
                <div className='grid grid-cols-1 md:grid-cols-2 place-content-center place-items-center'>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">দানের পরিমান</span>
                        </label>
                        <input type="text" placeholder="দানের পরিমান লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div>
                        <button onClick={() => setCurrency("bdt")} className={currency === "bdt" ? btnActiveClass : btnInActiveClass}>BDT (টাকা)</button>
                        <button onClick={() => setCurrency("usd")} className={currency === "usd" ? btnActiveClass : btnInActiveClass}>USD (ডলার)</button>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">ইমেইল</span>
                        </label>
                        <input type="text" placeholder="ইমেইল লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>

                    <div>
                        <button onClick={() => setPayloadMethod("bkash")} className={paymentMethod === "bkash" ? btnActiveClass : btnInActiveClass}>Bkash (বিকাশ)</button>
                        <button onClick={() => setPayloadMethod("nogod")} className={paymentMethod === "nogod" ? btnActiveClass : btnInActiveClass}>Nogod (নগদ)</button>
                        <button onClick={() => setPayloadMethod("rocket")} className={paymentMethod === "rocket" ? btnActiveClass : btnInActiveClass}>Rocket (রকেট)</button>
                        <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>Bank (ব্যাংক)</button>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">প্রাপক মোবাইল নাম্বার/ব্যাংক একাউন্ট</span>
                        </label>
                        <input type="text" placeholder="প্রাপক মোবাইল নাম্বার/ব্যাংক একাউন্ট লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">প্রেরক মোবাইল নাম্বার/ব্যাংক একাউন্ট</span>
                        </label>
                        <input type="text" placeholder="প্রেরক মোবাইল নাম্বার/ব্যাংক একাউন্ট লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                </div>
                <div className='my-5 grid grid-cols-1 md:grid-cols-4 place-content-center place-items-center'>
                    <button onClick={() => setPayloadMethod("bkash")} className={paymentMethod === "bkash" ? btnActiveClass : btnInActiveClass}>শিক্ষা সহায়তা</button>
                    <button onClick={() => setPayloadMethod("rocket")} className={paymentMethod === "rocket" ? btnActiveClass : btnInActiveClass}>বৃক্ষরোপণ</button>
                    <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>স্বচ্ছলতা প্রকল্প</button>
                    <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>পাঠাগার</button>
                </div>
                <div className='flex justify-center'>
                    <textarea placeholder="আমাদের কে কিছু বলতে চান" class="textarea textarea-bordered textarea-lg w-full max-w-xs lg:max-w-lg" ></textarea>
                </div>
                <div onClick={() => toast.success("Success")} className='flex justify-center my-10'>
                    <button className='btn btn-secondary text-white font-bold w-44'>আমাদের কাছে পাঠিয়ে দিন</button>
                </div>

            </div>
        </div>
    );
};

export default AboutDonation;