import React from 'react';
import { Link } from 'react-router-dom';

const FoodProject = () => {
    return (
        <div className='md:grid md:grid-cols-2 place-content-center place-items-center m-2 md:m-5'>
            <img src="https://files.globalgiving.org/pfil/5067/ph_5067_93073.jpg?m=1479658881000" alt="education" />
            <div>
                <p className='text-xl font-bold text-secondary'><span>৳ ৯০০ -</span> (১ টি পরিবারের জন্য খাদ্য)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৯০০০ -</span> (১০ টি পরিবারের জন্য খাদ্য)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৪৫০০০ -</span> (৫০ টি পরিবারের জন্য খাদ্য)</p>
                <br />
                <br />
                <p className='text-xl font-bold text-secondary'><span>৳ ৪৫০ -</span> (১ টি শিশুর জন্য খাদ্য)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৯০০০ -</span> (২০ টি শিশুর জন্য খাদ্য)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৩৬০০০ -</span> (৮০ টি শিশুর জন্য খাদ্য)</p>

                <br />
                <p className='font-bold text-red-500'>
                    # এছাড়াও আপনি যেকোনো পরিমান অর্থ খাদ্য প্রকল্পের জন্য ডোনেট করতে পারেন।
                </p>
                <br />
                <Link to='/donation' className="btn bg-secondary text-white border-0">Donation</Link>
            </div>
        </div>
    );
};

export default FoodProject;