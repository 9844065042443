import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BsFacebook } from "react-icons/bs";
import { BiLogoWhatsappSquare } from "react-icons/bi";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";

const PublicVercityStudent = () => {
  const [students, setStudents] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  const [allStudent, setAllStudent] = useState([]);

  const handleGetStudent = async () => {
    const { data } = await api.get("university-student");

    if (data.success) {
      setStudents(data.data);
    }
  };

  useEffect(() => {
    handleGetStudent();
  }, []);

  useEffect(() => {
    const totalPage = Math.ceil(students.length / 10);
    if (totalPage > currentPage) {
      setAllStudent(students.slice(currentPage * 10, 10 * (currentPage + 1)));
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, students]);

  const handleIncreasePage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleDecreasePage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="m-2 md:m-5">
      <h3 className="text-center font-bold text-2xl">
        পাবলিক বিশ্ববিদ্যালয়ের শিক্ষার্থী
      </h3>
      <div className="my-2 flex justify-end">
        <input
          type="text"
          placeholder="মোবাইল নাম্বার লিখুন"
          class="input input-bordered input-md w-full max-w-xs"
        />
        <button className="btn bg-secondary text-white border-0 mx-2">
          অনুসন্ধান
        </button>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-compact w-full">
          <thead>
            <tr>
              <th className="bg-secondary text-white">নাম</th>
              <th className="bg-secondary text-white">
                বিশ্ববিদ্যালয়ের নাম ও ঠিকানা
              </th>
              <th className="bg-secondary text-white">যোগাযোগ</th>
              <th className="bg-secondary text-white">ঠিকানা</th>
              <th className="bg-secondary text-white">Social Media</th>
            </tr>
          </thead>
          <tbody>
            {allStudent?.map((member, index) => (
              <tr key={index}>
                <div class="flex items-center space-x-3">
                  <div class="avatar">
                    <div class="mask mask-squircle w-12 h-12">
                      <img
                        src={`${baseUrl}${member.imageUrl}`}
                        alt="Avatar Tailwind CSS Component"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="font-bold">{member?.name}</div>
                    <div class="font-bold">{member?.departmentName}</div>
                  </div>
                </div>

                <td>
                  <div class="font-bold">{member?.university}</div>
                  <div class="text-sm opacity-80">{member?.department}</div>
                </td>
                <td>
                  <div class="font-bold">{member?.mobile}</div>
                  <div class="text-sm opacity-80">{member?.email}</div>
                </td>
                <td>{member?.address}</td>
                <td className="flex items-center">
                  {member?.facebookUrl && (
                    <a
                      href={member?.facebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsFacebook color="blue" size={30} />
                    </a>
                  )}
                  {member?.whatsappUrl && (
                    <a
                      href={member?.whatsappUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BiLogoWhatsappSquare color="green" size={35} />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="px-5">
          <div className="flex justify-end items-center">
            <div className="px-6 py-4">
              {currentPage * 10 + 1}-{10 * (currentPage + 1)} of{" "}
              {students?.length}
            </div>
            <div>
              <div className="flex items-center">
                <button className="pr-10" onClick={handleDecreasePage}>
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <button onClick={handleIncreasePage}>
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicVercityStudent;
