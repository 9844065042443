import { useQuery } from '@tanstack/react-query';
import React from 'react';
import api from '../../utils/axios.config';
import Loading from '../Shared/Loading';
import division from '../../data/division.json';
import district from '../../data/district.json';
import upazila from '../../data/upazila.json';
import union from '../../data/union.json';
import { baseUrl } from '../../utils/url';
import { toast } from 'react-hot-toast';

function AllUser() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['allUser'],
    queryFn: async () => await api.get('user'),
  });

  const users = data?.data?.data?.users;

  const getDivisionName = (id) => {
    const divisionInfo = division.find((div) => div.id === id);
    return divisionInfo.bn_name;
  };

  const getDistrictName = (id) => {
    const districtInfo = district.find((dis) => dis.id === id);
    return districtInfo.bn_name;
  };

  const getUpazilaName = (id) => {
    const upazilaInfo = upazila.find((upa) => upa.id === id);
    return upazilaInfo.bn_name;
  };

  const getUnionName = (id) => {
    const unionInfo = union.find((uni) => uni.id === id);
    return unionInfo.bn_name;
  };

  const changeRole = async (info) => {
    try {
      const { data } = await api.patch('user/status', info);

      if (data.success) {
        toast.success(data.message);
        refetch();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full grid place-content-center">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        সকল ইউজার
      </p>
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
            <tr>
              <th>নাম ও ঠিকানা</th>
              <th>প্রতিষ্ঠান ও পদবী</th>
              <th>NID & জন্ম তারিখ</th>
              <th>যোগাযোগ</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr key={user._id}>
                <td>
                  <div class="flex items-center space-x-3">
                    <div class="avatar">
                      <div class="mask mask-squircle w-12 h-12">
                        <img
                          src={`${baseUrl}${user.imageUrl}`}
                          alt="Avatar Tailwind CSS Component"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="font-bold">{user.name}</div>
                      <div class="text-sm opacity-50">
                        {`${getDivisionName(user?.division)},${getDistrictName(
                          user?.district
                        )},${getUpazilaName(user?.upazila)},${getUnionName(
                          user?.union
                        )}`}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {user.institute}
                  <br />
                  {user.designation}
                </td>
                <td>
                  {user.nid}
                  <br />
                  {user.dob}
                </td>
                <td>
                  {user.mobile}
                  <br />
                  {user?.email}
                </td>
                <td>{user.role}</td>
                <td>
                  {user.status === 'inactive' ? (
                    <button
                      onClick={() =>
                        changeRole({ _id: user?._id, status: 'active' })
                      }
                      class="btn btn-link"
                    >
                      Active Account
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        changeRole({ _id: user?._id, status: 'inactive' })
                      }
                      class="btn btn-link"
                    >
                      Inactive Account
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AllUser;
