import React from "react";
import member1 from "../../assets/members/saikat.jpg";
import { BsFacebook } from "react-icons/bs";
import member01 from "../../assets/members/member/01.jpg";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const TrainingCenter = () => {
  const centers = [
    {
      institute: "কুলাঘাট ইউনিয়ন ডিজিটাল সেন্টার",
      address: "কুলাঘাট ইউনিয়ন পরিষদ",
      mobile: "01777777777",
      email: "kulaghatup@gmail.com",
      director: "Md Sayhan Saikat",
      designation: "Entrepreneur",
      course: "MS Office, Freelancing, Graphics Design",
    },
    {
      institute: "কুলাঘাট ইউনিয়ন ডিজিটাল সেন্টার",
      address: "কুলাঘাট ইউনিয়ন পরিষদ",
      mobile: "01777777777",
      email: "kulaghatup@gmail.com",
      director: "Md Sayhan Saikat",
      designation: "Entrepreneur",
      course: "MS Office, Freelancing, Graphics Design",
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);

  const [allMember, setAllMember] = useState([]);

  useEffect(() => {
    const totalPage = Math.ceil(centers.length / 10);
    if (totalPage > currentPage) {
      setAllMember(centers.slice(currentPage * 10, 10 * (currentPage + 1)));
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, centers]);

  const handleIncreasePage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleDecreasePage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="m-2 md:m-5">
      <h3 className="text-center font-bold text-2xl">ট্রেনিং সেন্টার</h3>
      <div className="my-2 flex justify-end">
        <input
          type="text"
          placeholder="মোবাইল নাম্বার লিখুন"
          class="input input-bordered input-md w-full max-w-xs"
        />
        <button className="btn bg-secondary text-white border-0 mx-2">
          অনুসন্ধান
        </button>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-compact w-full">
          <thead>
            <tr>
              <th className="bg-secondary text-white">প্রতিষ্ঠান ও ঠিকানা</th>
              <th className="bg-secondary text-white">পরিচালকের নাম ও পদবী</th>
              <th className="bg-secondary text-white">মোবাইল</th>
              <th className="bg-secondary text-white">ইমেইল</th>
              <th className="bg-secondary text-white">কোর্স সমূহ</th>
              <th className="bg-secondary text-white">বিস্তারিত</th>
            </tr>
          </thead>
          <tbody>
            {centers?.map((member, index) => (
              <tr key={index}>
                <td>
                  <div class="font-bold">{member?.institute}</div>
                  <div class="text-sm opacity-80">{member?.address}</div>
                </td>
                <td>
                  <div class="font-bold">{member?.director}</div>
                  <div class="text-sm opacity-80">{member?.designation}</div>
                </td>
                <td>{member?.mobile}</td>
                <td>{member?.email}</td>
                <td>{member?.course}</td>
                <td>
                  <button className="pr-10">
                    <MdOutlineKeyboardArrowRight />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="px-5">
          <div className="flex justify-end items-center">
            <div className="px-6 py-4">
              {currentPage * 10 + 1}-{10 * (currentPage + 1)} of{" "}
              {centers?.length}
            </div>
            <div>
              <div className="flex items-center">
                <button className="pr-10" onClick={handleDecreasePage}>
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <button onClick={handleIncreasePage}>
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCenter;
