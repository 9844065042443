import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import Loading from "../Shared/Loading";

function AddMember() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async ({
    designation,
    address,
    file,
    institute,
    mobile,
    name,
    email,
    facebookUrl,
    whatsappUrl,
    organizationDesignation,
  }) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", file[0]);
    formData.append("name", name);
    formData.append("institute", institute);
    formData.append("designation", designation);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("facebookUrl", facebookUrl);
    formData.append("whatsappUrl", whatsappUrl);
    formData.append("organizationDesignation", organizationDesignation);
    formData.append("address", address);
    try {
      const { data } = await api.post("member/create", formData);
      if (data.success) {
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="md:mx-10 mx-2">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        কার্যকরী সদস্য যুক্ত করুন
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
          <div>
            <label class="label">
              <span class="label-text">ছবি নির্বাচন করুন *</span>
            </label>
            <input
              type="file"
              {...register("file", { required: "ছবি আবশ্যক" })}
              class="file-input file-input-bordered file-input-primary w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.file && errors.file.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">নাম *</span>
            </label>
            <input
              {...register("name", { required: "নাম আবশ্যক" })}
              type="text"
              placeholder="নাম লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.name && errors.name.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ইমেইল</span>
            </label>
            <input
              {...register("email")}
              type="text"
              placeholder="ইমেইল লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.name && errors.name.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">কর্মরত প্রতিষ্ঠানের নাম *</span>
            </label>
            <input
              {...register("institute", {
                required: "বর্তমানে কর্মরত প্রতিষ্ঠানের নাম আবশ্যক",
              })}
              type="text"
              placeholder="বর্তমানে কর্মরত প্রতিষ্ঠানের নাম লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.institute && errors.institute.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">পদবী *</span>
            </label>
            <input
              {...register("designation", {
                required: "পদবী আবশ্যক",
              })}
              type="text"
              placeholder="পদবী লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.designation && errors.designation.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ফেসবুক প্রোফাইল লিংক</span>
            </label>
            <input
              {...register("facebookUrl")}
              type="text"
              placeholder="ফেসবুক প্রোফাইল লিংক লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.facebookUrl && errors.facebookUrl.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">হোয়াটসএ্যাপ প্রোফাইল লিংক</span>
            </label>
            <input
              {...register("whatsappUrl")}
              type="text"
              placeholder="হোয়াটসএ্যাপ প্রোফাইল লিংক লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.whatsappUrl && errors.whatsappUrl.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">মোবাইল নাম্বার *</span>
            </label>
            <input
              {...register("mobile", { required: "মোবাইল নাম্বার আবশ্যক" })}
              type="text"
              placeholder="মোবাইল নাম্বার লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.mobile && errors.mobile.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ঠিকানা *</span>
            </label>
            <input
              {...register("address", { required: "ঠিকানা আবশ্যক" })}
              type="text"
              placeholder="ঠিকানা লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.address && errors.address.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">সংগঠনের পদবী *</span>
            </label>
            <input
              {...register("organizationDesignation", {
                required: "সংগঠনের পদবী আবশ্যক",
              })}
              type="text"
              placeholder="সংগঠনের পদবী লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.organizationDesignation &&
                  errors.organizationDesignation.message}
              </span>
            </label>
          </div>
        </div>
        <div className="w-full flex justify-center mt-10">
          <input
            className="btn btn-primary text-white w-44"
            value={"যুক্ত করুন"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}

export default AddMember;
