import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import Loading from "../Shared/Loading";
import divisions from "../../data/division.json";
import district from "../../data/district.json";
import upazila from "../../data/upazila.json";
import union from "../../data/union.json";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { baseUrl } from "../../utils/url";
import { Link } from "react-router-dom";
import CountDown from "../Shared/CountDown";

function ScholarshipApplication() {
  const [districts, setDistricts] = useState([]);
  const [upazilas, setUpazilas] = useState([]);
  const [unions, setUnions] = useState([]);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [signatureImageUrl, setSignatureImageUrl] = useState("");
  const [incomeCertificateImageUrl, setIncomeCertificateImageUrl] =
    useState("");
  const [sscMarkSheetImageUrl, setSscMarkSheetImageUrl] = useState("");
  const [hscMarkSheetImageUrl, setHscMarkSheetImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [applicantData, setApplicantData] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const selectedDivision = watch("division");
  const selectedDistrict = watch("district");
  const selectedUpazila = watch("upazila");
  const profileImage = watch("image");
  const signatureImage = watch("signature");
  const incomeCertificateImage = watch("incomeCertificate");
  const sscMarkSheetImage = watch("sscMarkSheetImage");
  const hscMarkSheetImage = watch("hscMarkSheetImage");
  const agreeTerm = watch("agreeTerm");
  const lastExam = watch("lastExam");

  const handleImageUpload = async (url, reason) => {
    try {
      const formData = new FormData();
      formData.append("image", url[0]);

      const { data } = await api.post("/upload-image", formData);

      if (data.success) {
        if (reason === "profile") {
          setProfileImageUrl(data.url);
        } else if (reason === "signature") {
          setSignatureImageUrl(data.url);
        } else if (reason === "sscMarkSheet") {
          setSscMarkSheetImageUrl(data.url);
        } else if (reason === "hscMarkSheet") {
          setHscMarkSheetImageUrl(data.url);
        } else if (reason === "incomeCertificate") {
          setIncomeCertificateImageUrl(data.url);
        }
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (profileImage?.length && !profileImageUrl) {
      handleImageUpload(profileImage, "profile");
    }
    if (incomeCertificateImage?.length && !incomeCertificateImageUrl) {
      handleImageUpload(incomeCertificateImage, "incomeCertificate");
    }
    if (signatureImage?.length && !signatureImageUrl) {
      handleImageUpload(signatureImage, "signature");
    }
    if (sscMarkSheetImage?.length && !sscMarkSheetImageUrl) {
      handleImageUpload(sscMarkSheetImage, "sscMarkSheet");
    }
    if (hscMarkSheetImage?.length && !hscMarkSheetImageUrl) {
      handleImageUpload(hscMarkSheetImage, "hscMarkSheet");
    }
  }, [
    incomeCertificateImage,
    incomeCertificateImageUrl,
    profileImage,
    signatureImage,
    profileImageUrl,
    signatureImageUrl,
    sscMarkSheetImageUrl,
    hscMarkSheetImageUrl,
    sscMarkSheetImage,
    hscMarkSheetImage,
  ]);

  useEffect(() => {
    const districts = district.filter(
      (dis) => dis.division_id === selectedDivision
    );
    setDistricts(districts);

    const upazilas = upazila.filter(
      (upa) => upa.district_id === selectedDistrict
    );
    setUpazilas(upazilas);

    const unions = union.filter((uni) => uni.upazilla_id === selectedUpazila);
    setUnions(unions);
  }, [selectedDivision, selectedDistrict, selectedUpazila]);

  const onSubmit = async ({
    name,
    fatherName,
    motherName,
    idStatus,
    idNo,
    dateOfBirth,
    division,
    district,
    upazila,
    union,
    village,
    mobile,
    email,
    maritalStatus,
    religion,
    guardianIncome,
    guardianProfession,
    familyMember,
    instituteName,
    healthStatus,
    sscBoard,
    sscEducationDivision,
    sscYear,
    sscResult,
    sscResultWithout4thSub,
    hscBoard,
    hscEducationDivision,
    hscYear,
    hscResult,
    hscResultWithout4thSub,
    lastExam,
    bkashNumber,
  }) => {
    setIsLoading(true);

    const applicationInfo = {
      name,
      fatherName,
      motherName,
      idStatus,
      idNo,
      dateOfBirth,
      division,
      district,
      upazila,
      union,
      village,
      mobile,
      bkashNumber,
      email,
      maritalStatus,
      religion,
      guardianIncome,
      guardianProfession,
      familyMember,
      instituteName,
      healthStatus,
      lastExam,
      sscResult: {
        board: sscBoard,
        educationDivision: sscEducationDivision,
        year: sscYear,
        result: sscResult,
        resultWithout4thSub: sscResultWithout4thSub,
        markSheetUrl: sscMarkSheetImageUrl,
      },
      hscResult: {
        board: hscBoard,
        educationDivision: hscEducationDivision,
        year: hscYear,
        result: hscResult,
        resultWithout4thSub: hscResultWithout4thSub,
        markSheetUrl: hscMarkSheetImageUrl,
      },
      profileImageUrl,
      signatureImageUrl,
      guardianIncomeCertificate: incomeCertificateImageUrl,
    };

    try {
      const { data } = await api.post(
        "scholarship-application",
        applicationInfo
      );

      if (data.success) {
        setApplicantData(data.data);
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <CountDown monthDay="9-5" />
      {!applicantData ? (
        <div className="my-10 mx-auto max-w-7xl border-2 border-primary md:p-5 p-2 rounded">
          <p className="text-center font-bold text-2xl text-primary mb-10">
            শিক্ষা বৃত্তির জন্য আবেদন
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">শিক্ষার্থীর নাম *</span>
                </label>
                <input
                  {...register("name", {
                    required: "নাম আবশ্যক",
                  })}
                  type="text"
                  placeholder="নাম লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.name && errors.name.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">পিতার নাম *</span>
                </label>
                <input
                  {...register("fatherName", {
                    required: "পিতার নাম আবশ্যক",
                  })}
                  type="text"
                  placeholder="পিতার নাম লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.fatherName && errors.fatherName.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">মাতার নাম *</span>
                </label>
                <input
                  {...register("motherName", {
                    required: "মাতার নাম আবশ্যক",
                  })}
                  type="text"
                  placeholder="মাতার নাম লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.motherName && errors.motherName.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">আইডির ধরন *</span>
                </label>
                <select
                  {...register("idStatus", {
                    required: "আইডির ধরন নির্বাচন করুন",
                  })}
                  class="select select-bordered"
                >
                  <option value="nid">NID</option>
                  <option value="birth">জন্ম সনদ</option>
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.idStatus && errors.idStatus.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">জন্ম সনদ/NID নম্বর *</span>
                </label>
                <input
                  {...register("idNo", {
                    required: "জন্ম সনদ/NID নম্বর আবশ্যক",
                  })}
                  type="text"
                  placeholder="জন্ম সনদ/NID নম্বর লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.idNo && errors.idNo.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">জন্ম তারিখ *</span>
                </label>
                <input
                  {...register("dateOfBirth", {
                    required: "জন্ম তারিখ আবশ্যক",
                  })}
                  type="text"
                  placeholder="দিন-মাস-বছর"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.dateOfBirth && errors.dateOfBirth.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">শিক্ষার্থীর মোবাইল *</span>
                </label>
                <input
                  {...register("mobile", {
                    required: "মোবাইল আবশ্যক",
                  })}
                  type="text"
                  placeholder="মোবাইল লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.mobile && errors.mobile.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">বিকাশ নাম্বার *</span>
                </label>
                <input
                  {...register("bkashNumber", {
                    required: "বিকাশ নাম্বার আবশ্যক",
                  })}
                  type="text"
                  placeholder="বিকাশ নাম্বার লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.bkashNumber && errors.bkashNumber.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">শিক্ষার্থীর ইমেইল *</span>
                </label>
                <input
                  {...register("email", {
                    required: "ইমেইল আবশ্যক",
                  })}
                  type="text"
                  placeholder="ইমেইল লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.email && errors.email.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">বিভাগ *</span>
                </label>
                <select
                  {...register("division", { required: "বিভাগ আবশ্যক" })}
                  class="select select-bordered"
                >
                  {divisions?.map((div) => (
                    <option key={div.id} value={div.id}>
                      {div.bn_name}
                    </option>
                  ))}
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.division && errors.division.message}
                  </span>
                </label>
              </div>

              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">জেলা *</span>
                </label>
                <select
                  {...register("district", { required: "জেলা আবশ্যক" })}
                  class="select select-bordered"
                >
                  {districts?.map((dis) => (
                    <option key={dis.id} value={dis.id}>
                      {dis.bn_name}
                    </option>
                  ))}
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.district && errors.district.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">উপজেলা *</span>
                </label>
                <select
                  {...register("upazila", { required: "উপজেলা আবশ্যক" })}
                  class="select select-bordered"
                >
                  {upazilas?.map((upa) => (
                    <option key={upa.id} value={upa.id}>
                      {upa.bn_name}
                    </option>
                  ))}
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.upazila && errors.upazila.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">ইউনিয়ন *</span>
                </label>
                <select
                  {...register("union", { required: "ইউনিয়ন আবশ্যক" })}
                  class="select select-bordered"
                >
                  {unions?.map((uni) => (
                    <option key={uni.id} value={uni.id}>
                      {uni.bn_name}
                    </option>
                  ))}
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.union && errors.union.message}
                  </span>
                </label>
              </div>

              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">গ্রাম *</span>
                </label>
                <input
                  {...register("village", {
                    required: "গ্রাম আবশ্যক",
                  })}
                  type="text"
                  placeholder="গ্রাম লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.village && errors.village.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">বৈবাহিক অবস্থা *</span>
                </label>
                <select
                  {...register("maritalStatus", {
                    required: "বৈবাহিক অবস্থা তথ্য আবশ্যক",
                  })}
                  class="select select-bordered"
                >
                  <option value="yes">বিবাহিত</option>
                  <option value="no">অবিবাহিত</option>
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.maritalStatus && errors.maritalStatus.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">ধর্ম *</span>
                </label>
                <select
                  {...register("religion", {
                    required: "ধর্মের তথ্য আবশ্যক",
                  })}
                  class="select select-bordered"
                >
                  <option value="islam">ইসলাম</option>
                  <option value="hindu">হিন্দু</option>
                  <option value="buddho">বৌদ্ধ</option>
                  <option value="khristan">খ্রিস্টান</option>
                  <option value="other">অন্যান্য</option>
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.religion && errors.religion.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">অভিভাবকের পেশা *</span>
                </label>
                <select
                  {...register("guardianProfession", {
                    required: "অভিভাবকের পেশা আবশ্যক",
                  })}
                  class="select select-bordered"
                >
                  <option value="কৃষক">কৃষক</option>
                  <option value="দিন মজুর">দিন মজুর</option>
                  <option value="ভিক্ষুক">ভিক্ষুক</option>
                  <option value="শ্রমিক">শ্রমিক</option>
                  <option value="গৃহিণী">গৃহিণী</option>
                  <option value="সরকারি চাকুরিজীবি">সরকারি চাকুরিজীবি</option>
                  <option value="বেসরকারি চাকুরিজীবী">
                    বেসরকারি চাকুরিজীবী
                  </option>
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.guardianProfession &&
                      errors.guardianProfession.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">অভিভাবকের মাসিক আয় *</span>
                </label>
                <input
                  {...register("guardianIncome", {
                    required: "অভিভাবকের মাসিক আয় আবশ্যক",
                  })}
                  type="text"
                  placeholder="অভিভাবকের মাসিক আয় লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.guardianIncome && errors.guardianIncome.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">পরিবারের সদস্য সংখ্যা *</span>
                </label>
                <input
                  {...register("familyMember", {
                    required: "পরিবারের সদস্য সংখ্যা আবশ্যক",
                  })}
                  type="text"
                  placeholder="পরিবারের সদস্য সংখ্যা লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.familyMember && errors.familyMember.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">প্রতিষ্ঠানের নাম *</span>
                </label>
                <input
                  {...register("instituteName", {
                    required: "প্রতিষ্ঠানের নাম আবশ্যক",
                  })}
                  type="text"
                  placeholder="প্রতিষ্ঠানের নাম লিখুন"
                  class="input input-bordered w-full max-w-xs"
                />
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.instituteName && errors.instituteName.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">শারীরিক অবস্থা *</span>
                </label>
                <select
                  {...register("healthStatus", {
                    required: "শারীরিক অবস্থা তথ্য আবশ্যক",
                  })}
                  class="select select-bordered"
                >
                  <option value="good">ভাল</option>
                  <option value="bad">প্রতিবন্ধী</option>
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.healthStatus && errors.healthStatus.message}
                  </span>
                </label>
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">সর্বশেষ পরীক্ষা *</span>
                </label>
                <select
                  {...register("lastExam", {
                    required: "সর্বশেষ পরীক্ষা তথ্য আবশ্যক",
                  })}
                  class="select select-bordered"
                >
                  <option value="ssc">SSC</option>
                  <option value="hsc">HSC</option>
                </select>
                <label class="label">
                  <span class="label-text-alt text-red-500">
                    {errors.lastExam && errors.lastExam.message}
                  </span>
                </label>
              </div>
            </div>
            <div className="my-10">
              {lastExam === "ssc" ? (
                <div className="border-2 rounded-lg md:p-5 md:m-5 m-2">
                  <p>এসএসসি</p>
                  <div className="my-5 md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">বোর্ড *</span>
                      </label>
                      <input
                        {...register("sscBoard", {
                          required: "বোর্ড আবশ্যক",
                        })}
                        type="text"
                        placeholder="বোর্ড লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.sscBoard && errors.sscBoard.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">বিভাগ *</span>
                      </label>
                      <input
                        {...register("sscEducationDivision", {
                          required: "বিভাগ আবশ্যক",
                        })}
                        type="text"
                        placeholder="বিভাগ লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.sscEducationDivision &&
                            errors.sscEducationDivision.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">পরীক্ষার সন *</span>
                      </label>
                      <input
                        {...register("sscYear", {
                          required: "পরীক্ষার সন আবশ্যক",
                        })}
                        type="text"
                        placeholder="পরীক্ষার সন লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.sscYear && errors.sscYear.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">ফলাফল *</span>
                      </label>
                      <input
                        {...register("sscResult", {
                          required: "ফলাফল আবশ্যক",
                        })}
                        type="text"
                        placeholder="ফলাফল লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.sscResult && errors.sscResult.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">
                          4th সাবজেক্ট ব্যতিত ফলাফল *
                        </span>
                      </label>
                      <input
                        {...register("sscResultWithout4thSub", {
                          required: "4th সাবজেক্ট ব্যতিত ফলাফল আবশ্যক",
                        })}
                        type="text"
                        placeholder="4th সাবজেক্ট ব্যতিত ফলাফল লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.sscResultWithout4thSub &&
                            errors.sscResultWithout4thSub.message}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label class="label">
                        <span class="label-text">
                          SSC মার্কশীট এর ছবি নির্বাচন করুন *
                        </span>
                      </label>
                      <input
                        type="file"
                        {...register("sscMarkSheetImage")}
                        class="file-input file-input-bordered file-input-primary w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.sscMarkSheetImage &&
                            errors.sscMarkSheetImage.message}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="border-2 rounded-lg md:p-5 md:m-5 m-2">
                  <p>এইচএসসি</p>
                  <div className="my-5 md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">বোর্ড *</span>
                      </label>
                      <input
                        {...register("hscBoard", {
                          required: "বোর্ড আবশ্যক",
                        })}
                        type="text"
                        placeholder="বোর্ড লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.hscBoard && errors.hscBoard.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">বিভাগ *</span>
                      </label>
                      <input
                        {...register("hscEducationDivision", {
                          required: "বিভাগ আবশ্যক",
                        })}
                        type="text"
                        placeholder="বিভাগ লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.hscEducationDivision &&
                            errors.hscEducationDivision.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">পরীক্ষার সন *</span>
                      </label>
                      <input
                        {...register("hscYear", {
                          required: "পরীক্ষার সন আবশ্যক",
                        })}
                        type="text"
                        placeholder="পরীক্ষার সন লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.hscYear && errors.hscYear.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">ফলাফল *</span>
                      </label>
                      <input
                        {...register("hscResult", {
                          required: "ফলাফল আবশ্যক",
                        })}
                        type="text"
                        placeholder="ফলাফল লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.hscResult && errors.hscResult.message}
                        </span>
                      </label>
                    </div>
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text">
                          4th সাবজেক্ট ব্যতিত ফলাফল *
                        </span>
                      </label>
                      <input
                        {...register("hscResultWithout4thSub", {
                          required: "4th সাবজেক্ট ব্যতিত ফলাফল আবশ্যক",
                        })}
                        type="text"
                        placeholder="4th সাবজেক্ট ব্যতিত ফলাফল লিখুন"
                        class="input input-bordered w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.hscResultWithout4thSub &&
                            errors.hscResultWithout4thSub.message}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label class="label">
                        <span class="label-text">
                          HSC মার্কশীট এর ছবি নির্বাচন করুন *
                        </span>
                      </label>
                      <input
                        type="file"
                        {...register("hscMarkSheetImage", {
                          required: "HSC মার্কশীট এর ছবি আবশ্যক",
                        })}
                        class="file-input file-input-bordered file-input-primary w-full max-w-xs"
                      />
                      <label class="label">
                        <span class="label-text-alt text-red-500">
                          {errors.hscMarkSheetImage &&
                            errors.hscMarkSheetImage.message}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              <div className="border-2 rounded-lg md:p-5 md:m-5 m-2 md:grid grid-cols-3">
                <div>
                  <label class="label">
                    <span class="label-text">
                      শিক্ষার্থীর ছবি নির্বাচন করুন *
                    </span>
                  </label>
                  <input
                    type="file"
                    {...register("image", { required: "ছবি আবশ্যক" })}
                    class="file-input file-input-bordered file-input-primary w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.image && errors.image.message}
                    </span>
                  </label>
                </div>
                <div>
                  <label class="label">
                    <span class="label-text">
                      শিক্ষার্থীর স্বাক্ষর নির্বাচন করুন *
                    </span>
                  </label>
                  <input
                    type="file"
                    {...register("signature", { required: "স্বাক্ষর আবশ্যক" })}
                    class="file-input file-input-bordered file-input-primary w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.signature && errors.signature.message}
                    </span>
                  </label>
                </div>
                <div>
                  <label class="label">
                    <span class="label-text">
                      অভিভাবকের আয়ের সনদ নির্বাচন করুন *
                    </span>
                  </label>
                  <input
                    type="file"
                    {...register("incomeCertificate", {
                      required: "অভিভাবকের আয়ের সনদ আবশ্যক",
                    })}
                    class="file-input file-input-bordered file-input-primary w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.incomeCertificate &&
                        errors.incomeCertificate.message}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <input
                {...register("agreeTerm")}
                type="checkbox"
                class="checkbox mr-2"
              />
              <span class="label-text">
                আমি এই বলে ঘোষণা করছি যে, উপরে বর্ণিত সকল তথ্য সত্য। যদি মিথ্যা
                তথ্য প্রদান করি তবে আমার বিরুদ্ধে আইনগত ব্যবস্থা গ্রহণ করা যাবে।
              </span>
            </div>
            {profileImageUrl &&
            signatureImageUrl &&
            incomeCertificateImageUrl &&
            (sscMarkSheetImageUrl || hscMarkSheetImageUrl) ? (
              <div className="w-full flex justify-center mt-10">
                <input
                  disabled={!agreeTerm}
                  className="btn btn-primary text-white w-44"
                  value={"সাবমিট করুন"}
                  type="submit"
                />
              </div>
            ) : (
              <div className="flex justify-center mt-10">
                <p className="text-red-500 font-bold">
                  * উপরোক্ত সকল তথ্য এবং ফাইল আপলোড করুন
                </p>
              </div>
            )}
          </form>
        </div>
      ) : (
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl font-bold mb-4 text-center">
            অভিনন্দন!!! প্রিয় {applicantData.name}
          </h2>
          <h3 className="text-lg font-semibold mb-4 text-center">
            তোমার আবেদন আমরা পেয়েছি। তথ্য যাচাই বাচাই এর পর তুমি নির্বাচিত হলে
            আমরা তোমাকে {applicantData.mobile} নাম্বারে SMS এর মাধ্যমে জানিয়ে
            দিব। তোমার জন্য শুভ কামনা।
          </h3>
          <h2 className="text-3xl font-bold mb-4 text-center">
            তোমার আবেদন আইডি {applicantData.id}
          </h2>
          <div className="flex w-full justify-center my-2">
            <Link
              className="btn btn-primary text-white px-5 py-2"
              to={`/scholarship-application/${applicantData.id}`}
            >
              তোমার আবেদনপত্র প্রিন্ট কর
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default ScholarshipApplication;
