import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import Loading from "../Shared/Loading";
import { Link } from "react-router-dom";
import CountDown from "../Shared/CountDown";
import role from "../../data/role.json";

function Picnic() {
  const [isLoading, setIsLoading] = useState(false);
  const [isReadInstruction, setIsReadInstruction] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const agreeTerm = watch("agreeTerm");

  const onSubmit = async (info) => {
    setIsLoading(true);

    try {
      const { data } = await api.post("picnic", info);

      if (data.success) {
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div id="myPage" className="bg-gray-100 md:p-10 p-2">
      <CountDown monthDay="12-17" />

      {!isReadInstruction ? (
        <div>
          <div className="bg-primary text-white p-4 rounded-lg shadow-lg my-10">
            <h2 className="text-2xl font-bold">শর্তাবলী:</h2>
            <ul className="list-disc ml-6">
              <li>১. উপদেষ্ঠা মহোদয়গণের জন্য ৬০০ টাকা চাঁদা</li>
              <li>২. সদস্য গণের চাঁদা ৪০০ টাকা জন প্রতি</li>
              <li>
                ৩. এই মিলন মেলায় যে কেউ অংশগ্রহন করতে পারবে, একাধিক সদস্য যুক্ত
                হলে একাধিক ফি দিতে হবে। [প্রতিটি সদস্যদের জন্য ৪০০ টাকা প্রদান
                করতে হবে।]
              </li>
              <li>৪. ছোট বাচ্ছা এর আওতামুক্ত থাকবে।</li>

              <br />
              <br />
              <li>
                উপরে বর্ণিত কোন শর্তাবলী ব্যতিত আবেদন করলে আবেদন বাতিল হবে।
              </li>
            </ul>
          </div>
          <div className="flex items-center">
            <input
              onChange={(e) => setIsReadInstruction(e.target.checked)}
              type="checkbox"
              class="checkbox mr-2"
            />
            <span class="label-text">উপরে বর্ণিত সকল শর্তাবলী পড়েছি।</span>
          </div>
        </div>
      ) : (
        <div className="my-10 mx-auto max-w-7xl border-2 border-primary md:p-5 p-2 rounded bg-white">
          <div>
            <p className="text-center font-bold text-2xl md:text-4xl text-primary mb-10">
              স্বেচ্ছাসেবী মিলন মেলা ২০২৩ নিবন্ধন
            </p>
            <div className="mt-2 mb-10 border-l-8 px-2 border-primary">
              <h2 className="font-bold text-primary mt-5">পেমেন্ট পদ্ধতি:</h2>
              <h3 className=" font-bold">* বিকাশ- 01744769331</h3>
              <h3 className=" font-bold">* নগদ - 01744769331</h3>
              <h3 className=" font-bold">
                * ডাচ্ বাংলা ব্যাংক একাউন্ট- 7017100274165
              </h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">নাম *</span>
                  </label>
                  <input
                    {...register("name", {
                      required: "নাম আবশ্যক",
                    })}
                    type="text"
                    placeholder="নাম লিখুন"
                    class="input input-bordered w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.name && errors.name.message}
                    </span>
                  </label>
                </div>
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">পিতা/স্বামীর নাম *</span>
                  </label>
                  <input
                    {...register("guardianName", {
                      required: "পিতা/স্বামীর নাম আবশ্যক",
                    })}
                    type="text"
                    placeholder="পিতা/স্বামীর নাম লিখুন"
                    class="input input-bordered w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.guardianName && errors.guardianName.message}
                    </span>
                  </label>
                </div>
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">পদবী *</span>
                  </label>
                  <select
                    {...register("designation", {
                      required: "পদবী আবশ্যক",
                    })}
                    class="select select-bordered"
                  >
                    {role?.map((rl) => (
                      <option key={rl.id} value={rl.roleEn}>
                        {rl.roleBn}
                      </option>
                    ))}
                  </select>
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.role && errors.role.message}
                    </span>
                  </label>
                </div>
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">মোবাইল *</span>
                  </label>
                  <input
                    {...register("mobile", {
                      required: "মোবাইল আবশ্যক",
                    })}
                    type="text"
                    placeholder="মোবাইল লিখুন"
                    class="input input-bordered w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.mobile && errors.mobile.message}
                    </span>
                  </label>
                </div>
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">অন্যান্য সদস্য সংখ্যা</span>
                  </label>
                  <input
                    {...register("totalMember")}
                    type="number"
                    defaultValue={0}
                    placeholder="যদি আপনার পরিববারে সদস্য অংশগ্রহন করাতে চান তাহলে কতজন"
                    class="input input-bordered w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.totalMember && errors.totalMember.message}
                    </span>
                  </label>
                </div>

                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">গ্রাম *</span>
                  </label>
                  <input
                    {...register("village", {
                      required: "গ্রাম আবশ্যক",
                    })}
                    type="text"
                    placeholder="গ্রাম লিখুন"
                    class="input input-bordered w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.village && errors.village.message}
                    </span>
                  </label>
                </div>

                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">টি শার্ট সাইজ *</span>
                  </label>
                  <select
                    {...register("tShirtSize", {
                      required: "টি শার্ট সাইজ তথ্য আবশ্যক",
                    })}
                    class="select select-bordered"
                  >
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="XXL">XXL</option>
                  </select>
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.tShirtSize && errors.tShirtSize.message}
                    </span>
                  </label>
                </div>
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">পেমেন্টের ধরন *</span>
                  </label>
                  <select
                    {...register("paymentMethod", {
                      required: "পেমেন্টের ধরন আবশ্যক",
                    })}
                    class="select select-bordered"
                  >
                    <option value="বিকাশ">বিকাশ</option>
                    <option value="নগদ">নগদ</option>
                    <option value="ব্যাংক একাউন্ট">ব্যাংক একাউন্ট</option>
                    <option value="নগদ ক্যাশ">নগদ ক্যাশ</option>
                  </select>
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.paymentMethod && errors.paymentMethod.message}
                    </span>
                  </label>
                </div>
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">একাউন্ট নং *</span>
                  </label>
                  <input
                    {...register("accountNo", {
                      required: "একাউন্ট নং আবশ্যক",
                    })}
                    type="text"
                    placeholder="একাউন্ট নং লিখুন"
                    class="input input-bordered w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.accountNo && errors.accountNo.message}
                    </span>
                  </label>
                </div>
                <div class="form-control w-full max-w-xs">
                  <label class="label">
                    <span class="label-text">ট্র্যান্সজেকশন আইডি *</span>
                  </label>
                  <input
                    {...register("transactionId", {
                      required: "ট্র্যান্সজেকশন আইডি আবশ্যক",
                    })}
                    type="text"
                    placeholder="ট্র্যান্সজেকশন আইডি লিখুন"
                    class="input input-bordered w-full max-w-xs"
                  />
                  <label class="label">
                    <span class="label-text-alt text-red-500">
                      {errors.transactionId && errors.transactionId.message}
                    </span>
                  </label>
                </div>
              </div>

              <div className="flex items-center">
                <input
                  {...register("agreeTerm")}
                  type="checkbox"
                  class="checkbox mr-2"
                />
                <span class="label-text">
                  আমি এই বলে ঘোষণা করছি যে, উপরে বর্ণিত সকল তথ্য সত্য।
                </span>
              </div>
              <div className="w-full flex justify-center mt-10">
                <input
                  disabled={!agreeTerm}
                  className="btn btn-primary text-white w-44"
                  value={"সাবমিট করুন"}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Picnic;
