import React from 'react';
import food from '../../assets/image/food-icon.webp';
import education from '../../assets/image/education-icon.webp';
import treatment from '../../assets/image/treatment-icon.webp';

const Mission = () => {
    const missionActivities = [
        { id: 1, name: 'খাদ্য', description: 'আমরা চাই যাতে কোন মানুষ খাদ্য অভাবে না থাকে।', image: food },
        { id: 2, name: 'শিক্ষা', description: 'শিশুদের শিক্ষা থেকে ঝড়িয়ে পড়া রোধ করতে চাই আমরা।', image: education },
        { id: 3, name: 'চিকিৎসা', description: 'চিকিৎসার অভাবে যাতে কাউকে মরতে না হয় সেই প্রচেষ্টায় কাজ করছি আমরা।', image: treatment },
    ]
    return (
        <div>
            <h3 className='text-3xl font-bold text-center text-primary mt-10 mb-5 p-2'>আমাদের লক্ষ্য এবং উদ্দেশ্য</h3>
            <p className='px-2 md:px-10 lg:px-20'>
                আমরা ভালবাসা, আশা এবং সামাজিক ন্যায়বিচার সমবেত একটি পৃথিবীর স্বপ্ন দেখি, যেখানে মানুষ মর্যাদা ও নিরাপত্তা নিয়ে বাস করবে। সমাজের অনগ্রর শ্রেণিকে বিশেষভাবে চরম দরিদ্র, গৃহহীন ও অনাথ শিশুদের শিক্ষা, পুষ্টি ও আশ্রয় সরবাহের মাধ্যমে তাদের জাতির জন্য সম্পদে পরিণত করা আমাদের প্রধান লক্ষ্য। ইহা সম্পূর্ণ অরাজনৈতিক, অলাভজনক, সামাজিক উন্নয়নমূলক, সাংস্কৃতিক ও স্বেচ্ছায় মানব সেবার সংগঠন
            </p>
            <div className='md:grid grid-cols-2 lg:grid-cols-3 place-items-center mt-5'>
                {
                    missionActivities?.map(activity => <div data-aos="flip-up" key={activity.id} className="card max-w-sm bg-secondary shadow-xl m-2 md:m-0">
                        <figure className="px-10 pt-10">
                            <img src={activity.image} alt="Shoes" className="rounded-xl" />
                        </figure>
                        <div className="card-body items-center text-center">
                            <h2 className="card-title text-white">{activity.name}</h2>
                            <p className='text-white'>{activity.description}</p>
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
};

export default Mission;