import React from "react";
import JoinUs from "./JoinUs";
import AboutUsBanner from "./AboutUsBanner";
import HomeBanner from "./HomeBanner";
import Mission from "./Mission";
import HomeCarousel from "./HomeCarousel";
import RecentActivities from "./RecentActivities";
import PrincipleAdviserMember from "./PrincipleAdviserMember";
import JoinUsBanner from "./JoinUsBanner";
import TopDonor from "./TopDonor";
import ActivitiesSummary from "./ActivitiesSummary";
import SummaryBanner from "./SummaryBanner";
import PresidentMessage from "./PresidentMessage";

const Home = () => {
  return (
    <div>
      <div className="mx-2 md:mx-5 lg:mx-10">
        <HomeCarousel />
        <div>
          <h3 className="text-3xl font-bold text-center text-primary mt-16 mb-5">
            আমাদের সাম্প্রতিক কার্যক্রম সমূহ
          </h3>
          <RecentActivities />
        </div>
        <HomeBanner />
        <JoinUs />
        <PrincipleAdviserMember />
        <PresidentMessage />
        <Mission />
        <SummaryBanner />
        <TopDonor />
        <ActivitiesSummary />
        <AboutUsBanner />
        <JoinUsBanner />
      </div>
    </div>
  );
};

export default Home;
