import React from "react";
import { BiDollarCircle } from "react-icons/bi";
import { FaPeopleCarry } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { Link } from "react-router-dom";

function SummaryBanner() {
  return (
    <div className="md:grid grid-cols-3 place-content-center place-items-center my-10 max-w-7xl mx-auto gap-10">
      <div>
        <h1 className="text-4xl font-bold mb-5">
          পূর্ব বড়ুয়া তরুণ সংঘ একটি{" "}
          <span className="text-primary">স্বপ্নের নাম</span>
        </h1>
        <p className="mb-5">
          আমরা চাই ধনী ও গরীবের সমতা। ক্ষুধা মুক্ত সমাজ। স্বপ্নবাজ শিশু, কিশোর,
          তরুন যারা দেশ এগিয়ে নেওয়ার স্বপ্ন দেখে। আমরা চাই টাকার অভাবে যাতে কোন
          শিক্ষার্থীকে পড়াশোনা থেকে ঝড়ে না পড়তে হয়। চিকিৎসার অভাবে যাতে কাউকে
          মরতে না হয়
        </p>
        <button className="btn btn-primary font-bold text-white">
          আরো পড়ুন
        </button>
      </div>
      <div className="md:grid grid-cols-2 lg:grid-cols-3 gap-5 col-span-2">
        <div class="card bg-base-100 shadow-xl border">
          <figure class="px-10 pt-10">
            <BiDollarCircle
              className="bg-primary rounded-3xl p-2"
              size={80}
              color="#fff"
            />
          </figure>
          <div class="card-body items-center text-center">
            <h2 class="card-title">দান</h2>
            <p>অলাভজনক এবং সামাজিক সংগঠনে দান করুন</p>
            <div class="card-actions">
              <Link class="btn btn-primary text-white font-bold" to="/donation">
                দান করুন
              </Link>
            </div>
          </div>
        </div>
        <div class="card bg-base-100 shadow-xl border">
          <figure class="px-10 pt-10">
            <FaPeopleCarry
              className="bg-primary rounded-3xl p-2"
              size={80}
              color="#fff"
            />
          </figure>
          <div class="card-body items-center text-center">
            <h2 class="card-title">স্বেচ্ছাসেবক</h2>
            <p>বিভিন্ন সামাজিক কাজে স্বেচ্ছাসেবক হিসাবে সহযোগিতা করুন</p>

            <div class="card-actions">
              <Link class="btn btn-primary text-white font-bold" to="/signup">
                রেজিস্টার করুন
              </Link>
            </div>
          </div>
        </div>
        <div class="card bg-base-100 shadow-xl border">
          <figure class="px-10 pt-10">
            <FaUserGraduate
              className="bg-primary rounded-3xl p-2"
              size={80}
              color="#fff"
            />
          </figure>
          <div class="card-body items-center text-center">
            <h2 class="card-title">শিশুর দায়িত্ব নিন</h2>
            <p>গরীব, অভাবী শিশুর দায়িত্ব নিন তার উজ্জ্বল ভবিষ্যতের জন্য</p>
            <div class="card-actions">
              <Link
                class="btn btn-primary text-white font-bold"
                to="/sponsor-info"
              >
                স্পন্সর করুন
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryBanner;
