import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import Loading from "../Shared/Loading";
import { Link } from "react-router-dom";
import CountDown from "../Shared/CountDown";

function MeritoriousStudent() {
  const [isReadInstruction, setIsReadInstruction] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [applicantData, setApplicantData] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const profileImageUrl = watch("profileImageUrl");
  const agreeTerm = watch("agreeTerm");
  const lastExam = watch("lastExam");
  const chancedInstituteCategory = watch("chancedInstituteCategory");

  const handleImageUpload = async (url, reason) => {
    try {
      const formData = new FormData();
      formData.append("image", url[0]);

      const { data } = await api.post("/upload-image", formData);

      if (data.success) {
        if (reason === "profile") {
          setProfileImage(data.url);
        }
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (profileImageUrl?.length && !profileImage) {
      handleImageUpload(profileImageUrl, "profile");
    }
  }, [profileImageUrl, profileImage]);

  const onSubmit = async (info) => {
    setIsLoading(true);

    info.profileImageUrl = profileImage;

    try {
      const { data } = await api.post("meritorious-student", info);

      if (data.success) {
        setApplicantData(data.data);
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div id="myPage" className="bg-gray-100 md:p-10 p-2">
      <CountDown monthDay="9-30" />

      {!applicantData ? (
        <div className="my-10 mx-auto max-w-7xl border-2 border-primary md:p-5 p-2 rounded bg-white">
          {isReadInstruction ? (
            <div>
              <p className="text-center font-bold text-2xl text-primary mb-10">
                মেধাবী শিক্ষার্থী সংবর্ধনার জন্য আবেদন
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
                  <div>
                    <label class="label">
                      <span class="label-text">
                        শিক্ষার্থীর ছবি নির্বাচন করুন *
                      </span>
                    </label>
                    <input
                      type="file"
                      {...register("profileImageUrl", {
                        required: "ছবি আবশ্যক",
                      })}
                      class="file-input file-input-bordered file-input-primary w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.profileImageUrl &&
                          errors.profileImageUrl.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">শিক্ষার্থীর নাম *</span>
                    </label>
                    <input
                      {...register("name", {
                        required: "নাম আবশ্যক",
                      })}
                      type="text"
                      placeholder="নাম লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.name && errors.name.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">পিতার নাম *</span>
                    </label>
                    <input
                      {...register("fatherName", {
                        required: "পিতার নাম আবশ্যক",
                      })}
                      type="text"
                      placeholder="পিতার নাম লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.fatherName && errors.fatherName.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">মাতার নাম *</span>
                    </label>
                    <input
                      {...register("motherName", {
                        required: "মাতার নাম আবশ্যক",
                      })}
                      type="text"
                      placeholder="মাতার নাম লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.motherName && errors.motherName.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">শিক্ষার্থীর মোবাইল *</span>
                    </label>
                    <input
                      {...register("mobile", {
                        required: "মোবাইল আবশ্যক",
                      })}
                      type="text"
                      placeholder="মোবাইল লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.mobile && errors.mobile.message}
                      </span>
                    </label>
                  </div>

                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">ইউনিয়ন *</span>
                    </label>
                    <input
                      {...register("union", {
                        required: "ইউনিয়ন আবশ্যক",
                      })}
                      type="text"
                      placeholder="ইউনিয়ন লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.union && errors.union.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">গ্রাম *</span>
                    </label>
                    <input
                      {...register("village", {
                        required: "গ্রাম আবশ্যক",
                      })}
                      type="text"
                      placeholder="গ্রাম লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.village && errors.village.message}
                      </span>
                    </label>
                  </div>

                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">
                        প্রতিষ্ঠানের নাম (পাশকৃত) *
                      </span>
                    </label>
                    <input
                      {...register("schoolOrCollegeName", {
                        required: "প্রতিষ্ঠানের নাম আবশ্যক",
                      })}
                      type="text"
                      placeholder="প্রতিষ্ঠানের নাম লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.schoolOrCollegeName &&
                          errors.schoolOrCollegeName.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">সর্বশেষ পরীক্ষা *</span>
                    </label>
                    <select
                      {...register("lastExam", {
                        required: "সর্বশেষ পরীক্ষা তথ্য আবশ্যক",
                      })}
                      class="select select-bordered"
                    >
                      <option value="ssc">SSC</option>
                      <option value="hsc">HSC</option>
                    </select>
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.lastExam && errors.lastExam.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">পাশের সন *</span>
                    </label>
                    <select
                      {...register("passingYear", {
                        required: "পাশের সন তথ্য আবশ্যক",
                      })}
                      class="select select-bordered"
                    >
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                    </select>
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.passingYear && errors.passingYear.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">পাশকৃত পরীক্ষায় বিভাগ *</span>
                    </label>
                    <select
                      {...register("lastExamDivision", {
                        required: "পাশকৃত পরীক্ষায় বিভাগ আবশ্যক",
                      })}
                      class="select select-bordered"
                    >
                      <option value="science">বিজ্ঞান</option>
                      <option value="arts">মানবিক</option>
                      <option value="commerce">বাণিজ্য</option>
                    </select>
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.lastExamDivision &&
                          errors.lastExamDivision.message}
                      </span>
                    </label>
                  </div>
                  <div class="form-control w-full max-w-xs">
                    <label class="label">
                      <span class="label-text">ফলাফল *</span>
                    </label>
                    <input
                      {...register("result", {
                        required: "ফলাফল আবশ্যক",
                      })}
                      type="text"
                      placeholder="ফলাফল লিখুন"
                      class="input input-bordered w-full max-w-xs"
                    />
                    <label class="label">
                      <span class="label-text-alt text-red-500">
                        {errors.result && errors.result.message}
                      </span>
                    </label>
                  </div>
                  {lastExam === "hsc" && (
                    <>
                      <div class="form-control w-full max-w-xs">
                        <label class="label">
                          <span class="label-text">
                            চান্স প্রাপ্ত প্রতিষ্ঠানের ধরন *
                          </span>
                        </label>
                        <select
                          {...register("chancedInstituteCategory", {
                            required: "চান্স প্রাপ্ত প্রতিষ্ঠানের ধরন আবশ্যক",
                          })}
                          class="select select-bordered"
                        >
                          <option value="not">Not</option>
                          <option value="engineering">Engineering</option>
                          <option value="medical">Medical</option>
                          <option value="university">University</option>
                        </select>
                        <label class="label">
                          <span class="label-text-alt text-red-500">
                            {errors.chancedInstituteCategory &&
                              errors.chancedInstituteCategory.message}
                          </span>
                        </label>
                      </div>
                      {chancedInstituteCategory !== "not" && (
                        <>
                          <div class="form-control w-full max-w-xs">
                            <label class="label">
                              <span class="label-text">
                                প্রতিষ্ঠানের নাম (চান্স প্রাপ্ত) *
                              </span>
                            </label>
                            <input
                              {...register("chancedInstituteName", {
                                required: "প্রতিষ্ঠানের নাম আবশ্যক",
                              })}
                              type="text"
                              placeholder="প্রতিষ্ঠানের নাম লিখুন"
                              class="input input-bordered w-full max-w-xs"
                            />
                            <label class="label">
                              <span class="label-text-alt text-red-500">
                                {errors.chancedInstituteName &&
                                  errors.chancedInstituteName.message}
                              </span>
                            </label>
                          </div>
                          <div class="form-control w-full max-w-xs">
                            <label class="label">
                              <span class="label-text">
                                বিভাগ/ইউনিটের নাম *
                              </span>
                            </label>
                            <input
                              {...register("chancedSubjectOrUnitName", {
                                required: "ডিপার্টমেন্ট/ইউনিটের নাম আবশ্যক",
                              })}
                              type="text"
                              placeholder="ডিপার্টমেন্ট/ইউনিটের নাম লিখুন"
                              class="input input-bordered w-full max-w-xs"
                            />
                            <label class="label">
                              <span class="label-text-alt text-red-500">
                                {errors.chancedSubjectOrUnitName &&
                                  errors.chancedSubjectOrUnitName.message}
                              </span>
                            </label>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  <input
                    {...register("agreeTerm")}
                    type="checkbox"
                    class="checkbox mr-2"
                  />
                  <span class="label-text">
                    আমি এই বলে ঘোষণা করছি যে, উপরে বর্ণিত সকল তথ্য সত্য। যদি
                    মিথ্যা তথ্য প্রদান করি তবে আমার বিরুদ্ধে আইনগত ব্যবস্থা
                    গ্রহণ করা যাবে।
                  </span>
                </div>
                <div className="w-full flex justify-center mt-10">
                  <input
                    disabled={!agreeTerm}
                    className="btn btn-primary text-white w-44"
                    value={"সাবমিট করুন"}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          ) : (
            <div>
              <div className="bg-primary text-white p-4 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold">নির্দেশনা:</h2>
                <ul className="list-disc ml-6">
                  <li>১. অবশ্যই কুলাঘাট ইউনিয়নের বাসিন্দা হতে হবে।</li>
                  <li>
                    ২. অবশ্যই সঠিকভাবে নাম লিখতে হবে (ক্রেস্ট এ প্রিন্ট হবে)
                  </li>
                  <li>৩. অবশ্যই GPA-5 (A+) প্রাপ্ত শিক্ষার্থী হতে হবে।</li>
                  <li>৪. ফরমাল ছবি আপলোড করতে হবে।</li>
                  <li>
                    ৫. তথ্য যাচাই বাচাই এর পর চূড়ান্তভাবে মনোনীত শিক্ষার্থী কে
                    মোবাইলে এসএমএস এর মাধ্যমে ফলাফল জানানো হবে বিধায় সচল মোবাইল
                    নম্বর প্রদান করতে হবে।
                  </li>
                  <br />
                  <br />
                  <li>
                    উপরে বর্ণিত কোন নির্দেশনা ব্যতিত আবেদন করলে আবেদন বাতিল হবে।
                  </li>
                </ul>
              </div>
              <div className="flex items-center mb-2 mt-5">
                <input
                  onChange={(e) => setIsReadInstruction(e.target.checked)}
                  type="checkbox"
                  class="checkbox mr-2"
                />
                <span class="label-text">উপরে বর্ণিত সকল নির্দেশনা পড়েছি।</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl font-bold mb-4 text-center">
            অভিনন্দন!!! প্রিয় {applicantData.name}
          </h2>
          <h3 className="text-lg font-semibold mb-4 text-center">
            তোমার আবেদন আমরা পেয়েছি। তথ্য যাচাই বাচাই এর পর তুমি নির্বাচিত হলে
            আমরা তোমাকে {applicantData.mobile} নাম্বারে SMS এর মাধ্যমে জানিয়ে
            দিব। তোমার জন্য শুভ কামনা।
          </h3>
          <h2 className="text-3xl font-bold mb-4 text-center">
            তোমার আবেদন আইডি {applicantData.id}
          </h2>
          <div className="flex w-full justify-center my-2">
            <Link
              className="btn btn-primary text-white px-5 py-2"
              to={`/meritorious-student/print/${applicantData.id}`}
            >
              তোমার আবেদনপত্র প্রিন্ট অথবা ডাউনলোড কর
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default MeritoriousStudent;
