import React, { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../utils/axios.config";
import { useEffect } from "react";
import logo from "../../assets/image/logo.png";
import { baseUrl } from "../../utils/url";
import signature from "../../assets/signature.png";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import "./MeritoriousStudentApplicationPrint.css";

function MeritoriousStudentApplicationPrint() {
  const [applicationInfo, setApplicationInfo] = useState(null);
  const { id } = useParams();

  const handleGetApplicationInfo = async () => {
    const { data } = await api.get(`meritorious-student/${id}`);

    if (data.success) {
      setApplicationInfo(data.data);
    }
  };
  useEffect(() => {
    handleGetApplicationInfo();
  }, [id]);

  const {
    id: applicationId,
    name,
    fatherName,
    motherName,
    mobile,

    schoolOrCollegeName,
    lastExam,
    profileImageUrl,
    result,
    union,
    village,
    lastExamDivision,
    passingYear,
    chancedInstituteName,
    chancedSubjectOrUnitName,
  } = applicationInfo || {};

  const handlePrintDocument = () => {
    const pdfWidth = 210; // A4 width in millimeters
    const pdfHeight = 297; // A4 height in millimeters

    // Set the dimensions of the applicationPage div to A4 size
    const applicationPage = document.getElementById("applicationPage");
    applicationPage.style.width = `${pdfWidth}mm`;
    applicationPage.style.height = `${pdfHeight}mm`;

    htmlToImage
      .toPng(applicationPage, { quality: 1.0 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";

        const pdf = new jsPDF({
          unit: "mm",
          format: [pdfWidth, pdfHeight], // Set A4 size
        });

        const imgProps = pdf.getImageProperties(dataUrl);
        const aspectRatio = imgProps.width / imgProps.height;

        // Calculate the height based on the aspect ratio to maintain proportions
        const imgWidth = pdfWidth;
        const imgHeight = imgWidth / aspectRatio;

        pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("application.pdf");

        // Reset the dimensions of the applicationPage div after capturing the image
        applicationPage.style.width = "";
        applicationPage.style.height = "";
      });
  };

  return (
    <>
      <div className="flex justify-center my-5 control-group">
        <button
          className="btn bg-secondary text-white border-0 mx-2"
          onClick={handlePrintDocument}
        >
          ডাউনলোড
        </button>
        <button
          onClick={() => window.print()}
          className="btn bg-red-500 text-white border-0 mx-2"
        >
          প্রিন্ট
        </button>
      </div>
      <div id="applicationPage" className="mb-10 ">
        {!applicationInfo ? (
          <div className="h-[60vh] w-full flex justify-center items-center">
            <div>
              <h1 className="text-3xl font-bold text-red-500 text-center">
                দুঃখিত! তোমার আবেদন খুঁজে পাওয়া যাই নি
              </h1>
              <h2 className="text-xl font-semibold text-center text-primary">
                তোমার সার্চকৃত আবেদন নং- {id}
              </h2>
              <h3 className="text-lg font-medium text-center text-primary">
                দয়া করে সঠিক আবেদন নম্বর দিয়ে চেষ্টা কর
              </h3>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center w-[210mm] h-[305mm] m-auto relative">
            <div className=" h-[300mm]  bg-contain  w-[205mm] relative  rounded  p-10 border-2 border-orange-500">
              <div className="flex bg-orange-500 mb-5 p-2 items-center rounded">
                <img src={logo} alt="logo" className="w-20 h-20" />
                <h2 className="text-2xl font-bold text-white mx-5">
                  পূর্ব বড়ুয়া তরুণ সংঘ মেধাবী শিক্ষার্থী সংবর্ধনা - ২০২৩
                </h2>
              </div>
              <div className="border-2 p-5 rounded">
                <div className="bg-base-200">
                  <h3 className="text-xl text-center mb-5  ">আবেদনপত্র</h3>
                </div>
                <div className="flex w-full justify-end absolute top-[30] right-16">
                  <div className="w-[120px]">
                    <img
                      className="h-[150px] w-[120px]"
                      src={`${baseUrl}${profileImageUrl}`}
                      alt="profile"
                    />
                  </div>
                </div>
                <div className="text-small">
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">আবেদন আইডি</p>

                    <p>: {applicationId}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">নাম</p>

                    <p>: {name}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">পিতার নাম</p>

                    <p>: {fatherName}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">মাতার নাম</p>

                    <p>: {motherName}</p>
                  </div>

                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">মোবাইল নং</p>

                    <p>: {mobile}</p>
                  </div>

                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">ইউনিয়ন</p>

                    <p>: {union}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">গ্রাম</p>

                    <p>: {village}</p>
                  </div>

                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">প্রতিষ্ঠানের নাম</p>

                    <p>: {schoolOrCollegeName}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">
                      যে পরীক্ষার ফলাফলের জন্য আবেদন
                    </p>

                    <p>: {lastExam === "ssc" ? "এসএসসি" : "এইচএসসি"}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">পাশের সন</p>

                    <p>: {passingYear}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">বিভাগ</p>

                    <p>: {lastExamDivision.toUpperCase()}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="w-64 font-bold">রেজাল্ট</p>

                    <p>: {result}</p>
                  </div>
                  {chancedInstituteName && (
                    <div className="flex justify-start items-center">
                      <p className="w-64 font-bold">
                        চান্স প্রাপ্ত প্রতিষ্ঠানের নাম
                      </p>

                      <p>: {chancedInstituteName}</p>
                    </div>
                  )}
                  {chancedSubjectOrUnitName && (
                    <div className="flex justify-start items-center">
                      <p className="w-64 font-bold">বিভাগ/ইউনিট নাম</p>

                      <p>: {chancedSubjectOrUnitName}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="border-2 my-2 p-5 rounded-lg flex items-center justify-between max-h-32">
                <div className="text-sm col-span-2">
                  <h3 className="font-bold text-lg">নির্দেশনা:</h3>
                  <p>১। আবেদনপত্রের প্রিন্ট কপি অবশ্যই সংগ্রহ করতে হবে।</p>
                  <p>
                    ২। ফলাফল এসএমএস এর মাধ্যমে জানানো হবে বিধায় মোবাইলের এসএমএস
                    যাচাই করতে হবে।
                  </p>
                  <p>
                    ৩। একই শিক্ষার্থীর জন্য একাধিক আবেদন করলে তার আবেদন বাতিল
                    করা হবে।
                  </p>
                </div>
                <QRCode
                  size={256}
                  className="w-16"
                  value={`Purbo Borua Torun Songho Education Scholarship.\nApplication ID: ${applicationId}\nMobile: ${mobile}\n`}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <div className="bg-white p-4 w-96  absolute bottom-20 right-0">
                <div className="mb-4 flex justify-center">
                  <img
                    src={signature}
                    alt="Signature"
                    className="w-32 h-auto"
                  />
                </div>
                <div className="mb-2">
                  <p className="text-lg font-bold text-center">
                    মোঃ সায়হান সৈকত
                  </p>
                  <p className=" text-center">সভাপতি</p>
                  <p className=" text-center">পূর্ব বড়ুয়া তরুণ সংঘ</p>
                  <p className=" text-center">সদর, লালমনিরহাট।</p>
                </div>
                <p className="text-sm font-semibold text-center">
                  মোবাইলঃ 01744769331
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MeritoriousStudentApplicationPrint;
