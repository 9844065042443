import React from "react";
import winterClothe from "../../assets/image/winter-clothe.jpeg";
import food from "../../assets/image/food.jpeg";
import tree from "../../assets/image/tree.jpeg";
import education from "../../assets/image/education.jpeg";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  const activities = [
    { id: 1, name: "শীতবস্ত্র বিতরণ", image: winterClothe },
    { id: 2, name: "শিক্ষা বৃত্তি এবং সহায়তা", image: education },
    { id: 3, name: "খাদ্য সহায়তা", image: food },
    { id: 4, name: "বনায়ন কর্মসূচি", image: tree },
  ];
  return (
    <div className="flex justify-center">
      <div>
        <h3 className="text-3xl font-bold text-center text-primary mt-10 mb-5">
          আমাদের কার্যক্রম সমূহ
        </h3>
        <div className="md:grid grid-cols-4 gap-10">
          {activities?.map((activity) => (
            <div
              data-aos="zoom-in-up"
              key={activity.id}
              className="card max-w-sm image-full m-2 md:m-0"
            >
              <figure>
                <img src={activity?.image} alt="activity" />
              </figure>
              <div className="card-body flex items-center justify-center">
                <h2 className="card-title text-white">{activity?.name}</h2>
                <Link
                  to="/donation"
                  className="btn btn-secondary w-44 text-white"
                >
                  অনুদান
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
