import React from 'react';

const DashboardIndex = () => {
    return (
        <div>
            <p className='font-bold text-secondary text-3xl text-center'>আপনাকে এডমিন ড্যাশবোর্ডে স্বাগতম</p>
        </div>
    );
};

export default DashboardIndex;