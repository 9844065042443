import React from 'react';
import { Link } from 'react-router-dom';

const ClothProject = () => {
    return (
        <div className='md:grid md:grid-cols-2 place-content-center place-items-center m-2 md:m-5'>
            <img src="https://www.bracu.ac.bd/sites/default/files/bulletin/2016/January16/phr%201.jpg" alt="education" />
            <div>
                <p className='text-xl font-bold text-secondary'><span>৳ ৯০০ -</span> (১ টি পরিবারের জন্য শীতবস্ত্র)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৯০০০ -</span> (১০ টি পরিবারের জন্য শীতবস্ত্র)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৪৫০০০ -</span> (৫০ টি পরিবারের জন্য শীতবস্ত্র)</p>
                <br />
                <br />
                <p className='text-xl font-bold text-secondary'><span>৳ ৪৫০ -</span> (১ টি শিশুর জন্য শীতবস্ত্র)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৯০০০ -</span> (২০ টি শিশুর জন্য শীতবস্ত্র)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৩৬০০০ -</span> (৮০ টি শিশুর জন্য শীতবস্ত্র)</p>

                <br />
                <p className='font-bold text-red-500'>
                    # এছাড়াও আপনি যেকোনো পরিমান অর্থ শীতবস্ত্র প্রকল্পের জন্য ডোনেট করতে পারেন।
                </p>
                <br />
                <Link to='/donation' className="btn bg-secondary text-white border-0">Donation</Link>
            </div>
        </div>
    );
};

export default ClothProject;