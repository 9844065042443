import React, { useState, useEffect } from "react";
import axios from "axios";
import districts from "../../data/district.json";
import upazilas from "../../data/upazila.json";
import unions from "../../data/union.json";
import donorPlaceholder from "../../assets/image/noimage.gif";
import api from "../../utils/axios.config";
import toast from "react-hot-toast";
import { FaTrash } from "react-icons/fa";

const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const ManageBloodDonor = () => {
  const [donors, setDonors] = useState([]);
  const [search, setSearch] = useState("");
  const [district, setDistrict] = useState("");
  const [upazila, setUpazila] = useState("");
  const [union, setUnion] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [bloodGroup, setBloodGroup] = useState("");

  useEffect(() => {
    fetchDonors();
  }, [search, district, upazila, union, page, bloodGroup]);

  const fetchDonors = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://api.pbtsbd.org/api/v1/blood-donor",
        {
          params: {
            search,
            district: getDistrictById(district),
            upazila: getUpazilaById(upazila),
            union: getUnionById(union),
            bloodGroup,
            page,
            limit: 10,
          },
        }
      );
      setDonors(response.data.data);
      setTotalPages(Math.ceil(response.data.total / 10));
    } catch (error) {
      console.error("Error fetching donors:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => setSearch(e.target.value);
  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
    setUpazila(""); // reset upazila when district changes
    setUnion(""); // reset union when upazila changes
  };
  const handleUpazilaChange = (e) => {
    setUpazila(e.target.value);
    setUnion(""); // reset union when upazila changes
  };
  const handleUnionChange = (e) => setUnion(e.target.value);
  const handlePageChange = (newPage) => setPage(newPage);

  const getDistrictById = (id) => {
    const district = districts.find((district) => district.id === id);
    return district ? district.bn_name : null;
  };

  const getUpazilaById = (id) => {
    const upazila = upazilas.find((upazila) => upazila.id === id);
    return upazila ? upazila.bn_name : null;
  };

  const getUnionById = (id) => {
    const union = unions.find((union) => union.id === id);
    return union ? union.bn_name : null;
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await api.delete(`/blood-donor/${id}`);
      toast.success("Blood donor successfully deleted");
      fetchDonors();
    } catch (error) {
      console.error("Error deleting donor:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Manage Blood Donors</h1>
      <div className="mb-4 flex flex-col md:flex-row gap-4">
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          className="input input-bordered flex-grow"
        />
        <select
          onChange={(e) => setBloodGroup(e.target.value)}
          className="select select-bordered flex-grow"
        >
          <option value="">Select Blood Group</option>
          {bloodGroups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </select>
        <select
          value={district}
          onChange={handleDistrictChange}
          className="select select-bordered flex-grow"
        >
          <option value="">Select District</option>
          {districts.map((dist) => (
            <option key={dist.id} value={dist.id}>
              {dist.bn_name}
            </option>
          ))}
        </select>
        <select
          value={upazila}
          onChange={handleUpazilaChange}
          className="select select-bordered flex-grow"
        >
          <option value="">Select Upazila</option>
          {upazilas
            .filter((upa) => upa.district_id === district)
            .map((upa) => (
              <option key={upa.id} value={upa.id}>
                {upa.bn_name}
              </option>
            ))}
        </select>
        <select
          value={union}
          onChange={handleUnionChange}
          className="select select-bordered flex-grow"
        >
          <option value="">Select Union</option>
          {unions
            .filter((uni) => uni.upazilla_id === upazila)
            .map((uni) => (
              <option key={uni.id} value={uni.id}>
                {uni.bn_name}
              </option>
            ))}
        </select>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th>Profile Image</th>
                <th>Name</th>
                <th>Blood Group</th>
                <th>Mobile</th>
                <th>District</th>
                <th>Upazila</th>
                <th>Union</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {donors.map((donor) => (
                <tr className="pb-5" key={donor._id}>
                  <td>
                    <img
                      src={
                        `https://api.pbtsbd.org/${donor.profileImage}` ||
                        donorPlaceholder
                      }
                      onError={(e) => (e.target.src = donorPlaceholder)}
                      alt={donor.name}
                      className="w-16 h-16 object-cover rounded-full"
                    />
                  </td>
                  <td>{donor.name}</td>
                  <td className="text-red-500 font-bold">{donor.bloodGroup}</td>
                  <td>{donor.mobile}</td>
                  <td>{donor.district}</td>
                  <td>{donor.upazila}</td>
                  <td>{donor.union}</td>
                  <td>
                    <button
                      onClick={() => handleDelete(donor._id)}
                      className="btn btn-danger"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-center mt-4">
            <div className="btn-group">
              {[...Array(totalPages).keys()].map((num) => (
                <button
                  key={num}
                  className={`btn ${page === num + 1 ? "btn-active" : ""}`}
                  onClick={() => handlePageChange(num + 1)}
                >
                  {num + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageBloodDonor;
