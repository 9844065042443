import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import Loading from '../Shared/Loading';

const RequireAuth = ({ children }) => {
  let location = useLocation();
  const [user, isLoading, error] = useUser();

  if (isLoading) {
    return <Loading />;
  }

  if (!user?.success || error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
