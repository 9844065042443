import { useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../utils/axios.config";
import Loading from "../Shared/Loading";
import { baseUrl } from "../../utils/url";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function MaritoriousStudentApplication() {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["allApplication"],
    queryFn: async () => await api.get("meritorious-student"),
  });
  const applications = data?.data?.data;

  const changeRole = async (id, info) => {
    try {
      const { data } = await api.patch(`meritorious-student/${id}`, info);

      if (data.success) {
        toast.success(data.message);
        refetch();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full grid place-content-center">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        সকল আবেদন
      </p>
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
            <tr>
              <th className="text-center">নাম ও ঠিকানা</th>
              <th className="text-center">
                পরীক্ষার নাম, প্রতিষ্ঠান ও রেজাল্ট
              </th>
              <th className="text-center">চান্স প্রাপ্ত প্রতিষ্ঠানের তথ্য</th>
              <th className="text-center">যোগাযোগ</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {applications?.map((application) => (
              <tr key={application?._id}>
                <td>
                  <div class="flex items-center space-x-3">
                    <div class="avatar">
                      <div class="mask mask-squircle w-12 h-12">
                        <img
                          src={`${baseUrl}${application?.profileImageUrl}`}
                          alt="Avatar Tailwind CSS Component"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="font-bold">{application?.name}</div>
                      <div class="text-sm opacity-50">
                        {`${application?.village},${application?.union}`}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {application?.lastExam === "ssc" ? "এসএসসি" : "এইচএসসি"}
                  <br />
                  {application?.schoolOrCollegeName}
                  <br />
                  {application?.result}
                </td>
                <td>
                  {application?.chancedInstituteName}
                  <br />
                  {application?.chancedSubjectOrUnitName}
                </td>
                <td>
                  {application?.mobile}
                  <br />
                  {application?.email}
                </td>
                <td>
                  <div className="flex justify-center my-2">
                    <button
                      onClick={() =>
                        navigate(
                          `/meritorious-student/print/${application?.id}`
                        )
                      }
                      className="btn btn-info text-white"
                    >
                      আবেদনপত্র দেখুন
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        changeRole(application?.id, { status: "pending" })
                      }
                      class={`mx-2 ${
                        application?.status === "pending"
                          ? "btn btn-primary p-1 text-white border-0"
                          : "btn bg-white text-primary border-primary border-2"
                      }`}
                    >
                      Pending
                    </button>
                    <button
                      onClick={() =>
                        changeRole(application?.id, { status: "accepted" })
                      }
                      class={`mx-2 ${
                        application?.status === "accepted"
                          ? "btn bg-secondary p-1 text-white border-0"
                          : "btn bg-white text-primary border-green-500 border-2"
                      }`}
                    >
                      Accepted
                    </button>
                    <button
                      onClick={() =>
                        changeRole(application?.id, { status: "rejected" })
                      }
                      class={`mx-2 ${
                        application?.status === "rejected"
                          ? "btn bg-red-500 p-1 text-white border-0"
                          : "btn bg-white text-red-500 border-red-500 border-2"
                      }`}
                    >
                      Rejected
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MaritoriousStudentApplication;
