import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import About from "../Pages/About/About";
import BloodDonors from "../Pages/BloodDonors/BloodDonors";
import Donation from "../Pages/Donation/Donation";
import Home from "../Pages/Home/Home";
import Join from "../Pages/Join/Join";
import Footer from "../Pages/Shared/Footer";
import Navbar from "../Pages/Shared/Navbar";
import NotFound from "../Pages/Shared/NotFound";
import Marquee from "react-fast-marquee";
import PublicVercityStudents from "../Pages/List/PublicVercityStudents";
import TalentStudents from "../Pages/List/TalentStudents";
import PhotoGallery from "../Pages/Media/PhotoGallery";
import VideoGallery from "../Pages/Media/VideoGallery";
import LatestNotice from "../Pages/Media/LatestNotice";
import AdviserMember from "../Pages/About/AdviserMember";
import Member from "../Pages/About/Member";
import Rules from "../Pages/About/Rules";
import VisionMission from "../Pages/About/VisionMission";
import EducationProject from "../Pages/Projects/EducationProject";
import FoodProject from "../Pages/Projects/FoodProject";
import ClothProject from "../Pages/Projects/ClothProject";
import TalentStudentAwardProject from "../Pages/Projects/TalentStudentAwardProject";
import QuestionsAnswers from "../Pages/About/QuestionsAnswers";
import Header from "../Pages/Shared/Header";
import Library from "../Pages/Projects/Library";
import NewspaperNews from "../Pages/Media/NewspaperNews";
import TreePlantation from "../Pages/Projects/TreePlantation";
import AnimalSacrifice from "../Pages/Projects/AnimalSacrifice";
import SponsorChild from "../Pages/SponsorChild/SponsorChild";
import GetPrize from "../Pages/Media/GetPrize";
import GeneralMember from "../Pages/Registration/GeneralMember";
import AboutDonation from "../Pages/Donation/AboutDonation";
import BloodDonorRegistration from "../Pages/Registration/BloodDonorRegistration";
import Dashboard from "../Pages/Dashboard/Dashboard";
import DashboardIndex from "../Pages/Dashboard/DashboardIndex";
import Login from "../Pages/Authentication/Login";
import VolunteerList from "../Pages/List/VolunteerList";
import AddRecentActivity from "../Pages/Dashboard/AddRecentActivity";
import Signup from "../Pages/Authentication/Signup";
import AllUser from "../Pages/Dashboard/AllUser";
import RequireAuth from "../Pages/Authentication/RequireAuth";
import RequireAdmin from "../Pages/Authentication/RequireAdmin";
import RecentActivities from "../Pages/Dashboard/RecentActivities";
import AddAdviserMessage from "../Pages/Dashboard/AddAdviserMessage";
import AdviserMessage from "../Pages/Dashboard/AdviserMessage";
import AddDonorPartner from "../Pages/Dashboard/AddDonorPartner";
import DonorPartner from "../Pages/Dashboard/DonorPartner";
import AddProvideHelp from "../Pages/Dashboard/AddProvideHelp";
import ProvideHelp from "../Pages/Dashboard/ProvideHelp";
import SponsorInfo from "../Pages/Donation/SponsorInfo";
import Freelancer from "../Pages/List/Freelancer";
import TrainingCenter from "../Pages/List/TrainingCenter";
import DonorPartnerList from "../Pages/List/DonorPartnerList";
import ScholarshipApplication from "../Pages/Media/ScholarshipApplication";
import PrintApplication from "../Pages/Media/PrintApplication";
import AllScholarshipApplication from "../Pages/Dashboard/AllScholarshipApplication";
import AddMember from "../Pages/Dashboard/AddMember";
import AddPublicUniversityStudent from "../Pages/Dashboard/AddPublicUniversityStudent";
import AllMember from "../Pages/Dashboard/AllMember";
import AllUniversityStudent from "../Pages/Dashboard/AllUniversityStudent";
import MeritoriousStudent from "../Pages/MeritoriousStudent/MeritoriousStudent";
import MeritoriousStudentApplicationPrint from "../Pages/MeritoriousStudent/MeritoriousStudentApplicationPrint";
import MaritoriousStudentApplication from "../Pages/Dashboard/MaritoriousStudentApplication";
import AddHeaderBanner from "../Pages/Dashboard/AddHeaderBanner";
import AddedHeaderBanner from "../Pages/Dashboard/AddedHeaderBanner";
import Picnic from "../Pages/Picnic/Picnic";
import PicnicApplication from "../Pages/Dashboard/PicnicApplication";
import AddPresidentMessage from "../Pages/Dashboard/AddPresidentMessage";
import PresidentMessage from "../Pages/Dashboard/PresidentMessage";
import AddNotice from "../Pages/Dashboard/AddNotice";
import ManageNotices from "../Pages/Dashboard/ManageNotices";
import Committee from "../Pages/About/Committee";
import api from "../utils/axios.config";
import BloodDonor from "../Pages/BloodDonor/BloodDonor";
import AddBloodDonor from "../Pages/Dashboard/AddBloodDonor";
import ManageBloodDonors from "../Pages/Dashboard/ManageBloodDonor";
const Index = () => {
  const [notices, setNotices] = useState([]);

  const fetchNotices = async () => {
    const { data } = await api.get("/notice");
    setNotices(data.data);
  };

  useEffect(() => {
    fetchNotices();
  }, []);
  const location = useLocation();
  const { pathname } = location;

  const shouldHideHeaderFooter =
    pathname.startsWith("/scholarship-application/") ||
    pathname.startsWith("/meritorious-student/print/");

  return (
    <>
      {!shouldHideHeaderFooter && (
        <>
          <Header />
          <Navbar />
          <div className="flex items-center sticky z-30 mb-10 mt-2">
            <div className="bg-secondary text-lg px-5 py-2 text-white">
              <p>সর্বশেষ:</p>
            </div>
            <Marquee
              className="text-white text-lg font-bold py-2  bg-primary"
              gradient={false}
            >
              {notices
                ?.slice()
                .reverse()
                .map((notice) => (
                  <Link
                    className="mr-12"
                    key={notice}
                    to="/media/latest-notice"
                  >
                    {notice.title}
                  </Link>
                ))}
            </Marquee>
          </div>
        </>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sponsor-child" element={<SponsorChild />} />
        <Route path="/about" element={<About />} />
        <Route path="/adviser" element={<AdviserMember />} />
        <Route path="/member" element={<Member />} />
        <Route path="/committee" element={<Committee />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/vision-mission" element={<VisionMission />} />
        <Route path="/questions-answers" element={<QuestionsAnswers />} />
        <Route path="/education-project" element={<EducationProject />} />
        <Route path="/library-project" element={<Library />} />
        <Route path="/picnic" element={<Picnic />} />
        <Route path="/tree-plantation-project" element={<TreePlantation />} />
        <Route path="/animal-sacrifice-project" element={<AnimalSacrifice />} />
        <Route path="/food-project" element={<FoodProject />} />
        <Route path="/cloth-project" element={<ClothProject />} />
        <Route
          path="/talent-student-award"
          element={<TalentStudentAwardProject />}
        />
        <Route path="/donation" element={<Donation />} />
        <Route path="/blood-donor" element={<BloodDonor />} />
        <Route path="/sponsor-info" element={<SponsorInfo />} />
        <Route path="/about-donation" element={<AboutDonation />} />
        <Route path="/join" element={<Join />} />
        <Route path="/blood-donors" element={<BloodDonors />} />
        <Route path="/blood-donor/register" element={<AddBloodDonor />} />
        <Route
          path="/public-university-students"
          element={<PublicVercityStudents />}
        />
        <Route path="/talent-students" element={<TalentStudents />} />
        <Route path="/volunteer-list" element={<VolunteerList />} />
        <Route path="/freelancer" element={<Freelancer />} />
        <Route path="/training-center" element={<TrainingCenter />} />
        <Route path="/donor-partner" element={<DonorPartnerList />} />

        <Route path="/media/photo-gallery" element={<PhotoGallery />} />
        <Route path="/media/video-gallery" element={<VideoGallery />} />
        <Route path="/media/latest-notice" element={<LatestNotice />} />
        <Route path="/media/newspaper-news" element={<NewspaperNews />} />
        <Route path="/media/get-prize" element={<GetPrize />} />
        <Route
          path="/scholarship-application"
          element={<ScholarshipApplication />}
        />
        <Route path="/meritorious-student" element={<MeritoriousStudent />} />
        <Route
          path="/meritorious-student/print/:id"
          element={<MeritoriousStudentApplicationPrint />}
        />
        <Route
          path="/scholarship-application/:id"
          element={<PrintApplication />}
        />
        <Route path="/register/general-member" element={<GeneralMember />} />
        <Route path="/register/general-member" element={<GeneralMember />} />
        <Route
          path="/register/blood-donor"
          element={<BloodDonorRegistration />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route index element={<DashboardIndex />} />
          <Route
            path="add-activity"
            element={
              <RequireAdmin>
                <AddRecentActivity />
              </RequireAdmin>
            }
          />
          <Route
            path="picnic/application"
            element={
              <RequireAdmin>
                <PicnicApplication />
              </RequireAdmin>
            }
          />
          <Route
            path="activities"
            element={
              <RequireAdmin>
                <RecentActivities />
              </RequireAdmin>
            }
          />
          <Route
            path="add-header-banner"
            element={
              <RequireAdmin>
                <AddHeaderBanner />
              </RequireAdmin>
            }
          />
          <Route
            path="added-header-banner"
            element={
              <RequireAdmin>
                <AddedHeaderBanner />
              </RequireAdmin>
            }
          />
          <Route
            path="add-notice"
            element={
              <RequireAdmin>
                <AddNotice />
              </RequireAdmin>
            }
          />
          <Route
            path="manage-notices"
            element={
              <RequireAdmin>
                <ManageNotices />
              </RequireAdmin>
            }
          />
          <Route
            path="add-blood-donor"
            element={
              <RequireAdmin>
                <AddBloodDonor />
              </RequireAdmin>
            }
          />
          <Route
            path="manage-blood-donor"
            element={
              <RequireAdmin>
                <ManageBloodDonors />
              </RequireAdmin>
            }
          />
          <Route
            path="add-member"
            element={
              <RequireAdmin>
                <AddMember />
              </RequireAdmin>
            }
          />
          <Route
            path="all-member"
            element={
              <RequireAdmin>
                <AllMember />
              </RequireAdmin>
            }
          />
          <Route
            path="add-public-university-student"
            element={
              <RequireAdmin>
                <AddPublicUniversityStudent />
              </RequireAdmin>
            }
          />
          <Route
            path="all-public-university-student"
            element={
              <RequireAdmin>
                <AllUniversityStudent />
              </RequireAdmin>
            }
          />
          <Route
            path="all-user"
            element={
              <RequireAdmin>
                <AllUser />
              </RequireAdmin>
            }
          />
          <Route
            path="scholarship-application"
            element={
              <RequireAdmin>
                <AllScholarshipApplication />
              </RequireAdmin>
            }
          />
          <Route
            path="meritorious-student"
            element={
              <RequireAdmin>
                <MaritoriousStudentApplication />
              </RequireAdmin>
            }
          />
          <Route
            path="add-adviser-message"
            element={
              <RequireAdmin>
                <AddAdviserMessage />
              </RequireAdmin>
            }
          />
          <Route
            path="adviser-message"
            element={
              <RequireAdmin>
                <AdviserMessage />
              </RequireAdmin>
            }
          />
          <Route
            path="add-president-message"
            element={
              <RequireAdmin>
                <AddPresidentMessage />
              </RequireAdmin>
            }
          />
          <Route
            path="president-message"
            element={
              <RequireAdmin>
                <PresidentMessage />
              </RequireAdmin>
            }
          />
          <Route
            path="add-donor-partner"
            element={
              <RequireAdmin>
                <AddDonorPartner />
              </RequireAdmin>
            }
          />
          <Route
            path="donor-partner"
            element={
              <RequireAdmin>
                <DonorPartner />
              </RequireAdmin>
            }
          />
          <Route
            path="add-provide-help"
            element={
              <RequireAdmin>
                <AddProvideHelp />
              </RequireAdmin>
            }
          />
          <Route
            path="provide-help"
            element={
              <RequireAdmin>
                <ProvideHelp />
              </RequireAdmin>
            }
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>

      {!shouldHideHeaderFooter && <Footer />}
    </>
  );
};

export default Index;
