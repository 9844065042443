import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import Loading from "../Shared/Loading";

function AddNotice() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async ({ files, title }) => {
    setIsLoading(true);

    const formData = new FormData();
    // Convert FileList to array and append each file to the formData
    Array.from(files).forEach((file) => {
      formData.append("attachments", file);
    });
    formData.append("title", title);

    try {
      const { data } = await api.post("/notice", formData);

      if (data.success) {
        toast.success(data.message);
        reset();
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error ?? error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div>
          <label className="label">
            <span className="label-text">Title *</span>
          </label>
          <input
            {...register("title", { required: "Title is required" })}
            type="text"
            placeholder="Enter the title"
            className="input input-bordered w-full max-w-xs"
          />
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.title && errors.title.message}
            </span>
          </label>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Attachments *</span>
          </label>
          <input
            type="file"
            multiple
            {...register("files", { required: "Attachments are required" })}
            className="file-input file-input-bordered file-input-primary w-full max-w-xs"
          />
          <label className="label">
            <span className="label-text-alt text-red-500">
              {errors.files && errors.files.message}
            </span>
          </label>
        </div>
      </div>
      <div className="mt-10">
        <input
          className="btn btn-primary text-white w-44"
          value={"Add Notice"}
          type="submit"
        />
      </div>
    </form>
  );
}

export default AddNotice;
