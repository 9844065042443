import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import Loading from "../Shared/Loading";

function AddPublicUniversityStudent() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async ({
    departmentName,
    address,
    file,
    university,
    mobile,
    name,
    email,
    facebookUrl,
    whatsappUrl,
  }) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", file[0]);
    formData.append("name", name);
    formData.append("university", university);
    formData.append("departmentName", departmentName);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("facebookUrl", facebookUrl);
    formData.append("whatsappUrl", whatsappUrl);
    formData.append("address", address);
    try {
      const { data } = await api.post("university-student/create", formData);
      if (data.success) {
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="md:mx-10 mx-2">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        পাবলিক বিশ্ববিদ্যালয়ের শিক্ষার্থী যুক্ত করুন
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
          <div>
            <label class="label">
              <span class="label-text">ছবি নির্বাচন করুন *</span>
            </label>
            <input
              type="file"
              {...register("file", { required: "ছবি আবশ্যক" })}
              class="file-input file-input-bordered file-input-primary w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.file && errors.file.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">নাম *</span>
            </label>
            <input
              {...register("name", { required: "নাম আবশ্যক" })}
              type="text"
              placeholder="নাম লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.name && errors.name.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ইমেইল *</span>
            </label>
            <input
              {...register("email", { required: "ইমেইল আবশ্যক" })}
              type="text"
              placeholder="ইমেইল লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.email && errors.email.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">বিশ্ববিদ্যালয়ের নাম ও ঠিকানা *</span>
            </label>
            <input
              {...register("university", {
                required: "বিশ্ববিদ্যালয়ের নাম ও ঠিকানা আবশ্যক",
              })}
              type="text"
              placeholder="বিশ্ববিদ্যালয়ের নাম ও ঠিকানা লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.university && errors.university.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">বিভাগের নাম *</span>
            </label>
            <input
              {...register("departmentName", {
                required: "বিভাগের নাম আবশ্যক",
              })}
              type="text"
              placeholder="বিভাগের নাম লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.departmentName && errors.departmentName.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ফেসবুক প্রোফাইল লিংক</span>
            </label>
            <input
              {...register("facebookUrl")}
              type="text"
              placeholder="ফেসবুক প্রোফাইল লিংক লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.facebookUrl && errors.facebookUrl.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">হোয়াটসএ্যাপ প্রোফাইল লিংক</span>
            </label>
            <input
              {...register("whatsappUrl")}
              type="text"
              placeholder="হোয়াটসএ্যাপ প্রোফাইল লিংক লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.whatsappUrl && errors.whatsappUrl.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">মোবাইল নাম্বার *</span>
            </label>
            <input
              {...register("mobile", { required: "মোবাইল নাম্বার আবশ্যক" })}
              type="text"
              placeholder="মোবাইল নাম্বার লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.mobile && errors.mobile.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ঠিকানা *</span>
            </label>
            <input
              {...register("address", { required: "ঠিকানা আবশ্যক" })}
              type="text"
              placeholder="ঠিকানা লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.address && errors.address.message}
              </span>
            </label>
          </div>
        </div>
        <div className="w-full flex justify-center mt-10">
          <input
            className="btn btn-primary text-white w-44"
            value={"যুক্ত করুন"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}

export default AddPublicUniversityStudent;
