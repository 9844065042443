import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";

const PhotoGallery = () => {
  const [activities, setActivities] = useState([]);

  const handleGetActivities = async () => {
    const { data } = await api.get("recent-activities/get");

    if (data.success && data.data.activities.length) {
      setActivities(data?.data?.activities);
    }
  };

  useEffect(() => {
    handleGetActivities();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    touchMove: true,
    arrows: true,
  };

  return (
    <div className="mb-10">
      <Slider {...settings}>
        {activities.map((activity, index) => (
          <div key={index}>
            <img
              src={`${baseUrl}${activity.imageUrl}`}
              alt="photoGallary"
              className="w-full max-w-xs h-48 mx-auto rounded-lg shadow-md"
            />
          </div>
        ))}
      </Slider>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5 place-content-center place-items-center m-10">
        {activities?.map((activity) => (
          <div
            key={activity._id}
            className="card w-full max-w-xs h-52 bg-base-100 shadow-xl image-full text-white"
          >
            <figure>
              <img src={`${baseUrl}${activity.imageUrl}`} alt="gallery" />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{activity?.title}</h2>
              <div className="card-actions justify-end items-end h-full">
                <button className="btn btn-primary text-white">
                  বিস্তারিত
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoGallery;
