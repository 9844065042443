import { useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../utils/axios.config";
import Loading from "../Shared/Loading";
import { baseUrl } from "../../utils/url";
import { toast } from "react-hot-toast";

function AllMember() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allMember"],
    queryFn: async () => await api.get("member"),
  });

  const members = data?.data?.data;

  const handleDeleteMember = async (id) => {
    const { data } = await api.delete(`member/${id}`);
    if (data.success) {
      toast.success(data.message);
      refetch();
    } else {
      toast.error(data.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full grid place-content-center">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        সকল কার্যকরী সদস্য
      </p>
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
            <tr>
              <th>নাম ও ঠিকানা</th>
              <th>প্রতিষ্ঠান ও পদবী</th>
              <th>যোগাযোগ</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member) => (
              <tr key={member._id}>
                <td>
                  <div class="flex items-center space-x-3">
                    <div class="avatar">
                      <div class="mask mask-squircle w-12 h-12">
                        <img
                          src={`${baseUrl}${member.imageUrl}`}
                          alt="Avatar Tailwind CSS Component"
                        />
                      </div>
                    </div>
                    <div>
                      <p>{member?.name}</p>
                      <p>{member?.address}</p>
                    </div>
                  </div>
                </td>
                <td>
                  {member?.institute}
                  <br />
                  {member?.designation}
                </td>

                <td>
                  {member.mobile}
                  <br />
                  {member?.email}
                </td>
                <td>
                  <button
                    onClick={() => handleDeleteMember(member._id)}
                    class="btn btn-link"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AllMember;
