import React, { useState, useEffect } from "react";
import axios from "axios";
import districts from "../../data/district.json";
import upazilas from "../../data/upazila.json";
import unions from "../../data/union.json";
import donorPlaceholder from "../../assets/image/noimage.gif";
import { Link } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import QRCode from "react-qr-code";
import { IoMdPerson } from "react-icons/io";
import { MdBloodtype, MdList, MdPhone } from "react-icons/md";
import logo from "../../assets/image/logo.png";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";

const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const BloodDonor = () => {
  const [donors, setDonors] = useState([]);
  const [search, setSearch] = useState("");
  const [district, setDistrict] = useState("");
  const [upazila, setUpazila] = useState("");
  const [union, setUnion] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [bloodGroup, setBloodGroup] = useState("");
  const [selectedDonor, setSelectedDonor] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to get district name by ID
  const getDistrictById = (id) => {
    const district = districts.find((district) => district.id === id);
    return district ? district.bn_name : null;
  };

  // Function to get upazila name by ID
  const getUpazilaById = (id) => {
    const upazila = upazilas.find((upazila) => upazila.id === id);
    return upazila ? upazila.bn_name : null;
  };

  // Function to get union name by ID
  const getUnionById = (id) => {
    const union = unions.find((union) => union.id === id);
    return union ? union.bn_name : null;
  };

  useEffect(() => {
    fetchDonors();
  }, [search, district, upazila, union, page, bloodGroup]);

  const fetchDonors = async () => {
    const response = await axios.get(
      "https://api.pbtsbd.org/api/v1/blood-donor",
      {
        params: {
          search,
          district: getDistrictById(district),
          upazila: getUpazilaById(upazila),
          union: getUnionById(union),
          bloodGroup,
          page,
          limit: 10,
        },
      }
    );
    setDonors(response.data.data);
    setTotalPages(Math.ceil(response.data.total / 10));
  };

  const handleSearch = (e) => setSearch(e.target.value);
  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
    setUpazila(""); // reset upazila when district changes
    setUnion(""); // reset union when upazila changes
  };
  const handleUpazilaChange = (e) => {
    setUpazila(e.target.value);
    setUnion(""); // reset union when upazila changes
  };
  const handleUnionChange = (e) => setUnion(e.target.value);
  const handlePageChange = (newPage) => setPage(newPage);

  const openModal = (donor) => {
    setSelectedDonor(donor);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedDonor(null);
    setModalIsOpen(false);
  };

  const handlePrintDocument = () => {
    const pdfWidth = 148; // A5 width in millimeters
    const pdfHeight = 210; // A5 height in millimeters

    const idCard = document.getElementById("idCard");
    const downloadButton = document.getElementById("downloadButton");
    idCard.style.width = `${pdfWidth}mm`;
    idCard.style.height = `${pdfHeight}mm`;
    downloadButton.style.display = "none"; // Hide download button

    htmlToImage.toPng(idCard, { quality: 1.0 }).then(function (dataUrl) {
      const pdf = new jsPDF({
        unit: "mm",
        format: [pdfWidth, pdfHeight],
        compress: true, // To ensure high resolution
      });

      const imgProps = pdf.getImageProperties(dataUrl);
      const aspectRatio = imgProps.width / imgProps.height;

      const imgWidth = pdfWidth;
      const imgHeight = imgWidth / aspectRatio;

      pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("id-card.pdf");

      idCard.style.width = "";
      idCard.style.height = "";
      downloadButton.style.display = ""; // Show download button again
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-5">
        <h1 className="md:text-2xl font-bold ">Blood Donors</h1>
        <Link
          className="btn bg-red-500 text-white border-0"
          to={"/blood-donor/register"}
        >
          ব্লাড ডোনার নিবন্ধন
        </Link>
      </div>
      <div className="mb-4 flex flex-col md:flex-row gap-4">
        <input
          type="text"
          placeholder="Search"
          onChange={handleSearch}
          value={search}
          className="input input-bordered flex-grow"
        />
        <select
          onChange={(e) => setBloodGroup(e.target.value)}
          className="select select-bordered flex-grow"
        >
          <option value="">Select Blood Group</option>
          {bloodGroups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </select>
        <select
          value={district}
          onChange={handleDistrictChange}
          className="select select-bordered flex-grow"
        >
          <option value="">Select District</option>
          {districts.map((dist) => (
            <option key={dist.id} value={dist.id}>
              {dist.bn_name}
            </option>
          ))}
        </select>
        <select
          value={upazila}
          onChange={handleUpazilaChange}
          className="select select-bordered flex-grow"
        >
          <option value="">Select Upazila</option>
          {upazilas
            .filter((upa) => upa.district_id === district)
            .map((upa) => (
              <option key={upa.id} value={upa.id}>
                {upa.bn_name}
              </option>
            ))}
        </select>
        <select
          value={union}
          onChange={handleUnionChange}
          className="select select-bordered flex-grow"
        >
          <option value="">Select Union</option>
          {unions
            .filter((uni) => uni.upazilla_id === upazila)
            .map((uni) => (
              <option key={uni.id} value={uni.id}>
                {uni.bn_name}
              </option>
            ))}
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
        {donors.map((donor) => (
          <div
            key={donor._id}
            className="border px-8 py-4 cursor-pointer shadow-lg flex justify-between w-full items-center my-2"
            onClick={() => openModal(donor)}
          >
            <div className="flex justify-start items-center">
              <img
                src={
                  `https://api.pbtsbd.org/${donor.profileImage}` ||
                  donorPlaceholder
                }
                onError={(e) => (e.target.src = donorPlaceholder)}
                alt={donor.name}
                className="h-32 w-28 mr-12 mb-4 object-cover"
              />
              <div>
                <div className="flex items-center mb-1">
                  <IoMdPerson size={20} className="mr-3" />
                  <h2 className="text-lg font-bold">{donor.name}</h2>
                </div>
                <div className="flex items-center mb-1">
                  <MdBloodtype size={20} className="mr-3 text-red-500" />
                  <p className="text-red-500 font-bold">
                    Blood Group: {donor.bloodGroup}
                  </p>
                </div>
                <div className="flex items-center mb-1">
                  <MdPhone size={20} className="mr-3" />
                  <p>
                    <strong>Mobile:</strong> {donor.mobile}
                  </p>
                </div>
                <div className="flex items-center mb-1">
                  <MdList size={20} className="mr-3" />
                  <p>
                    <strong>District:</strong> {donor.district}
                  </p>
                </div>
                <div className="flex items-center mb-1">
                  <MdList size={20} className="mr-3" />
                  <p>
                    <strong>Upazila:</strong> {donor.upazila}
                  </p>
                </div>
                <div className="flex items-center mb-1">
                  <MdList size={20} className="mr-3" />
                  <p>
                    <strong>Union:</strong> {donor.union}
                  </p>
                </div>
              </div>
            </div>
            <QRCode
              size={256}
              className="w-28 h-28"
              value={`Mobile: ${donor?.mobile}\nBlood Group: ${donor?.bloodGroup}`}
              viewBox={`0 0 256 256`}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <div className="btn-group">
          {[...Array(totalPages).keys()].map((num) => (
            <button
              key={num}
              className={`btn ${page === num + 1 ? "btn-active" : ""}`}
              onClick={() => handlePageChange(num + 1)}
            >
              {num + 1}
            </button>
          ))}
        </div>
      </div>
      {selectedDonor && (
        <Modal
          classNames={{
            modal: "md:w-full w-[80%]",
          }}
          open={modalIsOpen}
          onClose={closeModal}
          center
        >
          <div
            id="idCard"
            className="text-center bg-white bg-opacity-75 p-4 rounded-md"
          >
            <div className="flex flex-col justify-center items-center">
              <img src={logo} className="w-20 h-20" />
              <div className="mt-5 ml-3">
                <h2 className="text-2xl font-bold mb-2">
                  পূর্ব বড়ুয়া তরুণ সংঘ
                </h2>
                <h2 className="font-semibold mb-2">
                  লালমনিরহাট সদর, লালমনিরহাট
                </h2>
                <h2 className="text-xl text-red-500 font-bold my-5">
                  ব্লাড ডোনার আইডি কার্ড
                </h2>
              </div>
            </div>
            <img
              src={
                `https://api.pbtsbd.org/${selectedDonor.profileImage}` ||
                donorPlaceholder
              }
              onError={(e) => (e.target.src = donorPlaceholder)}
              alt={selectedDonor.name}
              className="w-32 h-32 object-cover rounded-full mx-auto mb-4"
            />
            <div className="flex justify-center">
              <div>
                <p className="text-red-500 text-4xl font-bold">
                  {selectedDonor.bloodGroup}
                </p>
                <h2 className="text-xl mb-2 font-bold">{selectedDonor.name}</h2>

                <p className="text-left">
                  <strong>Mobile:</strong> {selectedDonor.mobile}
                </p>
                <p className="text-left">
                  <strong>District:</strong> {selectedDonor.district}
                </p>
                <p className="text-left">
                  <strong>Upazila:</strong> {selectedDonor.upazila}
                </p>
                <p className="text-left">
                  <strong>Union:</strong> {selectedDonor.union}
                </p>
              </div>
            </div>
            <div className="flex items-end flex-col justify-center mt-5">
              <QRCode
                size={256}
                className="w-24 h-24 mr-3"
                value={`Mobile: ${selectedDonor.mobile}\nBlood Group: ${selectedDonor.bloodGroup}`}
                viewBox={`0 0 256 256`}
              />
              <h3 className="text-right mr-2">www.pbtsbd.org</h3>
            </div>

            <p className="mt-10">
              <small className="text-xs text-red-500 ">
                এই কার্ডটি অনলাইনে ডোনার নিবন্ধন হিসাবে সিস্টেম থেকে তৈরী কৃত
                এবং কার্ড শুধুমাত্র ব্লাড গুপের জন্য প্রস্তুত করা হয়েছে
              </small>
            </p>
            <button
              id="downloadButton"
              onClick={handlePrintDocument}
              className="btn bg-blue-500 text-white mt-4"
            >
              Download ID Card
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BloodDonor;
