import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import divisions from '../../data/division.json';
import district from '../../data/district.json';
import upazila from '../../data/upazila.json';
import union from '../../data/union.json';
import educationQualification from '../../data/educationQualification.json';
import api from '../../utils/axios.config';
import { toast } from 'react-hot-toast';
import role from '../../data/role.json';
import Loading from '../Shared/Loading';

function Signup() {
  const [districts, setDistricts] = useState([]);
  const [upazilas, setUpazilas] = useState([]);
  const [unions, setUnions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const selectedDivision = watch('divisionId');
  const selectedDistrict = watch('districtId');
  const selectedUpazila = watch('upazilaId');

  const bloodGroup = ['a+', 'a-', 'b+', 'b-', 'ab+', 'ab-', 'o+', 'o-'];

  useEffect(() => {
    const districts = district.filter(
      (dis) => dis.division_id === selectedDivision
    );
    setDistricts(districts);

    const upazilas = upazila.filter(
      (upa) => upa.district_id === selectedDistrict
    );
    setUpazilas(upazilas);

    const unions = union.filter((uni) => uni.upazilla_id === selectedUpazila);
    setUnions(unions);
  }, [selectedDivision, selectedDistrict, selectedUpazila]);

  const onSubmit = async ({
    confirmPassword,
    designation,
    districtId,
    divisionId,
    dob,
    educationQualification,
    file,
    institute,
    lastBloodDonateDate,
    mobile,
    name,
    nid,
    password,
    unionId,
    upazilaId,
    email,
    isAgreeDonateBlood,
    facebook,
    bloodGroup,
    role,
  }) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('image', file[0]);
    formData.append('name', name);
    formData.append('institute', institute);
    formData.append('designation', designation);

    formData.append('password', password);
    formData.append('confirmPassword', confirmPassword);
    formData.append('mobile', mobile);
    formData.append('email', email);
    formData.append('division', divisionId);
    formData.append('district', districtId);
    formData.append('upazila', upazilaId);
    formData.append('union', unionId);
    formData.append('facebookUrl', facebook);
    formData.append('bloodGroup', bloodGroup);
    formData.append('isAgreeDonateBlood', isAgreeDonateBlood);
    if (lastBloodDonateDate) {
      formData.append('lastBloodDonation', lastBloodDonateDate);
    }
    formData.append('educationQualification', educationQualification);
    formData.append('nid', nid);
    formData.append('dob', dob);
    formData.append('role', role);

    try {
      const { data } = await api.post('user/signup', formData);

      if (data.success) {
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="my-32 md:mx-10 mx-2">
      <p className="text-center font-bold text-2xl text-primary mb-10">
        পূর্ব বড়ুয়া তরুণ সংঘ এ আপনাকে স্বাগতম, রেজিস্টার করুন
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:grid grid-cols-2 lg:grid-cols-3 place-content-center place-items-center">
          <div>
            <label class="label">
              <span class="label-text">ছবি নির্বাচন করুন *</span>
            </label>
            <input
              type="file"
              {...register('file', { required: 'ছবি আবশ্যক' })}
              class="file-input file-input-bordered file-input-primary w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.file && errors.file.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">নাম *</span>
            </label>
            <input
              {...register('name', { required: 'নাম আবশ্যক' })}
              type="text"
              placeholder="নাম লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.name && errors.name.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ইমেইল</span>
            </label>
            <input
              {...register('email')}
              type="text"
              placeholder="ইমেইল লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.name && errors.name.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">প্রতিষ্ঠানের নাম *</span>
            </label>
            <input
              {...register('institute', {
                required: 'বর্তমানে কর্মরত প্রতিষ্ঠানের নাম আবশ্যক',
              })}
              type="text"
              placeholder="বর্তমানে কর্মরত প্রতিষ্ঠানের নাম লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.institute && errors.institute.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">পদবী *</span>
            </label>
            <input
              {...register('designation', {
                required: 'পদবী আবশ্যক',
              })}
              type="text"
              placeholder="পদবী লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.designation && errors.designation.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ফেসবুক প্রোফাইল লিংক</span>
            </label>
            <input
              {...register('facebook')}
              type="text"
              placeholder="ফেসবুক প্রোফাইল লিংক লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.facebook && errors.facebook.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">মোবাইল নাম্বার *</span>
            </label>
            <input
              {...register('mobile', { required: 'মোবাইল নাম্বার আবশ্যক' })}
              type="text"
              placeholder="মোবাইল নাম্বার লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.mobile && errors.mobile.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">বিভাগ *</span>
            </label>
            <select
              {...register('divisionId', { required: 'বিভাগ আবশ্যক' })}
              class="select select-bordered"
            >
              {divisions?.map((div) => (
                <option key={div.id} value={div.id}>
                  {div.bn_name}
                </option>
              ))}
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.divisionId && errors.divisionId.message}
              </span>
            </label>
          </div>

          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">জেলা *</span>
            </label>
            <select
              {...register('districtId', { required: 'জেলা আবশ্যক' })}
              class="select select-bordered"
            >
              {districts?.map((dis) => (
                <option key={dis.id} value={dis.id}>
                  {dis.bn_name}
                </option>
              ))}
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.districtId && errors.districtId.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">উপজেলা *</span>
            </label>
            <select
              {...register('upazilaId', { required: 'উপজেলা আবশ্যক' })}
              class="select select-bordered"
            >
              {upazilas?.map((upa) => (
                <option key={upa.id} value={upa.id}>
                  {upa.bn_name}
                </option>
              ))}
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.upazilaId && errors.upazilaId.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">ইউনিয়ন *</span>
            </label>
            <select
              {...register('unionId', { required: 'ইউনিয়ন আবশ্যক' })}
              class="select select-bordered"
            >
              {unions?.map((uni) => (
                <option key={uni.id} value={uni.id}>
                  {uni.bn_name}
                </option>
              ))}
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.unionId && errors.unionId.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">শিক্ষাগত যোগ্যতা *</span>
            </label>
            <select
              {...register('educationQualification', {
                required: 'শিক্ষাগত যোগ্যতা আবশ্যক',
              })}
              class="select select-bordered"
            >
              {educationQualification?.map((edu) => (
                <option key={edu.id} value={edu.id}>
                  {edu.name.bangla}
                </option>
              ))}
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.educationQualification &&
                  errors.educationQualification.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">পাসওয়ার্ড *</span>
            </label>
            <input
              {...register('password', { required: 'পাসওয়ার্ড আবশ্যক' })}
              type="password"
              placeholder="পাসওয়ার্ড লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.password && errors.password.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">পাসওয়ার্ড পুনরায় লিখুন *</span>
            </label>
            <input
              {...register('confirmPassword', {
                required: 'পুনরায় পাসওয়ার্ড আবশ্যক',
              })}
              type="password"
              placeholder="পুনরায় পাসওয়ার্ড লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.confirmPassword && errors.confirmPassword.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">NID *</span>
            </label>
            <input
              {...register('nid', { required: 'NID আবশ্যক' })}
              type="text"
              placeholder="NID লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.nid && errors.nid.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">জন্ম তারিখ *</span>
            </label>
            <input
              {...register('dob', { required: 'জন্ম তারিখ আবশ্যক' })}
              type="text"
              placeholder="দিন-মাস-সাল"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.dob && errors.dob.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">রক্তের গ্রুপ *</span>
            </label>
            <select
              {...register('bloodGroup', { required: 'রক্তের গ্রুপ আবশ্যক' })}
              class="select select-bordered"
            >
              {bloodGroup?.map((blood) => (
                <option key={blood} className="text-red-500" value={blood}>
                  {blood.toUpperCase()}
                </option>
              ))}
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.bloodGroup && errors.bloodGroup.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">শেষ রক্তদানের তারিখ</span>
            </label>
            <input
              {...register('lastBloodDonateDate')}
              type="text"
              placeholder="দিন-মাস-সাল"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.lastBloodDonateDate &&
                  errors.lastBloodDonateDate.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">রক্তদানে ইচ্ছুক কিনা *</span>
            </label>
            <select
              {...register('isAgreeDonateBlood', {
                required: 'রক্তদানে ইচ্ছুক কিনা আবশ্যক',
              })}
              class="select select-bordered"
            >
              <option value={true}>হ্যাঁ</option>
              <option value={false}>না</option>
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.isAgreeDonateBlood && errors.isAgreeDonateBlood.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">পদবী (ইচ্ছুক) *</span>
            </label>
            <select
              {...register('role', {
                required: 'পদবী আবশ্যক',
              })}
              class="select select-bordered"
            >
              {role?.map((rl) => (
                <option key={rl.id} value={rl.roleEn}>
                  {rl.roleBn}
                </option>
              ))}
            </select>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.role && errors.role.message}
              </span>
            </label>
          </div>
        </div>
        <div className="w-full flex justify-center mt-10">
          <input
            className="btn btn-primary text-white w-44"
            value={'সাইন আপ'}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}

export default Signup;
