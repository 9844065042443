import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from '../Shared/Loading';
import useAdmin from '../../hooks/useAdmin';

const RequireAdmin = ({ children }) => {
  let location = useLocation();
  const [user, isLoading, error] = useAdmin();

  if (isLoading) {
    return <Loading />;
  }

  if (!user?.success || error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAdmin;
