import React from "react";
import { FacebookEmbed } from "react-social-media-embed";

const VideoGallery = () => {
  const videos = [
    "https://www.facebook.com/EBYAssociation/videos/wwwebyabdcom/3349524661822518/?locale=th_TH",
    "https://www.facebook.com/100068776800017/videos/164495915743981/?__so__=permalink&locale=th_TH",
    "https://www.facebook.com/100068776800017/videos/1144922635943102?__so__=permalink&locale=th_TH",
    "https://www.facebook.com/100068776800017/videos/826957548101019?__so__=permalink&locale=th_TH",
    "https://www.facebook.com/100068776800017/videos/545488166960093?__so__=permalink&locale=th_TH",
    "https://www.facebook.com/100068776800017/videos/164782862406283?__so__=permalink&locale=th_TH",
    "https://www.facebook.com/100068776800017/videos/1741501636051986?__so__=permalink&locale=th_TH",
  ];
  return (
    <div className="m-10 md:grid grid-cols-2 lg:grid-cols-4 gap-10">
      {videos?.map((video, index) => (
        <div
          key={index}
          className="w-full max-w-xs md:flex md:justify-center h-44"
        >
          <FacebookEmbed width={300} url={video} />
        </div>
      ))}
    </div>
  );
};

export default VideoGallery;
