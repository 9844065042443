import React from "react";
import member1 from "../../assets/members/saikat.jpg";
import { BsFacebook } from "react-icons/bs";
import member01 from "../../assets/members/member/01.jpg";
import member02 from "../../assets/members/member/2.jpg";
import member03 from "../../assets/members/member/3.jpg";
import member05 from "../../assets/members/member/05.jpg";
import member06 from "../../assets/members/member/6.jpg";
import member09 from "../../assets/members/member/9.jpg";
import member12 from "../../assets/members/member/12.jpg";
import member13 from "../../assets/members/member/13.jpg";
import member14 from "../../assets/members/member/14.jpg";
import member15 from "../../assets/members/member/15.jpg";
import member17 from "../../assets/members/member/17.jpg";
import member18 from "../../assets/members/member/18.jpg";
import member20 from "../../assets/members/member/20.jpg";
import member21 from "../../assets/members/member/21.jpg";
import member22 from "../../assets/members/member/22.jpg";
import member24 from "../../assets/members/member/24.jpg";
import member27 from "../../assets/members/member/27.jpg";
import member28 from "../../assets/members/member/28.jpg";
import member29 from "../../assets/members/member/29.jpg";
import member30 from "../../assets/members/member/30.jpg";
import member31 from "../../assets/members/member/31.jpg";
import member32 from "../../assets/members/member/32.jpg";
import member33 from "../../assets/members/member/33.jpg";
import member34 from "../../assets/members/member/34.jpg";
import member35 from "../../assets/members/member/35.jpg";
import member38 from "../../assets/members/member/38.jpg";
import member39 from "../../assets/members/member/39.jpg";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const Freelancer = () => {
  const members = [
    {
      name: "মোঃ সায়হান সৈকত",
      designation: "সভাপতি",
      professionInstitute: "কুলাঘাট ইউনিয়ন ডিজিটাল সেন্টার",
      professionDesignation: "উদ্যোক্তা",
      mobile: "01744769331",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member01,
      facebookLink: "",
    },
    {
      name: "মোঃ রুহুল আমিন বাবু",
      designation: "সহঃ সভাপতি",
      professionInstitute: "বাংলাদেশ  সেনা বাহিনী",
      professionDesignation: "সেনা সদস্য",
      mobile: "01738037377",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: member02,
      facebookLink: "",
    },
    {
      name: "মোঃ জহুরুল ইসলাম",
      designation: "সহঃ সভাপতি",
      professionInstitute: "সরকারি প্রাথমিক বিদ্যালয়",
      professionDesignation: "সহকারী শিক্ষক",
      mobile: "01738039746",
      email: "member@pbtsbd.org",
      address: "চর খাটামারী ,কুলাঘাট",
      image: member03,
      facebookLink: "",
    },
    {
      name: "মোঃ রুবেল মিয়া",
      designation: "সহঃ সভাপতি",
      professionInstitute: "লালমনিরহাট সরকারি কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01765814924",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ নুরনবী ইসলাম",
      designation: "সহঃ সভাপতি",
      professionInstitute: "উত্তর বাংলা বিশ্ববিদ্যালয় কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01843797850",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member05,
      facebookLink: "",
    },
    {
      name: "মোঃ রবিউল ইসলাম",
      designation: "সাধারন সম্পাদক",
      professionInstitute: "আদিতমারী সরকারি কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01783083443",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member06,
      facebookLink: "",
    },
    {
      name: "মোঃ আহসানুল হক আদম",
      designation: "সহ: সাধারন সম্পাদক",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "01873560293",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member09,
      facebookLink: "",
    },
    {
      name: "মোঃ হাবিবুর রহমান",
      designation: "সাংগঠনিক সম্পাদক",
      professionInstitute: "উত্তর বাংলা বিশ্ববিদ্যালয় কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01770713825",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ আসাদুল হাবিব",
      designation: "সহ: সাংগঠনিক সম্পাদক",
      professionInstitute: "কারমাইকেল কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01783864368",
      email: "member@pbtsbd.org",
      address: "বাঁশপচাই ভিতরকুটি,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ নাজমুল হক",
      designation: "প্রচার ও প্রকাশনা বিষয়ক সম্পাদক",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "01740027282",
      email: "member@pbtsbd.org",
      address: "শিবেরকুটি,কুলাঘাট",
      image: member12,
      facebookLink: "",
    },
    {
      name: "মোঃ আল আমিন",
      designation: "সহঃ প্রচার ও প্রকাশনা বিষয়ক",
      professionInstitute: "উত্তর বাংলা বিশ্ববিদ্যালয় কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01860275827",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: member13,
      facebookLink: "",
    },
    {
      name: "মোঃ মোহাইমিনুল হক",
      designation: "অর্থ বিষয়ক সম্পাদক",
      professionInstitute: "কারমাইকেল কলেজ, রংপুর",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01768019453",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member14,
      facebookLink: "",
    },
    {
      name: "মোঃ মিজানুর রহমান মিন্টু",
      designation: "সহ: অর্থ বিষয়ক সম্পাদক",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "01747483416",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member15,
      facebookLink: "",
    },
    {
      name: "মোঃ রহিম বাদশা",
      designation: "দপ্তর বিষয়ক সম্পাদক",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "01737000265",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ আরিফ হাছান",
      designation: "সহঃ দপ্তর বিষয়ক সম্পাদক",
      professionInstitute: "কারমাইকেল কলেজ, রংপুর",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01794868642",
      email: "member@pbtsbd.org",
      address: "কুলাঘাট",
      image: member17,
      facebookLink: "",
    },
    {
      name: "মোঃ মোবারক হোসেন",
      designation: "আইন বিষয়ক সম্পাদক",
      professionInstitute: "ঢাকা বিশ্ববিদ্যালয়",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01924128656",
      email: "member@pbtsbd.org",
      address: "চর খাটামারী,কুলাঘাট",
      image: member18,
      facebookLink: "",
    },
    {
      name: "মোঃ উমর ফারুক",
      designation: "ত্রান ও দূযোর্গ বিষয়ক সম্পাদক",
      professionInstitute: "উত্তর বাংলা বিশ্ববিদ্যালয় কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01785484495",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ পেয়ারুল ইসলাম",
      designation: "শিক্ষা ও আইসিটি বিষয়ক সম্পাদক",
      professionInstitute: "আদিতমারী সরকারি কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01780809134",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member20,
      facebookLink: "",
    },
    {
      name: "মোঃ মাইদুল ইসলাম",
      designation: "সহ: শিক্ষা ও আইসিটি বিষয়ক সম্পাদক",
      professionInstitute: "আদিতমারী সরকারি কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01723711319",
      email: "member@pbtsbd.org",
      address: "জতিন্দ্র নারায়ন ,ফুলবাড়ী",
      image: member21,
      facebookLink: "",
    },
    {
      name: "মোঃ কাইফ হাসান",
      designation: "ক্রীড়া বিষয়ক সম্পাদক",
      professionInstitute: "ক্যান্টনমেন্ট পাবলিক স্কুল এন্ড কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01303070010",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member22,
      facebookLink: "",
    },
    {
      name: "মোঃ মাহামুদুল ইসলাম",
      designation: "সহ: ক্রীড়া বিষয়ক সম্পাদক",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "01873554701",
      email: "member@pbtsbd.org",
      address: "পশ্চিম বড়ুয়া,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ আশিকুর রহমান আকাশ",
      designation: "ধর্ম বিষয়ক সম্পাদক",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "০১৮৭৫৫১৪৩৭৬",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member24,
      facebookLink: "",
    },
    {
      name: "মোঃ সিয়াম ইসলাম",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "01404343660",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ রফিকুল ইসলাম",
      designation: "সাহিত্য ও সাংস্কৃতিক বিষয়ক সম্পাদক",
      professionInstitute: "বেগম রোকেয়া বিশ্ববিদ্যালয়",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01761413643",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোছাঃ আমেনা খাতুন",
      designation: "নারী ও শিশু বিষয়ক সম্পাদক",
      professionInstitute: "লালমনিরহাট আর্দশ ডিগ্রি কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01325023029",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member27,
      facebookLink: "",
    },
    {
      name: "মোঃ আলমগীর হোসেন",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "আশা ",
      professionDesignation: "মাঠ কর্মী",
      mobile: "01747483957",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member28,
      facebookLink: "",
    },
    {
      name: "মোঃ ইয়াছিন আলী",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "কারমাইকেল কলেজ, রংপুর",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01739031955",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: member29,
      facebookLink: "",
    },
    {
      name: "মোঃ মিঠু মিয়া",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "বাংলাদেশ পুলিশ",
      professionDesignation: "পুলিশ সদস্য",
      mobile: "01740101666",
      email: "member@pbtsbd.org",
      address: "কুলাঘাট,কুলাঘাট",
      image: member30,
      facebookLink: "",
    },
    {
      name: "মোঃ ময়নুল ইসলাম",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "বেগম রোকেয়া বিশ্ব বিদ্যালয়",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01302117845",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: member31,
      facebookLink: "",
    },
    {
      name: "মোঃ আশিকুর রহমান",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "কারমাইকেল কলেজ, রংপুর",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01908350558",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: member32,
      facebookLink: "",
    },
    {
      name: "মোঃ জাহাঙ্গীর আলম",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "ঢাকা বাংলা কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01635168697",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: member33,
      facebookLink: "",
    },
    {
      name: "মোঃ আমিনুল ইসলাম",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "উত্তর বাংলা বিশ্ববিদ্যালয় কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01993601500",
      email: "member@pbtsbd.org",
      address: "দক্ষিন শিবেরকুটি ,কুলাঘাট",
      image: member34,
      facebookLink: "",
    },
    {
      name: "মোঃ ইমরোজ রায়হান শাকিল",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "চীনে অধ্যয়নরত",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01736819744",
      email: "member@pbtsbd.org",
      address: "পূর্ব বড়ুয়া,কুলাঘাট",
      image: member35,
      facebookLink: "",
    },
    {
      name: "মোঃ আব্দুল মতিন",
      designation: "সহ: সাধারন সম্পাদক",
      professionInstitute: "কুড়িগ্রাম সরকারি কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01706838185",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ সুজন ইসলাম",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "কুড়িগ্রাম সরকারি কলেজ",
      professionDesignation: "শিক্ষার্থী",
      mobile: "01625865231",
      email: "member@pbtsbd.org",
      address: "দক্ষিন শিবেরকুটি ,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "মোঃ বিপ্লব হাসান",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "01774225664",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা, কুলাঘাট",
      image: member38,
      facebookLink: "",
    },
    {
      name: "মোঃ আব্দুর রাজ্জাক",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "হিসাব মহানিয়ন্ত্রক এর কার্যালয়",
      professionDesignation: "অফিস সহকারী",
      mobile: "01773808596",
      email: "member@pbtsbd.org",
      address: "ধাইরখাতা, কুলাঘাট",
      image: "",
      facebookLink: "",
    },
    {
      name: "সৌমিক রায়",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "০১৭৭৮৩৬২৯৬০",
      email: "member@pbtsbd.org",
      address: "খাটামারী, কুলাঘাট",
      image: member39,
      facebookLink: "",
    },
    {
      name: "মোঃ নাজির হোসেন",
      designation: "নির্বাহী সদস্য",
      professionInstitute: "-",
      professionDesignation: "-",
      mobile: "-",
      email: "member@pbtsbd.org",
      address: "কুলাঘাট,কুলাঘাট",
      image: "",
      facebookLink: "",
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);

  const [allMember, setAllMember] = useState([]);

  useEffect(() => {
    const totalPage = Math.ceil(members.length / 10);
    if (totalPage > currentPage) {
      setAllMember(members.slice(currentPage * 10, 10 * (currentPage + 1)));
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, members]);

  const handleIncreasePage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleDecreasePage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="m-2 md:m-5">
      <h3 className="text-center font-bold text-2xl">ফ্রিল্যান্সার তালিকা</h3>
      <div className="my-2 flex justify-end">
        <input
          type="text"
          placeholder="মোবাইল নাম্বার লিখুন"
          class="input input-bordered input-md w-full max-w-xs"
        />
        <button className="btn bg-secondary text-white border-0 mx-2">
          অনুসন্ধান
        </button>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-compact w-full">
          <thead>
            <tr>
              <th className="bg-secondary text-white">নাম এবং পদবী</th>
              <th className="bg-secondary text-white">
                কর্মরত প্রতিষ্ঠানের নাম এবং পদবী
              </th>
              <th className="bg-secondary text-white">যোগাযোগ</th>
              <th className="bg-secondary text-white">ঠিকানা</th>
              <th className="bg-secondary text-white">ফেসবুক</th>
            </tr>
          </thead>
          <tbody>
            {allMember?.map((member, index) => (
              <tr key={index}>
                <div class="flex items-center space-x-3">
                  <div class="avatar">
                    <div class="mask mask-squircle w-20 h-20 p-2">
                      {member?.image && (
                        <div class="avatar">
                          <div class="mask mask-squircle w-20 h-20">
                            <img src={member?.image} alt="not found" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div class="font-bold">{member?.name}</div>
                    <div class="text-sm opacity-80">{member?.designation}</div>
                  </div>
                </div>

                <td>
                  <div class="font-bold">{member?.professionInstitute}</div>
                  <div class="text-sm opacity-80">
                    {member?.professionDesignation}
                  </div>
                </td>
                <td>
                  <div class="font-bold">{member?.mobile}</div>
                  <div class="text-sm opacity-80">{member?.email}</div>
                </td>
                <td>{member?.address}</td>
                <td>
                  <div className="cursor-pointer">
                    <BsFacebook color="blue" size={30} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="px-5">
          <div className="flex justify-end items-center">
            <div className="px-6 py-4">
              {currentPage * 10 + 1}-{10 * (currentPage + 1)} of{" "}
              {members?.length}
            </div>
            <div>
              <div className="flex items-center">
                <button className="pr-10" onClick={handleDecreasePage}>
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <button onClick={handleIncreasePage}>
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Freelancer;
