import React, { useState } from 'react';

const BloodDonorRegistration = () => {
    const [paymentMethod, setPayloadMethod] = useState("");

    const btnInActiveClass = 'border w-28 border-red-500 rounded-lg py-2 text-red-500 m-2';
    const btnActiveClass = 'bg-red-500 w-28 border-0 rounded-lg py-2 text-white m-2';
    return (
        <div className='grid place-items-center'>
            <div className='lg:max-w-7xl lg:p-10  my-10 border-2 border-secondary rounded-xl'>
                <p className="font-bold text-center text-secondary text-3xl mb-5">ব্লাড ডোনার হিসাবে নিবন্ধন করুন</p>

                <div className=' grid grid-cols-1 md:grid-cols-2 gap-10 place-content-center place-items-center'>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">নাম</span>
                        </label>
                        <input type="text" placeholder="নাম লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">পিতার নাম</span>
                        </label>
                        <input type="text" placeholder="পিতার নাম লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">সর্বশেষ ব্লাড ডোনেশনের তারিখ</span>
                        </label>
                        <input type="text" placeholder="সর্বশেষ ব্লাড ডোনেশনের তারিখ লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">আপনি কি নিয়মিত রক্তদান করতে ইচ্ছুক:</span>
                        </label>
                        <div className='grid grid-cols-2'>
                            <button onClick={() => setPayloadMethod("bkash")} className={paymentMethod === "bkash" ? btnActiveClass : btnInActiveClass}>হ্যাঁ</button>
                            <button onClick={() => setPayloadMethod("nogod")} className={paymentMethod === "nogod" ? btnActiveClass : btnInActiveClass}>না</button>
                        </div>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">মোবাইল নাম্বার</span>
                        </label>
                        <input type="text" placeholder="মোবাইল নাম্বার লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">ইমেইল</span>
                        </label>
                        <input type="text" placeholder="ইমেইল লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">ফেসবুক আইডি</span>
                        </label>
                        <input type="text" placeholder="ফেসবুক আইডি লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">ছবি আপলোড করুন</span>
                        </label>
                        <input type="file" class="file-input file-input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">জাতীয় পরিচয় পত্র/জন্ম নিবন্ধন সনদ নাম্বার</span>
                        </label>
                        <input type="text" placeholder="জাতীয় পরিচয় পত্র/জন্ম নিবন্ধন সনদ নাম্বার লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">জন্ম তারিখ</span>
                        </label>
                        <input type="text" placeholder="জন্ম তারিখ লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">জেলা</span>
                        </label>
                        <input type="text" placeholder="জেলা লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">উপজেলা</span>
                        </label>
                        <input type="text" placeholder="উপজেলা লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">ইউনিয়ন</span>
                        </label>
                        <input type="text" placeholder="ইউনিয়ন লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">গ্রাম</span>
                        </label>
                        <input type="text" placeholder="গ্রাম লিখুন" class="input input-bordered w-full max-w-xs" />
                    </div>
                </div>
                <div className='my-5'>
                    <button onClick={() => setPayloadMethod("bkash")} className={paymentMethod === "bkash" ? btnActiveClass : btnInActiveClass}>A+</button>
                    <button onClick={() => setPayloadMethod("nogod")} className={paymentMethod === "nogod" ? btnActiveClass : btnInActiveClass}>A-</button>
                    <button onClick={() => setPayloadMethod("rocket")} className={paymentMethod === "rocket" ? btnActiveClass : btnInActiveClass}>B+</button>
                    <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>B-</button>
                    <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>AB+</button>
                    <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>AB-</button>
                    <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>O+</button>
                    <button onClick={() => setPayloadMethod("bank")} className={paymentMethod === "bank" ? btnActiveClass : btnInActiveClass}>O-</button>
                </div>
                <div className='grid place-content-center'>
                    <button className="btn btn-secondary text-white ">আবেদন দাখিল</button>
                </div>
            </div>
        </div>
    );
};

export default BloodDonorRegistration;