import React from 'react';
import { VscActivateBreakpoints } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import image1 from "../../assets/tree-plantation/20200902_150142.jpg";
import image2 from "../../assets/tree-plantation/20200902_155853.jpg";
import image3 from "../../assets/tree-plantation/20200905_164255.jpg";
import image4 from "../../assets/tree-plantation/20200905_164257.jpg";

const TreePlantation = () => {
    return (
        <div className='px-0 md:px-10 lg:px-20'>
            <p className='text-center font-bold text-2xl text-secondary mb-2'>বৃক্ষরোপণ কর্মসূচি</p>
            <p>
                বদলে যাচ্ছে আবহাওয়ার চিরচেনা চরিত্র। গরমের তীব্রতায় পুড়ছে দেশ। জলবায়ু পরিবর্তন এবং বৃক্ষ নিধনসহ আমাদেরই হাতের কামাইয়ের পরিণতি প্রত্যক্ষ করছি আমরা। এ থেকে রক্ষা পেতে দরকার প্রচুর পরিমাণে গাছ লাগানো। এই প্রয়োজনীয়তা উপলব্ধি করে পূর্ব বড়ুয়া তরুণ সংঘ প্রতিবছর সারাদেশে ফলজ বৃক্ষ রোপনের পরিকল্পনা হাতে নিয়েছে। সাধারণ মানুষ থেকে অর্থ সংগ্রহ করে দেশের প্রত্যন্ত এলাকার মাসজিদ, কবর স্থান, শিক্ষা প্রতিষ্ঠান, মাদরাসা এবং দরিদ্র জনগোষ্ঠী—যাদের গাছ লাগানোর মতো জমি রয়েছে—ফলজ গাছের চারা লাগানোর ব্যবস্থা করা হয়। এর মাধ্যমে সাধারণ মানুষকে সাদাকায়ে জারিয়ায় অংশগ্রহণের সুযোগ
                গাছ লাগানোর গুরুত্ব ও ফযীলত ও অপরিসীম। এটি একটি সাদাকায়ে জারিয়ামূলক নেক কাজ। যদি কেউ মানুষ কিংবা প্রাণীকূলের উপকার সাধনের লক্ষ্যে ফলজ বা বনজ গাছ রোপণ করে এবং এর মাধ্যমে সাওয়াব আশা করে, তবে এটি একটি উত্তম সাদাকায়ে জারিয়াহ; যার সওয়াবের ধারা ব্যক্তির মৃত্যুর পরও অব্যাহত থাকতে পারে।

                <br />
                <br />

                রাসূলুল্লাহ (সাল্লাল্লাহু আলাইহি ওয়া সাল্লাম) বলেছেন, ‘যদি কোনো মুসলমান একটি বৃক্ষ রোপণ করে অথবা কোনো শস্য উৎপাদন করে এবং তা থেকে কোনো মানুষ কিংবা পাখি অথবা পশু ভক্ষণ করে, তাহলে তা সে ব্যক্তির জন্য সাদকাস্বরূপ।’ (সহীহ বুখারী: হাদীস-২৩২০, সহীহ মুসলিম: হাদীস-১৫৫৩)

                <br />
                <br />
                পূর্ব বড়ুয়া তরুণ সংঘ সাধারণত উন্নত জাতের ফলজ গাছ লাগানোর চেষ্টা করে। যাতে পরিবেশ সুরক্ষার পাশাপাশি গরিব মানুষের অর্থের সংস্থানও হতে পারে। প্রথম বছর যেসব গাছ লাগানো হয়েছে, তা হলো—
            </p>
            <br />
            <br />
            <ul>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>আমগাছ</p>
                </li>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>লিচু</p>
                </li>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>জাম</p>
                </li>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>কাঠাল</p>
                </li>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>লেবু</p>
                </li>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>নারিকেলের চারা</p>
                </li>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>খেজুর গাছের চারা </p>
                </li>
                <li className='flex items-center'>
                    <VscActivateBreakpoints />
                    <p className='mx-1'>বিভিন্ন বনজ গাছের চারা</p>
                </li>
            </ul>
            <p>২০১৭-২০২৩ সালে মোট ১০ হাজার ৭৯২টি গাছ লাগানো হয়েছে। ইন শা আল্লাহ প্রতি বছর এই ধারা অব্যাহত থাকবে; বরং আরো বেগবান হবে।</p>
            <div className='flex justify-center my-10'>
                <Link to="/donation" className='btn btn-primary text-white w-44'>দান করুন</Link>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-content-center place-items-center gap-10'>
                <img className='h-64 max-w-xs m-2' src={image1} alt="" />
                <img className='h-64 max-w-xs m-2' src={image2} alt="" />
                <img className='h-64 max-w-xs m-2' src={image3} alt="" />
                <img className='h-64 max-w-xs m-2' src={image4} alt="" />
            </div>
        </div>
    );
};

export default TreePlantation;