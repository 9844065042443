import React, { useEffect, useState } from "react";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";
import { BsTrashFill } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";

function AddedHeaderBanner() {
  const [banners, setBanners] = useState([]);

  const handleGetHeaderBanners = async () => {
    const { data } = await api.get("header-banner");
    setBanners(data.data);
  };

  useEffect(() => {
    handleGetHeaderBanners();
  }, []);
  return (
    <div>
      <div className="flex justify-around border-b-2">
        <p>Image</p>
        <p>Title</p>
        <p>Action</p>
      </div>
      {banners?.map((banner) => (
        <div
          key={banner._id}
          className="flex justify-around items-center border-b-2"
        >
          <div class="avatar">
            <div class="w-24 rounded-full">
              <img src={`${baseUrl}${banner.imageUrl}`} alt="" />
            </div>
          </div>
          <div>
            <p>{banner.title}</p>
          </div>
          <div className="flex">
            <BsTrashFill className="mx-2" size={30} color="red" />
            <GrEdit className="mx-2" size={30} color="blue" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default AddedHeaderBanner;
