import React from 'react';
import { Link } from 'react-router-dom';
import image1 from "../../assets/sponsor-child/image-1.jpeg";
import image2 from "../../assets/sponsor-child/image-2.jpeg";
import image3 from "../../assets/sponsor-child/image-3.jpeg";
import image4 from "../../assets/sponsor-child/image-4.jpeg";

const SponsorChild = () => {
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 place-content-center place-items-center mx-2 md:5 lg:10'>
                <img className="m-2" src={image1} alt="" />
                <img className="m-2" src={image2} alt="" />
                <img className="m-2" src={image3} alt="" />
                <img className="m-2" src={image4} alt="" />
            </div>
            <div className='flex justify-center my-10'>
                <Link to="/donation" className='btn btn-primary text-white w-44'>দান করুন</Link>
            </div>
        </div>
    );
};

export default SponsorChild;