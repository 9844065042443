import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/image/logo.png';

const AboutUsBanner = () => {
    return (
        <div data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000" className='my-10 md:flex justify-center items-center p-2'>
            <div>
                <img className='p-5' height={200} width={300} src={logo} alt="" />
            </div>
            <div>
                <p className='max-w-md'>
                    ‘পূর্ব বড়ুয়া তরুণ সংঘ’ ২০১৭ সালের ১৩ এপ্রিল, বাংলাদেশের রংপুর বিভাগ, লালমনিরহাট জেলা, লালমনিরহাট সদর উপজেলা, কুলাঘাট ইউনিয়নের, পূর্ব বড়ুয়া গ্রামে প্রতিষ্ঠিত হয়। সংগঠনটির স্লোগান ‘আমরাই তরুন, আমরাই উদৌামি’।
                </p>
                <Link className='btn btn-secondary text-white' to='/about'>বিস্তারিত</Link>
            </div>
        </div>
    );
};

export default AboutUsBanner;