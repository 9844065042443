import React, { useEffect, useState } from "react";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";
import Carousel from "react-multi-carousel";

const ActivitiesSummary = () => {
  const [helps, setHelps] = useState([]);

  const handleGetHelps = async () => {
    const { data } = await api.get("provide-help");

    setHelps(data.help);
  };

  useEffect(() => {
    handleGetHelps();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <h3 className="text-3xl font-bold text-center text-primary mb-5">
        সহযোগিতা প্রদান
      </h3>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        autoPlay={true}
        ssr={true}
        infinite={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {helps?.map((help) => (
          <div
            key={help._id}
            class="card bg-base-100 shadow-xl border m-2 w-[90vw] md:w-[45vw] lg:w-[22vw] h-80"
            data-aos="flip-left"
          >
            <div className="flex justify-center mt-4">
              <div class="avatar">
                <div class="w-36 rounded-full">
                  <img src={`${baseUrl}${help.imageUrl}`} alt="" />
                </div>
              </div>
            </div>
            <div class="card-body">
              <h2 class="font-bold text-2xl text-center leading-[1]">
                {help?.title}
              </h2>
              <p className="text-center text-xl leading-[1]">
                {help?.helpDetails}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default ActivitiesSummary;
