import React from "react";

const MissionVisionCard = () => {
  const sections = [
    "প্রধানত সামাজিক ও শিক্ষা কর্মকাণ্ড পরিচালনা করবে।",
    "এলাকাবাসির মধ্যে আত্মনির্ভরশীলতা অর্জনের প্রত্যয় সৃষ্টি করা।",
    "এলাকার গরীব, অসহায় মেধাবী ছাত্র-ছাত্রীদের পড়া লেখা চালিয়ে যেতে সহায়তা বা উদ্ভুদ্ব করা এবং বিনামুল্যে বই বিতরন করা।",
    "ছাত্র-ছাত্রীদের মেধা ও সৃজনশীলতার সর্বাধিক বিকাশের লক্ষ্যে তাদেরকে প্রণোদিত ও সংগঠিত করা ও মেধাবী ছাত্র ছাত্রীদেরকে উপবৃত্তি প্রদান করা।",
    "রমজান মাসে অসহায় গরিবদের মাঝে ইফতারি সামগ্রী বিতরন করা ও ইফতার মাহফিল আয়োজন করা।",
    "“ঈদ উৎসব” ঈদের আগের দিন অসহায় গরিবের মাঝে ঈদ প্যাকেজ বিতরন করা।",
    "সমাজের সবার মধ্যে সামাজিক দায়বদ্ধতা বোধ সৃষ্টি করে সমাজ সচেতন নাগরিক হিসেবে গড়ে তোলা।",
    "পূর্ব বড়ুয়া তরুন সংঘ সামাজিক সংগঠনের প্রতিটি সদস্যকে কাজের মাধ্যমে সফল, স্বয়ংক্রিয় ও স্বেচ্ছাসেবী হয়ে ওঠার লক্ষ্যে ক্ষমতায়িত করা।",
    "শিশু কল্যাণঃ এলাকার গরীব শিশু-কিশোরদের অক্ষরদান দেয়ার জন্য গণশিক্ষা কেন্দ্র/পাঠাগার প্রতিষ্ঠা করা এবং দরিদ্র শিশুদের খেলাধুলার পাশাপাশি সু-স্বাস্থ্য নিশ্চিত কল্পে ফ্রি চিকিৎসার ব্যবস্থা করা এবং শিশুদের বাল্যবিবাহ রোধে সভা-সেমিনার ও গনসচেতনতা সৃষ্টি করা।",
    "এলাকার সকল প্রকার খেলাধুলার আয়োজন, অংশগ্রহন ও প্রতিভাবান খেলোয়ারদের প্রশিক্ষন এর ব্যাবস্থা করা। সংশ্লিস্ট খেলায় জেলা, বিভাগ ও জাতীয় পর্যায়ে অংশ গ্রহন করার ব্যবস্থা করা।",
    "মাদক মুক্ত এলাকা গড়তে প্রশাসনকে সহযোগিতা করা।",
    "এলাকার মাদকাসক্ত, জুয়াড়ি, বখাটে ও অপরাধীদের সুস্থ জীবনে ফিরিয়ে আনার লক্ষে বিনোদন, গনসচেতনতা ও চিকিৎসার ব্যাবস্থা করা এবং কর্মসংস্থানের জন্য উৎসাহ প্রদান করা।",
    "সমাজ বিরোধী কার্যকলাপ হইতে জনগণকে বিরত রাখিবার উদ্দেশ্যে চিত্ত-বিনোদন ও সাংস্কৃতিক কর্মসূচীর ব্যবস্থা করা।",
    "যে কোন সেবামূলক কাজে জনগনকে উদ্ভুদ্ব করা এবং জনগনকে সেবামূলক কাজে সহযোগিতা করা। এমনকি খাদ্য দ্রব্যকে বিষ মুক্ত রাখা ও কৃষকদেরকে কেমিক্যাল ব্যতিত ফসল উৎপাদানের ব্যপারে পরামর্শ দেয়া।",
    "সরকারের উন্নয়ন মূলক সংস্থা সমূহের সহায়ক শক্তি হিসাবে কাজ করা। গণ-শিক্ষা গ্রহণে বয়স্কদের উদ্ভুদ্ব করা, উন্নত প্রযুক্তির কৃষি, মৎস্য চাষ, হাঁস মুরগী পালন, হস্ত ও কুটির শিল্প স্থাপন, হেচারী ও নার্সারী সহ অন্যান্য কার্যক্রম সম্মন্ধে গ্রামবাসীর মধ্যে জ্ঞান দানের নিমিত্তে সংশ্লিষ্ট উন্নয়ন মূলক কর্মকান্ডে আমন্ত্রন করা এবং সভা ও সেমিনারের আয়োজন করা।পরিবার পরিকল্পনার মাঠকর্মীদের সাথে সমন্বয় সাধন করে পরিকল্পিত পরিবার গঠনে জনগনকে উদ্ধুদ্ধ করা।",
    "দেশের দুর্ভিক্ষ, বন্যা, ঘুর্ণিঝড়, ভূমিকম্প, মহামারী, অনাবৃষ্টি, অতিবৃষ্টি, সকল প্রকার প্রাকৃতিক দুর্যোগে সাহায্য সামগ্রী নিয়ে দুঃস্হ, ও ক্ষতিগ্রস্থদের পাশে এগিয়ে যাওয়া শীত বস্ত্র বিতরন এবং তহিবল বৃদ্ধি ও সংরক্ষনার্থে দান।",
    "ফুটবল, ক্রিকেট বেটমিন্টন টুর্নামেন্ট ও সাংস্কৃতিক অনুষ্ঠানের আয়োজন করা।",
  ];
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className=" mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <header className="bg-blue-600 text-white text-center py-6">
          <h1 className="text-4xl font-bold">লক্ষ্য ও উদ্দেশ্য</h1>
        </header>
        <div className="p-6 space-y-6">
          {sections.map((section, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-sm">
              <p className="text-gray-800 text-lg leading-relaxed">{section}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MissionVisionCard;
