import React from "react";
import { FaHeart, FaHandsHelping, FaGraduationCap } from "react-icons/fa"; // Import icons from react-icons
import logo from "../../assets/image/logo.png";

const About = () => {
  return (
    <div className="w-full flex flex-col items-center p-6 bg-gray-100">
      <img src={logo} alt="Logo" className="w-32 h-32 mb-6" />
      <h1 className="text-2xl font-bold mb-4 text-center">About Us</h1>
      <div className="max-w-2xl text-center text-gray-700">
        <p className="mb-4">
          ‘পূর্ব বড়ুয়া তরুণ সংঘ’ ২০১৭ সালের ১৩ এপ্রিল, বাংলাদেশের রংপুর বিভাগ,
          লালমনিরহাট জেলার, লালমনিরহাট সদর উপজেলার, কুলাঘাট ইউনিয়নের পূর্ব বড়ুয়া
          গ্রামে প্রতিষ্ঠিত হয়।
        </p>
        <p className="mb-4">
          <strong>
            পূর্ব বড়ুয়া তরুণ সংঘ প্রতিষ্ঠার জন্য উদ্যোগ গ্রহন করেন জনাব আরমান
            রহমান (২৯তম) বিসিএস শিক্ষা ক্যাডার
          </strong>{" "}
          এবং সেই উদ্যোগে কাজে লাগিয়ে সংগঠন টি এগিয়ে নিয়ে যাচ্ছেন মোঃ সায়হান
          সৈকত, উদ্যোক্তা, কুলাঘাট ইউনিয়ন ডিজিটাল সেন্টার
        </p>
        <p className="mb-4">
          আমরা ভালবাসা, আশা এবং সামাজিক ন্যায়বিচার সমবেত একটি পৃথিবীর স্বপ্ন
          দেখি, যেখানে মানুষ মর্যাদা ও নিরাপত্তা নিয়ে বাস করবে। সমাজের অনগ্রসর
          শ্রেণীকে বিশেষভাবে চরম দরিদ্র, গৃহহীন ও অনাথ শিশুদেরকে শিক্ষা, পুষ্টি
          ও আশ্রয় সরবরাহের মাধ্যমে তাদের জাতির জন্য সম্পদে পরিণত করা আমাদের
          প্রধান লক্ষ্য।
        </p>
        <p className="mb-4">
          ইহা সম্পূর্ণ অরাজনৈতিক, অলাভজনক, সামাজিক উন্নয়নমুলক, সাংস্কৃতিক ও
          সেচ্ছায় মানব সেবার সংগঠন। এই সংগঠন স্কুল/কলেজের ছাত্র, এলাকার তরুণ
          যুবক এবং ৩ ধরনের সদস্য নিয়ে গঠিত হবে এবং সর্ব শ্রেণীর উন্নয়নের জন্য
          কার্যক্রম পরিচালনা করবে।
        </p>
      </div>
      <div className="flex flex-col md:flex-row justify-around mt-6 space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-md">
          <FaHeart className="text-red-500 text-4xl mb-2" />
          <h2 className="text-xl font-semibold mb-2">Love and Compassion</h2>
          <p className="text-gray-600 text-center">
            We dream of a world where people live with dignity and security.
          </p>
        </div>
        <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-md">
          <FaHandsHelping className="text-green-500 text-4xl mb-2" />
          <h2 className="text-xl font-semibold mb-2">Community Service</h2>
          <p className="text-gray-600 text-center">
            Our goal is to transform the underprivileged into assets for the
            nation.
          </p>
        </div>
        <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-md">
          <FaGraduationCap className="text-blue-500 text-4xl mb-2" />
          <h2 className="text-xl font-semibold mb-2">Education and Support</h2>
          <p className="text-gray-600 text-center">
            We provide education, nutrition, and shelter to homeless and
            orphaned children.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
