import React from 'react';

function Loading() {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <progress class="progress w-56"></progress>
    </div>
  );
}

export default Loading;
