import React, { useEffect, useState } from "react";
import { AiFillPhone } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { BsPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Clock from "react-live-clock";
import { baseUrl } from "../../utils/url";

const Header = () => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-transparent lg:flex justify-around items-center p-5">
        <div className="items-center  hidden lg:flex">
          <p className="flex items-center text-black font-bold mx-1">
            <span className="mx-1">
              <BsPhoneFill size={25} className="text-secondary" />
            </span>
            <span className="mx-1">+8801744769331</span>
          </p>
          <p className="hidden lg:block text-black font-bold border-r-2 mx-1 h-5"></p>
          <p className="flex items-center text-black font-bold mx-1">
            <span className="mx-1">
              <MdEmail className="text-secondary" size={25} />
            </span>
            <span>info@pbtsbd.org</span>
          </p>
        </div>

        <div className="ml-3 lg:flex items-center">
          <p className="text-black font-bold mx-1 m-5 lg:m-0 hidden lg:flex">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=+arif.vtti@gmail.com"
            >
              <AiFillYoutube className="text-red-500" size={25} />
            </a>
          </p>
          <p className="text-black font-bold mx-1 m-5 lg:m-0 hidden lg:flex">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/EBYAssociationbd"
            >
              <AiFillFacebook className="text-blue-500" size={25} />
            </a>
          </p>
          <Clock
            className="text-black font-bold hidden lg:flex"
            format={"dddd, MMMM, YYYY, h:mm:ss A"}
            ticking={true}
          />
          <div className="mx-2 m-5 lg:m-0 flex items-center">
            <Link
              to="/signup"
              className="bg-secondary text-white font-bold w-24 rounded-l-lg h-10 border-white border-2 p-2 flex justify-center"
            >
              Sign Up
            </Link>
            {!user?.token ? (
              <Link
                to="/login"
                className="bg-primary text-white font-bold w-24 rounded-r-lg h-10 border-white border-2 p-2 flex justify-center"
              >
                Login
              </Link>
            ) : (
              <button
                onClick={() => {
                  localStorage.removeItem("user");
                  navigate("/login");
                }}
                className="bg-secondary text-white font-bold w-24 rounded-r-lg h-10 border-white border-2 p-2 flex justify-center"
              >
                Sign Out
              </button>
            )}
          </div>
          {user?.user?.role === "admin" && (
            <Link
              to="/dashboard"
              className="bg-primary text-white font-bold  rounded-lg h-10 border-white border-2 px-5 py-2 text-center"
            >
              Dashboard
            </Link>
          )}
          {user?.token && (
            <div className="flex items-center mx-2">
              <p>{user?.user?.name}</p>
              <div class="avatar">
                <div class="w-16 rounded-full">
                  <img src={`${baseUrl}${user?.user?.imageUrl}`} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
