import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../utils/axios.config';
import { toast } from 'react-hot-toast';
import ReactQuill from 'react-quill';
import axios from 'axios';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { baseUrl } from '../../utils/url';
import Loading from '../Shared/Loading';

function AddProvideHelp() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const onSubmit = async ({ file, title, helpDetails }) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('image', file[0]);
    formData.append('title', title);
    formData.append('helpDetails', helpDetails);

    try {
      const { data } = await api.post('provide-help', formData);

      if (data.success) {
        toast.success(data.message);
        reset();
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error(error.response.data.error ?? error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <label class="label">
              <span class="label-text">ছবি নির্বাচন করুন *</span>
            </label>
            <input
              type="file"
              {...register('file', { required: 'ছবি আবশ্যক' })}
              class="file-input file-input-bordered file-input-primary w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.file && errors.file.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">টাইটেল *</span>
            </label>
            <input
              {...register('title', {
                required: 'টাইটেল আবশ্যক',
              })}
              type="text"
              placeholder="টাইটেল লিখুন"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.title && errors.title.message}
              </span>
            </label>
          </div>
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">বিবরণ *</span>
            </label>
            <input
              {...register('helpDetails', {
                required: 'বিবরণ আবশ্যক',
              })}
              type="text"
              placeholder="কত জন/কত টি ইত্যাদি"
              class="input input-bordered w-full max-w-xs"
            />
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.helpDetails && errors.helpDetails.message}
              </span>
            </label>
          </div>
        </div>
        <div className="mt-10">
          <input
            className="btn btn-primary text-white w-44"
            value={'যুক্ত করুন'}
            type="submit"
          />
        </div>
      </form>
    </>
  );
}

export default AddProvideHelp;
