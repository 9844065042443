import React from 'react';
import { Link } from 'react-router-dom';

const TalentStudentAwardProject = () => {
    return (
        <div className='md:grid md:grid-cols-2 place-content-center place-items-center m-2 md:m-5'>
            <img src="https://keralakaumudi.com/web-news/en/2022/03/NMAN0320572/image/students.1.1548191.jpg" alt="education" />
            <div>
                <p className='text-xl font-bold text-secondary'><span>৳ ৫০০ -</span> (১ জন কৃতি শিক্ষার্থীর জন্য সংবর্ধনা খরচ)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ১০০০০ -</span> (২০ জন কৃতি শিক্ষার্থীর জন্য সংবর্ধনা খরচ)</p>
                <p className='text-xl font-bold text-secondary'><span>৳ ৪০০০০ -</span> (৮০ জন কৃতি শিক্ষার্থীর জন্য সংবর্ধনা খরচ)</p>

                <br />
                <p className='font-bold text-red-500'>
                    # এছাড়াও আপনি যেকোনো পরিমান অর্থ কৃতি শিক্ষার্থীর সংবর্ধনা প্রকল্পের জন্য ডোনেট করতে পারেন।
                </p>
                <br />
                <Link to='/donation' className="btn bg-secondary text-white border-0">Donation</Link>
            </div>
        </div>
    );
};

export default TalentStudentAwardProject;