import React, { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../utils/axios.config";
import { toast } from "react-hot-toast";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loading from "../Shared/Loading";

function AddAdviserMessage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const onSubmit = async ({ message }) => {
    setIsLoading(true);

    try {
      const { data } = await api.post("adviser-message", { message });

      if (data.success) {
        toast.success(data.message);
        reset();
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error(error.response.data.error ?? error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div class="form-control w-full">
            <label class="label">
              <span class="label-text">বাণী *</span>
            </label>
            <textarea
              class="textarea textarea-bordered h-32 w-full"
              {...register("message", {
                required: "বাণী আবশ্যক",
              })}
              type="text"
              placeholder="বাণী লিখুন"
            ></textarea>
            <label class="label">
              <span class="label-text-alt text-red-500">
                {errors.message && errors.message.message}
              </span>
            </label>
          </div>
        </div>
        <div className="mt-10">
          <input
            className="btn btn-primary text-white w-44"
            value={"যুক্ত করুন"}
            type="submit"
          />
        </div>
      </form>
    </>
  );
}

export default AddAdviserMessage;
