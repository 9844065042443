import React from "react";
import themeSong from "../../assets/theme song.mp4";

const Footer = () => {
  return (
    <div>
      <footer class="footer p-10 bg-base-200">
        <div className="md:w-96 w-full">
          <span class="footer-title">আমাদের দর্শন</span>
          <p>
            আমরা চাই সমাজের গরীব, দরিদ্র সকলেই যাতে তাদের মৌলিক অধিকার পায়। সেই
            প্রচেষ্টায় আমরা এগিয়ে যাচ্ছি। এই প্রচেষ্টায় আমাদের সহযোগী সরকারী ও
            বেসরকারী বিভিন্ন অফিস, কর্মকর্তা, কর্মচারী, সাধারন জনগন। আমরা চাই
            সবাই মিলে একসাথে এই প্রচেষ্টায় সফলতায় পৌঁছাব।
          </p>
          <button className="btn btn-primary text-white">বিস্তারিত</button>

          <div className="bg-white w-fit rounded-lg px-5 py-2 grid place-content-center place-item-center mt-5">
            <p className="font-bold text-secondary text-center ">
              Total Visitor
            </p>
            <p className="font-bold text-secondary text-center text-lg">
              50000
            </p>
          </div>
        </div>
        <div>
          <span class="footer-title">পৃষ্ঠা গুলি</span>
          <a class="link link-hover">আমাদের সম্পর্কে</a>
          <a class="link link-hover">তালিকা</a>
          <a class="link link-hover">মিডিয়া</a>
          <a class="link link-hover">ব্লাড ডোনেশন</a>
          <a class="link link-hover">প্রকল্প</a>
        </div>
        <div>
          <span class="footer-title">দরকারি লিংক</span>
          <a class="link link-hover">জেলা প্রশাসক, লালমনিরহাট</a>
          <a class="link link-hover">UNO অফিস</a>
          <a class="link link-hover">সমাজসেবা অধিদপ্তর</a>
        </div>
        <div>
          <span class="footer-title">যোগাযোগ করুন</span>
          <p>গ্রাম: পূর্ব বড়ুয়া, ডাকঘর: কুলাঘাট, উপজেলা ও জেলা: লালমনিরহাট।</p>
          <p>ও</p>
          <p>কুলাঘাট ইউনিয়ন ডিজিটাল সেন্টার</p>
          <p>কুলাঘাট ইউনিয়ন পরিষদ</p>
          <p>কুলাঘাট, লালমনিরহাট</p>
          <p>+8801744769331</p>
          <p>+8801700809648</p>
          <div className="w-full bg-secondary p-2 rounded-lg">
            <p className="font-bold text-white">সংগঠনের থিম সং</p>
          </div>
          <audio src={themeSong} controls></audio>
        </div>
      </footer>
      <footer class="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300 grid grid-cols-1 md:grid-cols-3 place-content-center place-items-center">
        <p className="text-secondary font-bold">
          পূর্ব বড়ুয়া তরুণ সংঘ, একটি সামাজিক স্বেচ্ছাসেবী সংগঠন
        </p>
        <div>
          <div className="flex items-center justify-center w-full">
            <p className="font-semibold text-center mx-2">
              Designed and Development By
            </p>
            <a
              className=" text-blue-500 font-bold"
              href="https://arifmia.netlify.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              Md Arif
            </a>
          </div>
          <p>
            2017-{new Date().getFullYear()} &copy; পূর্ব বড়ুয়া তরুণ সংঘ সমস্ত
            অধিকার সংরক্ষিত
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
