import React, { useEffect, useState } from "react";
import api from "../../utils/axios.config";
import { baseUrl } from "../../utils/url";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BsFacebook } from "react-icons/bs";
import { GoBrowser } from "react-icons/go";
import { MdEmail } from "react-icons/md";

const TopDonor = () => {
  const [donorPartners, setDonorPartners] = useState([]);

  useEffect(() => {
    const handleGetDonorPartner = async () => {
      try {
        const { data } = await api.get("donor-partner");
        setDonorPartners(data.donorPartner);
      } catch (error) {
        console.error("Error fetching donor partners:", error);
      }
    };
    handleGetDonorPartner();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="my-10">
      <h3 className="text-3xl font-bold text-center text-primary mb-5">
        ডোনার এন্ড পাটর্নার
      </h3>
      <div className="mx-auto w-full">
        <Carousel
          swipeable
          draggable
          showDots
          responsive={responsive}
          autoPlay
          ssr
          infinite
          autoPlaySpeed={2000}
          keyBoardControl
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {donorPartners.map((donorPartner) => (
            <div key={donorPartner._id} className="flex justify-center w-full">
              <div
                className="bg-blue-100 border-2 border-blue-500 md:w-96 mx-2 h-96 card"
                data-aos="fade-down-right"
              >
                <div className="flex justify-center mt-4">
                  <div className="w-36 h-36 rounded-full overflow-hidden">
                    <img
                      src={`${baseUrl}${donorPartner?.imageUrl}`}
                      alt={donorPartner.name}
                    />
                  </div>
                </div>
                <div className="card-body text-center">
                  <h2 className="font-bold text-lg leading-tight">
                    {donorPartner.name}
                  </h2>
                  <p className="text-sm leading-tight">
                    {donorPartner.designation}
                  </p>
                  <p className="text-sm leading-tight">
                    {donorPartner.institute}
                  </p>
                </div>
                <div className="flex justify-around m-5">
                  {donorPartner.facebookUrl && (
                    <a
                      href={donorPartner.facebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsFacebook size={30} className="text-blue-800" />
                    </a>
                  )}
                  {donorPartner.webUrl && (
                    <a
                      href={donorPartner.webUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GoBrowser size={30} className="text-secondary" />
                    </a>
                  )}
                  {donorPartner.emailUrl && (
                    <a href={`mailto:${donorPartner.emailUrl}`}>
                      <MdEmail size={30} className="text-red-500" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TopDonor;
