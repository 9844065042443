import React from "react";
import dbl from "../../assets/image/donation/dbl.jpeg";
import bkash from "../../assets/image/donation/bkash.jpeg";
import nagad from "../../assets/image/donation/nagad.jpeg";
import rocket from "../../assets/image/donation/rocket.jpeg";
import ekpay from "../../assets/image/donation/ekpay-logo.png";

const banks = [
  {
    name: "DBL Donation",
    image: dbl,
    alt: "dbl",
  },
  {
    name: "bKash Donation",
    image: bkash,
    alt: "bkash",
  },
  {
    name: "Nagad Donation",
    image: nagad,
    alt: "nagad",
  },
  {
    name: "Rocket Donation",
    image: rocket,
    alt: "rocket",
  },
];

const Donation = () => {
  return (
    <div>
      <div className="border-4 rounded-lg border-primary m-5 p-5 flex justify-center">
        <a href="https://donation.pbtsbd.org/" target="__blank">
          <h3 className="font-bold text-2xl text-center">
            Donation with government payment gateway
          </h3>
          <img
            src={ekpay}
            alt="ekpay"
            className="w-full h-32 object-contain"
            style={{ transform: "scale(1)" }}
          />
        </a>
      </div>
      <div className="md:grid grid-cols-2 lg:grid-cols-4 place-content-center place-items-center gap-10 lg:mx-10 my-10">
        {banks.map((bank, index) => (
          <div
            key={index}
            className="m-2 relative group"
            style={{
              transition: "transform 0.3s ease",
              cursor: "pointer", // Add cursor pointer on hover
            }}
          >
            <img
              src={bank.image}
              alt={bank.alt}
              className="w-full h-full object-cover"
              style={{ transform: "scale(1)" }}
            />
            <div className="card-content group-hover:block hidden absolute bottom-0 bg-white w-full p-2 text-center">
              <h3 className="text-xl font-semibold">{bank.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Donation;
