import React from 'react';
import donations from '../../assets/image/donations.png';
import volunteer from '../../assets/image/volunteer.png';
import sponsorship from '../../assets/image/sponsorship.png';

const JoinUs = () => {
    const joinUsActivities = [
        { id: 1, name: 'DONATIONS', image: donations, description: 'আমাদের এই সামাজিক কার্যক্রম কে আরো এগিয়ে নিতে দরকার আপনাদের আর্থিক অনুদান। আপনার অনুদানের মাধ্যমেই এই সামাজিক কার্যক্রম এগিয়ে যাবে সবসময়।' },
        { id: 2, name: 'VOLUNTEER', image: volunteer, description: 'আমাদের রয়েছে নানাবিধ সামাজিক কার্যক্রম। এসব কার্যক্রম সঠিকভাবে বাস্তবায়ন করার জন্য প্রয়োজন আপনার আমার স্বেচ্ছাশ্রম। আপনার আমার স্বেচ্ছাশ্রমই পারে এসব কার্যক্রম কে সঠিকভাবে বাস্তবায়ন করতে।' },
        { id: 3, name: 'SPONSORSHIP', image: sponsorship, description: 'আপনার ব্যবসা বা অন্য যেকোনো প্রতিষ্ঠান মাধ্যমে আমাদের এই প্রতিষ্ঠানে Sponsorship দিন। এতে করে আমাদের এসব সামাজিক কার্যক্রম আরোও অগ্রসর হবে।' },
    ]
    return (
        <div>
            <h3 className='text-3xl font-bold text-center text-primary mt-10'>আমাদের কার্যক্রমে যুক্ত হোন</h3>

            <div className='flex justify-center mt-5'>
                <div className='md:grid grid-cols-2 lg:grid-cols-3 gap-10'>
                    {
                        joinUsActivities?.map(activity => <div data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500" key={activity.id} className="card max-w-sm bg-base-100 shadow-xl border m-2 md:m-0">
                            <figure className="px-10 pt-10 bg-orange-300 p-2">
                                <img height={150} width={150} src={activity.image} alt="activity" className="rounded-xl" />
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title">{activity.name}</h2>
                                <p>{activity.description}</p>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
};

export default JoinUs;